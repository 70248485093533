import PropTypes from 'prop-types';
import Search from 'components/Trader/Search';

import styles from '../../Alarms.module.scss';

export const StockSearch = ({ handleSuggest, handleClear }) => (
  <div className={styles.field}>
    <label htmlFor="ticker">Ticker</label>
    <Search
      id="companyReport"
      placeholder="Ticker eller navn"
      onSuggestionSelected={handleSuggest}
      onClear={handleClear}
      inputProps={{
        placeholder: 'Søk ticker/selskap...',
        autoFocus: true,
        'data-lpignore': 'true',
      }}
    />
  </div>
);

StockSearch.propTypes = {
  handleSuggest: PropTypes.func,
};

export default StockSearch;
