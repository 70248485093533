import { forwardRef } from 'react';
import * as React from 'react';
import classNames from 'classnames';
import styles from './Radio.module.scss';
import Icon from '../Icons';

type Props = {
  className?: string;
  checked?: boolean;
  id?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  size?: 'small' | 'medium' | 'large';
} & React.HTMLProps<HTMLInputElement>;

const Radio = forwardRef<HTMLInputElement, Props>(
  (
    {
      id,
      className,
      onChange: handleChange,
      checked,
      size = 'medium',
      ...props
    },
    ref,
  ) => {
    const toggleClass = checked ? 'radio-on' : 'radio-off';

    return (
      <div
        className={classNames('radio', className, toggleClass, styles.Radio, {
          [styles[size]]: size,
        })}
      >
        <input
          ref={ref}
          id={id}
          type="radio"
          checked={checked}
          {...props}
          onChange={handleChange}
        />
        <Icon
          icon={checked ? ['far', 'dot-circle'] : ['far', 'circle']}
          className={toggleClass}
        />
      </div>
    );
  },
);

Radio.displayName = 'Radio';

export default Radio;
