import PropTypes from 'prop-types';
import { useFormState } from 'react-final-form';
import { useSidebar } from 'components/Sidebar';
import Button from 'components/other/Button';

import styles from '../Alarms.module.scss';

const FormButtons = ({ type, handleReset }) => {
  const { alarmToHandle } = useSidebar();
  const { submitting, pristine } = useFormState();

  return (
    <div className={styles.buttons}>
      <Button
        data-submitting={submitting}
        type="submit"
        stretch
        primary
        aria-label="Opprett ny alarm"
        disabled={submitting || pristine}
      >
        {alarmToHandle ? 'Endre' : 'Opprett'}{' '}
        {type === 'newsAlarm' ? 'nyhets' : 'markeds'}alarm
      </Button>
      {!pristine && (
        <Button
          type="reset"
          stretch
          secondary
          aria-label="Tøm skjema"
          onClick={handleReset}
        >
          Tøm skjema
        </Button>
      )}
    </div>
  );
};

FormButtons.propTypes = {
  type: PropTypes.oneOf(['newsAlarm', 'markedAlarm']),
};

export default FormButtons;
