import * as React from 'react';
import classNames from 'classnames';

import styles from './styles.module.scss';

type Props = {
  type:
    | 'info'
    | 'error'
    | 'warning'
    | 'success'
    | 'inline-info'
    | 'inline-error'
    | 'inline-warning'
    | 'inline-success';
  children: React.ReactNode;
  className?: string;
};

export const Box = ({ type, children, className, ...props }: Props) => (
  <div {...props} className={classNames(className, type, styles[type])}>
    {children}
  </div>
);

export default Box;
