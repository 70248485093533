let win;
if (typeof window === 'undefined') {
  win = { location: {} };
} else {
  win = window;
}

/* eslint max-len: [0] */
export const HOSTNAME = `//${process.env.JAWS_URL || win.location.host}`;
export const BASE_URL = `${HOSTNAME}/server`;
export const BASE_SECURE_URL = `${BASE_URL}/secure`;
export const COMPONENTS_SECURE_URL = `${BASE_SECURE_URL}/components`;
export const AUDIT_URL = `${BASE_SECURE_URL}/audit`;
export const COMPONENTS_URL = `${BASE_URL}/components`;
export const GRAPHDATA_URL = `${COMPONENTS_URL}/graphdata`;
export const SUGGEST_SECURE_URL = `${BASE_SECURE_URL}/suggest`;
export const GRAPHDATA_SECURE_URL = `${COMPONENTS_SECURE_URL}/graphdata`;
export const SUGGEST_URL = `${BASE_URL}/suggest`;
export const TAX_REPORT_URL = `${BASE_SECURE_URL}/tax-reports`;
export const PORTFOLIOS_URL = `${BASE_SECURE_URL}/portfolio`;

export const ACCOUNTS_URL = `${BASE_SECURE_URL}/accountCustomers`;
export const CONTRACTS_URL = `${BASE_SECURE_URL}/contracts`;
export const ORDERS_URL = `${BASE_SECURE_URL}/orderCustomers`;
export const CUSTOMERTRANSACTIONS_URL = `${BASE_SECURE_URL}/transactionCustomers`;
export const PRODUCTS_URL = `${BASE_SECURE_URL}/products`;
export const CUSTOMERS_URL = `${BASE_SECURE_URL}/customers`;
export const EVRY_ACCOUNTS_URL = `${CUSTOMERS_URL}/evryAccounts`;

export const EXCHANGE_URL = `${BASE_URL}/exchange`;
export const FUNDS_URL = `${BASE_URL}/funds`;

export const SESSION_KEEPALIVE_URL = `${BASE_SECURE_URL}/keepalive`;
export const USER_INFO_URL = `${BASE_SECURE_URL}/me`;

export const SNIPLETS_URL = `${BASE_URL}/sniplets`;
export const COMPETENCY_ASSESSMENT_URL = `${BASE_SECURE_URL}/competency-assessment-test`;
export const H_TEST_URL = `${BASE_SECURE_URL}/h-test`;
export const REPORT_URL = `${BASE_SECURE_URL}/report`;
export const RECEIVER_URL = `${BASE_SECURE_URL}/askMove/receivers`;
export const PROGRESS_URL = `${BASE_SECURE_URL}/askMove/progress`;
export const REQUEST_URL = `${BASE_SECURE_URL}/askMove/requestForHolding`;

export const ADMIN_ANALYSIS_URL = `${BASE_SECURE_URL}/company-reports`;
export const ADMIN_AUDIT_GRAPH_STATS_URL = `${AUDIT_URL}/graphStats`;
export const ADMIN_AUDIT_LIVE_URL = `${BASE_SECURE_URL}/sessions/info`;
export const ADMIN_AUDIT_SEARCH_URL = `${AUDIT_URL}/search`;
export const ADMIN_AUDIT_STATS_URL = `${AUDIT_URL}/auditStats`;
export const ADMIN_BANKS_URL = `${BASE_SECURE_URL}/admin/banks`;
export const ADMIN_CHANGE_PW_URL = `${BASE_SECURE_URL}/admin/change-password`;
export const ADMIN_COMPANY_REPORTS_URL = `${BASE_SECURE_URL}/company-reports`;
export const ADMIN_CUSTOMERS_URL = `${BASE_SECURE_URL}/admin/customers`;
export const ADMIN_LOGIN_URL = `${BASE_SECURE_URL}/auth/login/admin`;
export const ADMIN_TWO_FACTOR_LOGIN_URL = `${BASE_SECURE_URL}/auth/login/admin/two-factor`;
export const ADMIN_LOGOUT_URL = `${BASE_SECURE_URL}/auth/logout`;
export const ADMIN_SNIPLETS_URL = `${BASE_SECURE_URL}/admin/sniplets`;
export const ADMIN_USERS_URL = `${BASE_SECURE_URL}/admin`;
export const ADMIN_WHITELIST_URL = `${BASE_SECURE_URL}/admin/whitelist`;

export const AGREEMENT_URL = `${BASE_SECURE_URL}/savingsAgreement`;
export const ALERTS_URL = `${BASE_SECURE_URL}/jaws-alerts`;
export const ASK_TAX_REPORT_URL = `${TAX_REPORT_URL}/ask`;
export const COMPANY_REPORT_SUBTYPES_URL = `${BASE_SECURE_URL}/company-report-subtypes`;
export const COMPANY_REPORTS_URL = `${BASE_SECURE_URL}/company-reports`;
export const CONTACT_INFO_URL = `${BASE_SECURE_URL}/contactinfo`;
export const CONVERSION_URL = `${BASE_SECURE_URL}/conversion`;
export const DOMAIN_URL = `${BASE_URL}/domain/`;
export const FICTIVE_PORTFOLIOS_URL = `${PORTFOLIOS_URL}/fstock`;
export const FICTIVE_TRANSACTIONS_URL = `${BASE_SECURE_URL}/transactions/fstock`;
export const FUND_TAX_REPORT_URL = `${TAX_REPORT_URL}/funds`;
export const INSTRUMENTS_URL = `${BASE_SECURE_URL}/instruments`;
export const IPS_TAX_REPORT_URL = `${TAX_REPORT_URL}/ips`;
export const PORTFOLIO_INSTRUMENTS_URL = `${PORTFOLIOS_URL}/instruments`;
export const NORNE_API = `${BASE_SECURE_URL}/norneApi`;
export const PUBLIC_SAVINGS_ROBOT_URL = `${BASE_URL}/norneApi/savingsRobot`;
export const SAVINGS_ROBOT_URL = `${NORNE_API}/savingsRobot`;
export const WITHDRAWALAGREEMENTS = `${BASE_SECURE_URL}/accounts`;

export const MOVEMENTCUSTOMERS_URL = `${BASE_SECURE_URL}/movementCustomers`;
export const REPORT_RECOMMENDATION_TYPES_URL = `${BASE_SECURE_URL}/recommendation-types`;
export const REPORTS_URL = `${BASE_URL}/reports`;
export const SIGNICAT_URL = `${BASE_SECURE_URL}/signicat`;
export const TRANSACTIONS_URL = `${BASE_SECURE_URL}/transactions`;
export const TRANSFER_POSITIONS_URL = `${BASE_SECURE_URL}/transferPositions`;
export const TRIGGERED_ALERTS_URL = `${BASE_SECURE_URL}/alerts/triggered`;
export const WATCHLIST_URL = `${BASE_SECURE_URL}/watchlists`;
