import { lazy, Suspense, memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import Skeleton from 'react-skeleton-loader';
import App from 'containers/pages/App';
import { useDispatch, useSelector } from 'react-redux';
import { getIsLoggedIn, getUserIsFetching } from 'state/user/selectors';

import { reloadUserInfo } from 'state/user/actions';

const UserAuthicator = lazy(() => import('components/other/UserAuthenticator'));
const components = {};
const getComponent = component => {
  if (components[component]) return components[component];
  components[component] = lazy(() => import(`containers/pages/${component}`));
  return components[component];
};

const RouteComponent = memo(({ component, privateRoute, ...props }) => {
  const Component = getComponent(component);

  return (
    <Suspense
      fallback={
        <Skeleton
          count={10}
          width={'100%'}
          height={'250px'}
          color={'#f7f7f7'}
        />
      }
    >
      {privateRoute ? (
        <UserAuthicator page={Component} {...props} />
      ) : (
        <Component {...props} />
      )}
    </Suspense>
  );
});
RouteComponent.propTypes = {
  component: PropTypes.string.isRequired,
  privateRoute: PropTypes.bool,
};

const LazyRoute = ({ component, privateRoute, path, exact, noWrapper }) => (
  <Route
    path={path}
    exact={exact}
    render={props => {
      if (noWrapper) {
        return (
          <RouteComponent
            component={component}
            privateRoute={privateRoute}
            {...props}
          />
        );
      }

      return (
        <App {...props}>
          <RouteComponent
            component={component}
            privateRoute={privateRoute}
            {...props}
          />
        </App>
      );
    }}
  />
);

export const SavingsRobotRoute = props => {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(getIsLoggedIn);
  const isFetching = useSelector(getUserIsFetching);
  useEffect(() => {
    dispatch(reloadUserInfo());
  }, [dispatch]);

  if (isFetching) return null;

  const { step } = props.computedMatch.params;
  const isPublicStep = parseInt(step) <= 15;

  return (
    // privateRoute kicks off the UserAuthenticator
    <LazyRoute {...props} privateRoute={isAuthenticated || !isPublicStep} />
  );
};

LazyRoute.propTypes = {
  path: PropTypes.string.isRequired,
  component: PropTypes.string.isRequired,
  privateRoute: PropTypes.bool,
  exact: PropTypes.bool,
  noWrapper: PropTypes.bool,
};

export default LazyRoute;
