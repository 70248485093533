import { Field } from 'react-final-form';
import { normalizeNumber } from '@oms/utils';
import { NumberInput } from 'utils/useBlockWheel';
import styles from '../../Alarms.module.scss';

const required = value =>
  !normalizeNumber(value) ? 'Velg en verdi' : undefined;

export const ValueField = () => (
  <Field name="thresholdTrigger.value" validate={required}>
    {({ input, meta }) => (
      <div className={styles.field}>
        <label htmlFor="value">Verdi</label>
        <NumberInput
          {...input}
          placeholder="Verdi"
          name="value"
          type="number"
        />
        {meta.error && meta.touched && (
          <span className={styles.error}>{meta.error}</span>
        )}
      </div>
    )}
  </Field>
);

export default ValueField;
