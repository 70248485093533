const calculateSums = (rows, cashInDepot) => {
  if (!rows) return {};

  const result = rows.reduce(
    (sum, item) => {
      const {
        LASTNZ_DIV,
        LAST,
        CLOSE,
        CHANGE,
        POSITION_VOLUME,
        COST_BUY_PRICE,
        REALIZED_PROFIT,
        PRICE,
      } = item.values;

      const last = LASTNZ_DIV || CLOSE || LAST || PRICE;
      const marketValue = POSITION_VOLUME * last;
      const unrealizedProfit = marketValue - COST_BUY_PRICE;

      // Add to sum
      return {
        marketValue: sum.marketValue + (marketValue || 0),
        marketValueChange:
          sum.marketValueChange + (CHANGE * POSITION_VOLUME || 0),
        costPrice: sum.costPrice + (COST_BUY_PRICE || 0),
        realizedProfit: sum.realizedProfit + (REALIZED_PROFIT || 0),
        unrealizedProfit: sum.unrealizedProfit + (unrealizedProfit || 0),
      };
    },
    {
      marketValue: 0,
      marketValueChange: 0,
      costPrice: 0,
      realizedProfit: 0,
      unrealizedProfit: 0,
    },
  );
  result.cashInDepot = cashInDepot || 0;
  result.marketValueWithCash = result.marketValue + cashInDepot || 0;
  result.marketValueChangePct =
    result.marketValueChange && result.marketValue
      ? (result.marketValueChange / result.marketValue) * 100
      : null;
  result.unrealizedProfitPct =
    result.unrealizedProfit && result.costPrice
      ? (result.unrealizedProfit / result.costPrice) * 100
      : null;

  return result;
};

export default calculateSums;
