import { SNIPLETS_URL, ADMIN_SNIPLETS_URL } from 'constants/Jaws';
import { get, post } from 'utils/fetch';

export const snipletTemplates = {
  dashboardInformation: {
    name: 'dashboardInformation',
    bodies: {
      NO: {
        description: 'Message to users on dashboard page',
        body: '',
        languageCode: 'NO',
      },
    },
  },
  stylesheet: {
    name: 'stylesheet',
    bodies: {
      NO: {
        description: 'A link to a stylesheet with custom branch styling',
        body: '',
        languageCode: 'NO',
      },
    },
  },
  agreementLink: {
    name: 'agreementLink',
    bodies: {
      NO: {
        description:
          'A link to a PDF with terms and agreements for creating fund accounts',
        body: '',
        languageCode: 'NO',
      },
    },
  },
  termsAndAgreementsStock: {
    name: 'termsAndAgreementsStock',
    bodies: {
      NO: {
        description: 'Terms and agreements for stock trading',
        body: '',
        languageCode: 'NO',
      },
    },
  },
  ipsAgreementLink: {
    name: 'ipsAgreementLink',
    bodies: {
      NO: {
        description:
          'A link to a PDF with terms and agreements for creating IPS accounts',
        body: '',
        languageCode: 'NO',
      },
    },
  },
  recommendedFunds: {
    name: 'recommendedFunds',
    bodies: {
      NO: {
        description: 'The funds this bank recommends',
        body: '',
        languageCode: 'NO',
      },
    },
  },
  instrumentsAvailable: {
    name: 'instrumentsAvailable',
    bodies: {
      NO: {
        description: 'Instruments available to a bank and their customers',
        body: '',
        languageCode: 'NO',
      },
    },
  },
  footer: {
    name: 'footer',
    bodies: {
      NO: {
        description: 'A custom footer',
        body:
          '<p>Har du spørsmål eller trenger hjelp, ta kontakt med Norne Securities på <a href="tel:+4755559130">55 55 91 30</a></p>' +
          '<p><small>Powered by <a href="https://oms.no" target="_blank" rel="noopener noreferrer">Oslo Market Solutions AS</a></small></p>' +
          '<p><small><a href="/licenses" target="_blank" rel="noopener noreferrer">Lisenser</a></small></p>',
        languageCode: 'NO',
      },
    },
  },
};

export const fetchBankFilteredSniplet = async (centerId, snipletName) => {
  const response = await get({
    url: `${SNIPLETS_URL}/${snipletName}{?centerId}`,
    centerId,
  });

  if (!response.ok) {
    throw new Error(await response.text());
  }

  const { data } = await response.json();
  return data;
};

export const fetchSnipletByName = async snipletName => {
  const response = await get(`${SNIPLETS_URL}/${snipletName}`);
  if (!response.ok) {
    throw new Error(await response.text());
  }

  const { data } = await response.json();
  return data;
};

export const createSniplet = async (centerId, id) => {
  const payload = snipletTemplates[id];

  if (!payload) {
    throw new Error(`Creating sniplets of type '${id}' is not supported`);
  }

  const response = await post(
    `${ADMIN_SNIPLETS_URL}/bank/${centerId}/sniplet`,
    JSON.stringify({ data: payload }),
  );

  if (!response.ok) {
    throw new Error(await response.text());
  }

  const { data } = await response.json();
  return data;
};
