import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';
import { connect } from 'react-redux';
import { getUserId } from 'state/user/selectors';
import { useHandleStatus } from 'state/Hooks/HandleStatus.js';

import { createNewPortfolio } from 'utils/fictivePortfolio';
import Button from 'components/other/Button';

import styles from './FictivePortfolioForm.module.scss';

const FictivePortfolioForm = ({ userId, fetchPortfolio }) => {
  const [status, setStatus] = useHandleStatus(null);

  async function createPortfolio({ portfolioName }) {
    const result = await createNewPortfolio(userId, portfolioName);
    setStatus(result);
    if (result.success) fetchPortfolio();
  }

  return (
    <div className={styles.FictivePortfolioForm}>
      <h3>Opprett fiktiv portefølje</h3>
      <Form
        onSubmit={createPortfolio}
        render={({ handleSubmit, pristine, invalid, form }) => (
          <form onSubmit={handleSubmit}>
            <div>
              <Field name="PortfolioName">
                {({ meta }) => (
                  <div>
                    <label htmlFor="PortfolioName">Porteføljenavn</label>
                    <Field
                      name="portfolioName"
                      component="input"
                      placeholder="Gi din fiktive portefølje et navn"
                    />
                    {meta.error && meta.touched && (
                      <span className={styles.formError}>{meta.error}</span>
                    )}
                  </div>
                )}
              </Field>
              <Button
                type="submit"
                primary
                stretch
                aria-label="Opprett fiktiv portefølje"
                disabled={pristine || invalid}
              >
                Opprett fiktiv portefølje
              </Button>
              {!pristine && (
                <Button
                  type="reset"
                  secondary
                  stretch
                  onClick={() => this.resetForm(form.reset)}
                  aria-label="Tøm skjema"
                >
                  Tøm skjema
                </Button>
              )}
            </div>
            {status?.error && (
              <p>
                Her gikk noe galt: <br /> {status.error}
              </p>
            )}
            {status?.loading && 'laster'}
          </form>
        )}
      />
    </div>
  );
};

FictivePortfolioForm.propTypes = {
  userId: PropTypes.string.isRequired,
  fetchPortfolio: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  userId: getUserId(state),
});

export default connect(mapStateToProps)(FictivePortfolioForm);
