import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import styles from './Holdings.module.scss';

const AccountHeading = ({ account }) => (
  <div className={styles.AccountHeading}>
    <h3>
      {account.accountName} {account.id}
    </h3>
    <Link className={styles.Link} to={`/dashboard/account/${account.id}`}>
      Se aksjeoversikt
    </Link>
  </div>
);

AccountHeading.propTypes = {
  account: PropTypes.shape({
    accountName: PropTypes.string,
    id: PropTypes.string,
  }),
};

export default AccountHeading;
