import PropTypes from 'prop-types';
import Skeleton from 'react-skeleton-loader';
import useAlarm from 'state/alarm';
import AlarmRow from './AlarmRow';
import styles from './AlarmList.module.scss';

const sortByInstrumentName = (a, b) => {
  if (a.item < b.item) return -1;
  if (a.item > b.item) return 1;

  // If names are equal fallback to newest first
  return b.id - a.id;
};

export const MyAlarms = ({ editAlarm, handleAlarmMenu }) => {
  const {
    alarms,
    error,
    newsAlarms,
    fetching,
    toggleAlarm,
    deleteAlarm,
  } = useAlarm();

  if (fetching)
    return (
      <Skeleton count={15} width={'100%'} height={'45px'} color={'#f7f7f7'} />
    );

  if (!alarms?.length && !newsAlarms?.length && !fetching)
    return <h3>Fant ingen alarmer</h3>;

  return (
    <section className={styles.ActiveAlarms}>
      <div className={styles.statusField}>
        {error && <p> Noe gikk galt! : {error}</p>}
      </div>
      {alarms.sort(sortByInstrumentName).map(alarm => (
        <AlarmRow
          key={alarm.id}
          alarm={alarm}
          deleteAlarm={deleteAlarm}
          editAlarm={editAlarm}
          toggleAlarm={toggleAlarm}
          handleAlarmMenu={handleAlarmMenu}
        />
      ))}
    </section>
  );
};

MyAlarms.propTypes = {
  editAlarm: PropTypes.func,
  handleAlarmMenu: PropTypes.func.isRequired,
};
export default MyAlarms;
