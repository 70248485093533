// Polyfills
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'url-search-params-polyfill';
import 'element-closest';
import { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { setup } from 'utils/sentry';
import { BrowserRouter as Router } from 'react-router-dom';

import ensureIntlSetup from 'utils/ensureIntlSetup';
import ExceptionCatcher from './components/ExceptionCatcher';
import App from './main';

// setup react-query
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60,
      retry: 1,
      retryDelay: 2000,
      refetchOnWindowFocus: false,
    },
  },
});

// Start MSW worker if dev
if (process.env.NODE_ENV === 'development') {
  const { worker } = require('./mocks/browser');
  worker.start({ onUnhandledRequest: 'bypass' });
}

// Setup Sentry error-tracking
setup({
  prodUrl: '^spare.norne.no$',
  prodDSN: 'https://e96c57487e7f42c9b5808057894a4a20@sentry.oms.no/8',
  devDSN: 'https://07e366256192448694f3d31d9b4d748b@sentry.oms.no/7',
});

const elem = document.getElementById('root');
const render = () => {
  ReactDOM.render(
    <Router>
      <ExceptionCatcher>
        <Suspense fallback={null}>
          <QueryClientProvider client={queryClient}>
            <App />
          </QueryClientProvider>
        </Suspense>
      </ExceptionCatcher>
    </Router>,
    elem,
  );
};

ensureIntlSetup().then(render);
