import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import useAlarm from 'state/alarm';
import { getInitialValues } from 'utils/alarms/alarms';
import StatusField from '../Fields/StatusField';
import FormButtons from '../FormButtons';
import AlarmToHandleHeader from '../AlarmToHandleHeader';
import StockSearch from '../Fields/StockSearch';
import NewsField from '../Fields/NewsField';
import { useSidebar } from 'components/Sidebar';

// ⓘ These (state) hooks are shared with MarkedAlarm
export const useSuccess = (initialState = false) => {
  const [success, setSuccess] = useState(initialState);
  useEffect(() => {
    const timer = setTimeout(() => success && setSuccess(false), 1500);
    return () => clearTimeout(timer);
  }, [success]);
  return [success, setSuccess];
};

export const useAlarmState = (alarmToHandle = {}) =>
  useState({
    item: alarmToHandle?.item,
    sector: alarmToHandle?.sector,
    initialValues: getInitialValues(alarmToHandle),
  });

export const required = value =>
  value ? undefined : 'Velg hvordan alarmen skal trigges';

const NewsAlarm = ({ alarmToHandle }) => {
  const { handleAlarm } = useSidebar();
  const { createNewsAlarm } = useAlarm();

  const [{ item, sector, initialValues }, setState] = useAlarmState(
    alarmToHandle,
  );

  const handleSubmitForm = async (values, form) => {
    await createNewsAlarm(
      {
        values,
        item: item || values.item,
        sector: sector || values.sector,
      },
      () => handleReset(form, 2000),
    );
  };

  const handleReset = (form, delay) => {
    setTimeout(() => {
      handleAlarm();
      form.getRegisteredFields().forEach(field => form.resetFieldState(field));
      form.reset();
    }, delay);
  };

  const handleSuggestSelected = (event, data) => {
    event.preventDefault();
    setState(s => ({
      ...s,
      item: data.suggestion.ITEM,
      sector: data.suggestion.SECTOR,
    }));
  };

  const handleClearSearch = () =>
    setState(s => ({ ...s, item: undefined, sector: undefined }));

  return (
    <Form
      onSubmit={handleSubmitForm}
      initialValues={initialValues}
      render={({ handleSubmit, form }) => (
        <form onSubmit={handleSubmit} noValidate>
          {alarmToHandle ? (
            <AlarmToHandleHeader />
          ) : (
            <StockSearch
              handleSuggest={handleSuggestSelected}
              handleClear={handleClearSearch}
            />
          )}
          <NewsField />
          <StatusField />
          <FormButtons type="newsAlarm" handleReset={() => handleReset(form)} />
        </form>
      )}
    />
  );
};

NewsAlarm.propTypes = {
  alarmToHandle: PropTypes.shape({
    item: PropTypes.string.isRequired,
    sector: PropTypes.string.isRequired,
  }),
};

export default NewsAlarm;
