import classNames from 'classnames';

type Props = {
  type: string | string[];
  className?: string;
  [props: string]: any;
};

const Icon = ({ type, className, ...props }: Props) => (
  <i
    {...props}
    className={classNames('fa', type, className)}
    aria-hidden="true"
  />
);

export default Icon;
