import * as ActionTypes from './types';

const initialState = {
  data: [],
  isFetching: false,
  lockedFilter: false,
};

export default function adminCustomers(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.ADMIN_CUSTOMERS_REQUEST_DATA:
      return Object.assign({}, state, {
        isFetching: true,
      });

    case ActionTypes.ADMIN_CUSTOMERS_RECEIVE_DATA:
      return Object.assign({}, state, {
        isFetching: false,
        data: action.data,
      });

    default:
      return state;
  }
}
