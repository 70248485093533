import React, { useCallback, useContext, useEffect, useMemo } from 'react';

import { fetchOrders, fetchTodaysOrders } from 'state/orders';
import {
  getActiveOrders,
  getNumberOfActiveFundOrders,
} from 'state/orders/selectors';
import { getUserId, getAccounts } from 'state/user/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { useJaws } from '@oms/jaws-react';
import cookies from 'js-cookie';
import { useLocalStorage } from 'react-use';

cookies.remove('seenOrders');

const dedupe = <T extends any>(array: T[]) => [...new Set(array)];

const createSpecFromOrders = (orders: any) => {
  if (!orders.size) return undefined;

  const itemSector = dedupe(
    orders
      .map((item: any) => {
        const tickerCode = item?.get('TICKER_CODE');
        const stockExhangeId = item?.get('STOCK_EXCHANGE_ID');
        if (!tickerCode || !stockExhangeId) return undefined;

        return `${tickerCode}.${stockExhangeId}`;
      })
      .filter(Boolean),
  ).join(',');

  if (!itemSector) return undefined;

  return {
    columns:
      'ITEM, INSTRUMENT_TYPE, LAST, LONG_NAME, SECURITYNAME, INSTRUMENT_NAME, TICKER_CODE, LASTNZ_CA, LAST, CLOSENZ_CA, CLOSE',
    itemSector,
  };
};

const Context = React.createContext<{
  items: any;
  orders: any;
  ordersCount: any;
  unseenOrders: any;
  unseenOrdersCount: any;
  updateSeenOrders: any;
  fetchOrders: any;
  fetchToday: any;
} | null>(null);

const useActiveOrdersContext = () => {
  const ctx = useContext(Context);
  if (ctx === null)
    throw new Error(
      'useActiveOrdersContext can only be used within ActiveOrdersProvider',
    );
  return ctx;
};

const Provider: React.FC = ({ children }) => {
  const dispatch = useDispatch();
  const userId = useSelector(getUserId);
  const accounts = useSelector(getAccounts);
  const orders = useSelector(getActiveOrders);
  const ordersCount = useSelector(getNumberOfActiveFundOrders);
  const [seenOrders = [], setSeenOrders] = useLocalStorage<string[]>(
    'seenOrders',
  );

  const unseenOrders = useMemo(
    () =>
      orders
        ?.filter(
          (order: any) =>
            !seenOrders.includes(order.get('ORDER_ID')) &&
            order.get('INSTRUMENT_NAME'),
        )
        ?.toJS() || [],
    [seenOrders, orders],
  );
  const unseenOrdersCount = unseenOrders.length;

  const spec = useMemo(() => createSpecFromOrders(orders), [orders]);

  const { items } = useJaws(spec);

  useEffect(() => {
    if (userId && accounts) {
      dispatch(fetchOrders());
    }
    //eslint-disable-next-line
  }, [dispatch, userId, accounts, unseenOrdersCount]);

  const updateSeenOrders = useCallback(() => {
    const orderIds = orders
      .map((order: any) => order.get('ORDER_ID'))
      .toJS()
      .filter(Boolean);
    setSeenOrders(orderIds);
    //eslint-disable-next-line
  }, [orders]);

  const fetchData = useCallback(() => {
    return dispatch(fetchOrders());
  }, [dispatch]);

  const fetchToday = useCallback(() => {
    return dispatch(fetchTodaysOrders());
  }, [dispatch]);

  const value = useMemo(
    () => ({
      items,
      orders,
      ordersCount,
      unseenOrders,
      unseenOrdersCount,
      updateSeenOrders,
      fetchOrders: fetchData,
      fetchToday,
    }),
    [
      fetchData,
      fetchToday,
      items,
      orders,
      ordersCount,
      updateSeenOrders,
      unseenOrders,
      unseenOrdersCount,
    ],
  );

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

Context.displayName = 'ActiveOrdersContext';
const { Consumer } = Context;

export {
  Context as ActiveOrdersContext,
  Provider as ActiveOrdersProvider,
  Consumer as ActiveOrdersConsumer,
  useActiveOrdersContext,
};
