import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';

export const RedirectComponent = ({ from, to, ...props }) => (
  <Route {...props} path={from} render={() => <Redirect to={to} />} />
);

RedirectComponent.propTypes = {
  from: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
};

export default RedirectComponent;
