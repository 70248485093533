import { Component } from 'react';
import jawsConnect, { jawsPropTypes } from '@oms/jaws-react-compat';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { formatNumber, trendClass, decimalPlaces } from '@oms/utils';

import Icon from 'components/other/Icon';

import styles from './InstrumentOverview.module.scss';

class InstrumentOverview extends Component {
  static propTypes = {
    items: PropTypes.object.isRequired,
    selected: PropTypes.object.isRequired,
    ...jawsPropTypes,
  };

  state = {
    status: false,
  };

  render() {
    let { items } = this.props;
    const { selected } = this.props;

    items = items.sortBy(item => item.get('ASK')).slice(0, 1);

    const LAST = items.getIn([`${selected.ITEM}_${selected.SECTOR}`, 'LAST']);

    const LASTNZ = items.getIn([
      `${selected.ITEM}_${selected.SECTOR}`,
      'LASTNZ',
    ]);

    const CHANGE_PCT = items.getIn([
      `${selected.ITEM}_${selected.SECTOR}`,
      'CHANGE_PCT',
    ]);

    const CHANGE = items.getIn([
      `${selected.ITEM}_${selected.SECTOR}`,
      'CHANGE',
    ]);

    return (
      <div className={styles.InstrumentOverview} key={CHANGE_PCT}>
        <div className={styles.row}>
          <span>
            <b>{formatNumber(LAST || LASTNZ, decimalPlaces(LAST || LASTNZ))}</b>
          </span>
          <Icon type="fa-caret-up" className={trendClass(CHANGE)} />
          <span className={classNames(trendClass(CHANGE))}>
            {CHANGE > 0 && '+'}
            {formatNumber(CHANGE)}
          </span>
          <span className={classNames(trendClass(CHANGE_PCT))}>
            ({CHANGE_PCT > 0 && '+'}
            {formatNumber(CHANGE_PCT)}%)
          </span>
        </div>
      </div>
    );
  }
}

const mapStateToSpec = (state, props) => {
  const { ITEM, SECTOR } = props.selected;
  if (!ITEM || !SECTOR) return null;

  const columns =
    'BID_VOL as BIDVOL, ASK_VOL as ASKVOL, BID, ASK, LAST, CHANGE, CHANGE_PCT, CLOSE, PRICE, LASTNZ, LASTNZ_DIV, LASTNZ';
  return {
    columns,
    itemSector: `${ITEM}.${SECTOR}`,
  };
};

export { InstrumentOverview };
export default jawsConnect(mapStateToSpec)(InstrumentOverview);
