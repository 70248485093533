export const GRAPHDATA_SET_ITEM = 'graphdata/GRAPHDATA_SET_ITEM';
export const GRAPHDATA_REQUEST_DATA = 'graphdata/GRAPHDATA_REQUEST_DATA';
export const GRAPHDATA_SETUP_COMPONENT = 'graphdata/GRAPHDATA_SETUP_COMPONENT';
export const GRAPHDATA_RECEIVE_BUCKET = 'graphdata/GRAPHDATA_RECEIVE_BUCKET';
export const GRAPHDATA_CLEAR_BUCKETS = 'graphdata/GRAPHDATA_CLEAR_BUCKETS';
export const GRAPHDATA_CREATE_BUCKET = 'graphdata/GRAPHDATA_CREATE_BUCKET';
export const GRAPHDATA_RECEIVE_SERIES = 'graphdata/GRAPHDATA_RECEIVE_SERIES';
export const GRAPHDATA_SET_SELECTION = 'graphdata/GRAPHDATA_SET_SELECTION';
export const GRAPHDATA_ADD_BUCKET_WITH_SERIES =
  'graphdata/GRAPHDATA_ADD_BUCKET_WITH_SERIES';
