export const optimisticToggle = (alarms, values) =>
  alarms.map(alarm => ({
    ...alarm,
    active: alarm.id === values.id ? !alarm.active : alarm.active,
  }));

export const getType = payload => {
  if (payload.newsType) return 'NEWS';
  if (payload.intradayTrigger) return 'INTRADAY';
  if (payload.thresholdTrigger) return 'THRESHOLD';
  throw new Error(`Unspecified trigger type in AlarmProvider`);
};

export const types = {
  fetching: 'FETCHING',
  success: 'SUCCESS',
  error: 'ERROR',
  submitting: 'SUBMITTING',
};

export const alarmReducer = (state, { type, payload }) => {
  switch (type) {
    case types.fetching:
      return { ...state, submitting: false, fetching: true };
    case types.submitting:
      return { ...state, submitting: true };
    case types.success:
      return {
        ...state,
        // → store the previous data
        previousAlarms: state.alarms,
        error: null,
        submitting: false,
        fetching: false,
        alarms: payload?.sort((a, b) => (a.item > b.item ? -1 : 1)),
      };
    case types.error:
      return {
        ...state,
        // → restore alarms with previous data incase an optimistic update fails.
        alarms: state.previousAlarms,
        fetching: false,
        submitting: false,
        error: payload,
      };
    default:
      throw new Error(`Unsupported action of type ${type} in AlarmProvider`);
  }
};
