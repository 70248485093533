import PropTypes from 'prop-types';
import jawsComponent from '@oms/jaws-react-compat';
import { formatNumber as number } from '@oms/utils';

const Percentage = ({ items }) => {
  const data = items.toJS();
  const key = Object.keys(data)[0];
  let change;
  if (data && key) {
    change = data[key].CHANGE_PCT;
  }
  if (!change) return null;

  return <span>{number(change)}%</span>;
};

Percentage.propTypes = {
  items: PropTypes.shape({}),
};

const mapStateToSpec = (state, props) => ({
  columns: ['LAST', 'CHANGE_PCT'].join(),
  itemSector: `${props.ITEM}.${props.SECTOR}`,
});

export default jawsComponent(mapStateToSpec)(Percentage);
