import PropTypes from 'prop-types';
import Select from 'react-select';
import { Field } from 'react-final-form';
import { newsTypes } from 'utils/alarms/options';

import styles from '../../Alarms.module.scss';

const required = value => (value ? undefined : 'Velg hva du vil varles om');

export const NewsField = () => (
  <Field name="newsType" validate={required}>
    {({ input, meta }) => (
      <div className={styles.field}>
        <label htmlFor="newsType">Varsle om</label>
        <Select
          {...input}
          isSearchable={false}
          isClearable={false}
          placeholder={'Hva ønsker du å bli varslet om ?'}
          options={newsTypes}
          value={newsTypes?.find(opt => opt.value === input.value?.value)}
        />
        {meta.error && meta.touched && (
          <span className={styles.error}>{meta.error}</span>
        )}
      </div>
    )}
  </Field>
);
NewsField.propTypes = {
  NewsFieldOptions: PropTypes.array,
  disableField: PropTypes.bool,
};

export default NewsField;
