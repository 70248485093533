import PropTypes from 'prop-types';
import Button from 'components/other/Button';
import Checkbox from 'components/other/Checkbox';
import ErrorMessage from 'components/other/ErrorMessage';

import styles from './CompetencyAssessmentTest.module.scss';

const Results = ({
  testResult,
  warningAcknowledged,
  setWarningAcknowledged,
  handleProceed,
  submitting,
}) => {
  const Failed = () => (
    <>
      <ErrorMessage
        message="Kunnskapstest feilet"
        error="Basert på de opplysningene du har oppgitt, mener vi at komplekse instrumenter som dette
              ikke passer for deg. Dersom du likevel ber om det, kan vi tilby
            tjenesten. Men vær klar over at du kan bli utsatt for økonomisk
            risiko som krever særskilt kunnskap og erfaring."
      />
      <div className={styles.warningAcknowledge}>
        <label htmlFor="continue">
          <Checkbox
            id="continue"
            checked={warningAcknowledged}
            onChange={() => setWarningAcknowledged(!warningAcknowledged)}
          />
          Jeg ønsker allikevel å fortsette med handel.
        </label>
        <Button
          primary
          disabled={!warningAcknowledged || submitting}
          onClick={() => handleProceed(false)}
        >
          Lagre resultat
        </Button>
      </div>
    </>
  );

  const Passed = () => (
    <>
      <h2>Testen er bestått!</h2>
      <Button
        primary
        stretch
        disabled={submitting}
        onClick={() => handleProceed(true)}
      >
        Lagre resultat
      </Button>
    </>
  );

  return (
    <div className={styles.Results}>
      {testResult === 'COMPLETED_OK' ? <Passed /> : <Failed />}
    </div>
  );
};

Results.propTypes = {
  testResult: PropTypes.string.isRequired,
  warningAcknowledged: PropTypes.bool.isRequired,
  setWarningAcknowledged: PropTypes.func.isRequired,
  handleProceed: PropTypes.func.isRequired,
  submitting: PropTypes.bool,
};

export default Results;
