export const LOGOUT_USER = 'user/LOGOUT_USER';
export const REQUEST_USER_INFO = 'user/REQUEST_USER_INFO';
export const RECEIVE_USER_INFO = 'user/RECEIVE_USER_INFO';
export const USER_INFO_SET_IPS_RETURN_CURRENT_YEAR =
  'user/USER_INFO_SET_IPS_RETURN_CURRENT_YEAR';
export const USER_SET_SELECTED_ACCOUNT = 'user/USER_SET_SELECTED_ACCOUNT';
export const USER_SET_SELECTED_USER = 'user/USER_SET_SELECTED_USER';
export const USER_NOT_AUTHENTICATED = 'user/USER_NOT_AUTHENTICATED';
export const JAWS_CONNECTION_CLOSED = 'user/JAWS_CONNECTION_CLOSED';
export const JAWS_CONNECTION_ESTABLISHED = 'user/JAWS_CONNECTION_ESTABLISHED';
export const BALANCE_OR_MARGIN_FAILED = 'user/BALANCE_OR_MARGIN_FAILED';
export const USER_INFO_SET_MARGIN_INFO = 'user/USER_INFO_SET_MARGIN_INFO';
export const USER_INFO_SET_BALANCE = 'user/USER_INFO_SET_BALANCE';
export const USER_SET_EDIT_ACCOUNT = 'user/USER_SET_EDIT_ACCOUNT';
