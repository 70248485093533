import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import useAlarm from 'state/alarm';
import ErrorMessage from 'components/other/ErrorMessage';
import CommoditiesType from '../Fields/CommoditiesType';
import StockSearch from '../Fields/StockSearch';
import WhenField from '../Fields/WhenField';
import TriggerField from '../Fields/TriggerField';
import ValueField from '../Fields/ValueField';
import SelectionField from '../Fields/SelectionField';

import StatusField from '../Fields/StatusField';
import FormButtons from '../FormButtons';
import AlarmToHandleHeader from '../AlarmToHandleHeader';
import { useAlarmState } from '../NewsAlarm';
import { useSidebar } from 'components/Sidebar';

const MarkedAlarm = ({ alarmToHandle }) => {
  const { createNewMarkedAlarm, error } = useAlarm();
  const { handleAlarm } = useSidebar();

  const [{ item, sector, initialValues }, setState] = useAlarmState(
    alarmToHandle,
  );

  const handleSubmitForm = async (values, form) => {
    await createNewMarkedAlarm(
      {
        values,
        item: item || values.item,
        sector: sector || values.sector,
      },
      () => handleReset(form, 2000),
    );
  };

  const handleReset = (form, delay) => {
    setTimeout(() => {
      handleAlarm();
      form.getRegisteredFields().forEach(field => form.resetFieldState(field));
      form.reset();
    }, delay);
  };

  const handleSuggestSelected = (event, data) => {
    event.preventDefault();
    setState(s => ({
      ...s,
      item: data.suggestion.ITEM,
      sector: data.suggestion.SECTOR,
    }));
  };

  const handleClearSearch = () =>
    setState(s => ({ ...s, item: undefined, sector: undefined }));

  if (error)
    return (
      <ErrorMessage
        message={'Obs! Feil med en eller flere alarmer'}
        error={error}
      />
    );

  return (
    <Form
      onSubmit={handleSubmitForm}
      initialValues={initialValues}
      render={({ handleSubmit, form, values }) => {
        const showStockSearch =
          values.commoditiesType?.value === 'stocks' && !alarmToHandle;
        const showSelection = !showStockSearch && !alarmToHandle;

        const highFiveYears =
          values.thresholdTrigger?.field?.value === 'HIGH_5YEARS';
        if (highFiveYears) {
          form.change('thresholdTrigger.trigger', 'HIGH_5YEARS');
        }

        return (
          <form onSubmit={handleSubmit} noValidate>
            {alarmToHandle ? <AlarmToHandleHeader /> : <CommoditiesType />}

            {showStockSearch && (
              <StockSearch
                handleSuggest={handleSuggestSelected}
                handleClear={handleClearSearch}
              />
            )}
            {showSelection && <SelectionField />}

            <WhenField />
            {!highFiveYears && (
              <>
                <TriggerField />
                <ValueField />
              </>
            )}

            <StatusField />

            <FormButtons
              type="markedAlarm"
              handleReset={() => handleReset(form)}
            />
          </form>
        );
      }}
    />
  );
};

MarkedAlarm.propTypes = {
  alarmToHandle: PropTypes.shape({
    item: PropTypes.string.isRequired,
    sector: PropTypes.string.isRequired,
  }),
};

export default MarkedAlarm;
