import PropTypes from 'prop-types';
import HightlightQueryMatch from './HighLightQueryMatch';
import Percentage from './Percentage';

const suggestionTypes = {
  BONDS: 'Obligasjon',
  DERIVATIVES: 'Derivat',
  EQUITIES: 'Aksje',
  ETFS: 'ETF',
  ETNS: 'ETN',
  ETPS: 'ETP',
  PCC: 'Egenkapitalbevis',
  RIGHTS: 'Tegningsrett',
  WARRANTS: 'Warrant',
};

const suggestionKeys = Object.keys(suggestionTypes);

const supportedType = type => suggestionKeys.includes(type);

const Suggestion = (props, { query }) => {
  if (!props) return null;
  if (!supportedType(props.TYPE)) return null;
  const { LONG_NAME, ITEM, TYPE, ...rest } = props;

  return (
    <>
      <div>
        <HightlightQueryMatch query={query} text={ITEM} />
        <HightlightQueryMatch query={query} text={LONG_NAME} />
      </div>
      <Percentage {...{ ITEM, ...rest }} />
    </>
  );
};

Suggestion.propTypes = {
  LONG_NAME: PropTypes.string,
  ITEM: PropTypes.string,
  TYPE: PropTypes.string,
};

export default Suggestion;
