import Immutable from 'immutable';
import * as Graphdata from 'constants/Graphdata';

import * as ActionTypes from './types';

const initialState = Immutable.fromJS({
  // Set overviewChart default view to week
  OverviewChart: {
    selection: Graphdata.SELECTION_INTRADAY,
  },
});

export default function overviewChart(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.GRAPHDATA_RECEIVE_BUCKET:
      return state.mergeIn(
        [action.componentId, 'buckets', action.bucketId],
        action.data,
      );
    case ActionTypes.GRAPHDATA_SET_ITEM:
      return state.setIn([action.componentId, 'itemSector'], action.itemSector);

    case ActionTypes.GRAPHDATA_REQUEST_DATA:
      return state.setIn([action.componentId, 'isFetching'], true);

    case ActionTypes.GRAPHDATA_SETUP_COMPONENT:
      return state.setIn(
        [action.componentId],
        Immutable.fromJS({
          isFetching: true,
          selection: Graphdata.SELECTION_INTRADAY,
          itemSector: action.itemSector,
          buckets: {},
        }),
      );

    case ActionTypes.GRAPHDATA_CLEAR_BUCKETS:
      return state.setIn([action.componentId, 'buckets'], Immutable.fromJS({}));

    case ActionTypes.GRAPHDATA_CREATE_BUCKET:
      return state.setIn(
        [action.componentId, 'buckets', action.bucketId],
        Immutable.fromJS({ series: [], isFetching: true }),
      );

    case ActionTypes.GRAPHDATA_RECEIVE_SERIES:
      return state
        .setIn([action.componentId, 'isFetching'], false)
        .setIn(
          [action.componentId, 'buckets', action.bucketId, 'series'],
          action.data,
        );

    case ActionTypes.GRAPHDATA_SET_SELECTION:
      return state.setIn([action.componentId, 'selection'], action.selection);

    case ActionTypes.GRAPHDATA_ADD_BUCKET_WITH_SERIES: {
      const newState = state.setIn(
        [action.componentId, 'buckets', action.bucketId],
        action.data,
      );
      const isFetching = newState
        .getIn([action.componentId, 'buckets'])
        .filter(val => val.get('isFetching'));

      return newState.setIn(
        [action.componentId, 'isFetching'],
        isFetching.size !== 0,
      );
    }

    default:
      return state;
  }
}
