import Select from 'react-select';
import { Field } from 'react-final-form';
import { fields } from 'utils/alarms/options';
import styles from '../../Alarms.module.scss';

const required = value => (value ? undefined : 'Når skal alarmen trigges ?');

export const WhenField = () => (
  <Field name="thresholdTrigger.field" validate={required}>
    {({ input, meta }) => (
      <div className={styles.field}>
        <label htmlFor="thresholdTrigger.field">Når</label>
        <Select
          {...input}
          isSearchable={false}
          isClearable={false}
          placeholder={'Sist omsatt, endring intradag, høyest... '}
          options={fields}
          value={fields?.find(opt => opt.value === input.value?.value) || null}
        />
        {meta.error && meta.touched && (
          <span className={styles.error}>{meta.error}</span>
        )}
      </div>
    )}
  </Field>
);

export default WhenField;
