import PropTypes from 'prop-types';
import PortfoliosRows from './PortfolioRow';

const PortFoliosList = ({
  portfolios,
  userId,
  deletePortfolio,
  setPortfolio,
}) => (
  <table className="table-base table-align-right fictive-table">
    <thead>
      <tr>
        <th>Navn</th>
        <th>Verdi</th>
        <th />
      </tr>
    </thead>
    <tbody>
      {portfolios?.map(({ accountName, id }) => (
        <PortfoliosRows
          key={id}
          accountName={accountName}
          userId={userId}
          portfolioId={id}
          deletePortfolio={deletePortfolio}
          setPortfolio={setPortfolio}
        />
      ))}
    </tbody>
  </table>
);

PortFoliosList.propTypes = {
  userId: PropTypes.string.isRequired,
  deletePortfolio: PropTypes.func.isRequired,
  setPortfolio: PropTypes.func.isRequired,
  portfolios: PropTypes.array.isRequired,
};

export default PortFoliosList;
