import { library } from '@fortawesome/fontawesome-svg-core';
// Import the icons you need
import {
  faListUl,
  faUniversity,
  faLifeRing,
  faIslandTropical,
  faUserSecret,
  faEnvelope,
  faBadgeCheck,
  faSackDollar,
  faUserNinja,
  faUserCheck,
  faUserCircle,
  faUserAlt,
  faUserFriends,
  faUsers,
  faChevronUp,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faArrowLeft,
  faArrowRight,
  faCaretDown,
  faCaretUp,
  faEllipsisV,
  faEllipsisH,
  faBars,
  faBriefcase,
  faClipboardList,
  faRepeat,
  faHistory,
  faSearch,
  faRedo,
  faSort,
  faSortUp,
  faSortDown,
  faSortAlphaUp,
  faSortAlphaDown,
  faSortAmountUp,
  faSortAmountDown,
  faSync,
  faSyncAlt,
  faFilter,
  faWrench,
  faSignIn,
  faSignOut,
  faPercentage,
  faPercent,
  faBell,
  faStar,
  faHeart,
  faShoppingCart,
  faSpinner,
  faClock,
  faBroadcastTower,
  faFileDownload,
  faFile,
  faFilePdf,
  faFileAlt,
  faFileInvoice,
  faFileContract,
  faFileSignature,
  faFileExcel,
  faPlus,
  faMinus,
  faTrashAlt,
  faCalendar,
  faCalendarAlt,
  faInfoCircle,
  faQuestionCircle,
  faExclamationTriangle,
  faChartBar,
  faChartArea,
  faChartLine,
  faChartPie,
  faAnalytics,
  faTimes,
  faEdit,
  faCheck,
  faNewspaper,
  faExchange,
  faInbox,
  faCoins,
  faHandHoldingUsd,
  faHandHoldingBox,
  faUserPlus,
} from '@fortawesome/pro-light-svg-icons';

// Import the icons you need from other packages
import {
  faStar as fasStar,
  faBell as fasBell,
  faFileSignature as fasFileSignature,
  faCircle,
  faUser,
  faBolt,
  faTimesCircle,
  faBriefcase as fasBriefcase,
  faClipboardList as fasClipboardList,
  faRepeat as fasRepeat,
  faSpinner as fasSpinner,
  faCheckSquare,
  faAngleDown,
  faLeaf,
  faGlobe as fasGlobe,
} from '@fortawesome/pro-solid-svg-icons';

import {
  faSquare,
  faCircle as farCircle,
  faDotCircle,
  faShoppingCart as farShoppingCart,
} from '@fortawesome/pro-regular-svg-icons';

// add icons to library
const iconLibrary = library.add(
  faListUl,
  faUniversity,
  faLifeRing,
  faIslandTropical,
  faUserSecret,
  faEnvelope,
  faAngleDown,
  faLeaf,
  fasSpinner,
  farCircle,
  faDotCircle,
  faSquare,
  faCheckSquare,
  fasBriefcase,
  fasClipboardList,
  fasRepeat,
  faTimesCircle,
  faBadgeCheck,
  faSackDollar,
  faUserNinja,
  faUserCheck,
  faUserCircle,
  faUserFriends,
  faUserPlus,
  faUsers,
  faUser,
  faUserAlt,
  faChevronUp,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faArrowLeft,
  faArrowRight,
  faCaretDown,
  faCaretUp,
  faEllipsisV,
  faEllipsisH,
  faBars,
  faBriefcase,
  faClipboardList,
  faRepeat,
  faHistory,
  faSearch,
  fasGlobe,
  faRedo,
  faSort,
  faSortUp,
  faSortDown,
  faSortAlphaUp,
  faSortAlphaDown,
  faSortAmountUp,
  faSortAmountDown,
  faSync,
  faSyncAlt,
  faFilter,
  faWrench,
  faSignIn,
  faSignOut,
  faPercentage,
  faPercent,
  faBell,
  faStar,
  faHeart,
  faShoppingCart,
  farShoppingCart,
  faSpinner,
  faClock,
  faBroadcastTower,
  faFileDownload,
  faFile,
  faFilePdf,
  faFileAlt,
  faFileInvoice,
  faFileContract,
  faFileSignature,
  faFileExcel,
  faPlus,
  faMinus,
  faTrashAlt,
  faCalendar,
  faCalendarAlt,
  faInfoCircle,
  faQuestionCircle,
  faExclamationTriangle,
  faChartBar,
  faChartArea,
  faChartLine,
  faChartPie,
  faAnalytics,
  fasStar,
  fasBell,
  fasFileSignature,
  faCircle,
  faBolt,
  faTimes,
  faEdit,
  faCheck,
  faNewspaper,
  faExchange,
  faInbox,
  faCoins,
  faHandHoldingUsd,
  faHandHoldingBox,
);

export default iconLibrary;
