export const termsLastUpdated = new Date('2017-10-31T12:24:43.107Z');
export const termsStockLastUpdated = new Date('2017-10-31T12:24:43.107Z');
export const fundAccountTerms = (
  <div>
    <h4>Avtale om Aksjesparekonto (ASK)</h4>
    <p>
      Mellom Norne Securities AS (Foretaket) og Kunden er det inngått avtale om
      Aksjesparekonto (ASK). Avtalen kommer i tillegg til eksisterende
      Kundeavtale med XXX (Banken) og Kundeavtale inngått mellom Foretaket og
      Kunden. Ved signering av Kundeavtalen har Kunden også akseptert Foretakets
      Forretningsvilkår. Banken har innsynsrett i kundens Aksjesparekonto (ASK).
      <br />
      <br />
      Kunden bekrefter at Foretaket har gitt informasjon om:
      <ul>
        <li>hvilke midler som kan inngå på kontoen</li>
        <li>relevante skatteregler</li>
        <li>kostnader knyttet til kontoen</li>
        <li>at det ikke løper renter på innestående kontanter</li>
        <li>forholdet til innskuddsgarantiordningen</li>
      </ul>
    </p>
    <p>
      <b>1. Hva avtalen gjelder</b>
      <br />
      Avtalen gjelder opprettelse av Aksjesparekonto (ASK), føring av kontoen og
      oppbevaring av finansielle instrumenter og kontanter på kontoen. Innskudd
      på kontoen kan benyttes til å investere i børsnoterte aksjer, børsnoterte
      egenkapitalbevis og andeler i aksjefond i Norge («Investeringsuniverset»).
      Aksjesparekontoen (ASK) kan dermed ikke benyttes for handel i «exchange
      traded notes» (ETN), derivater, obligasjoner eller rentefond.
      Kombinasjonsfond må ha en aksjeandel på mer enn 80 % for å omfattes av
      ordningen. Aksjeandelen beregnes ved inntektsårets begynnelse. Gevinster
      ved realisasjon av finansielle @instrumenter i Investeringsuniverset
      skattlegges ikke i forbindelse med realisasjonen, men først når midlene
      tas ut av kontoen. Mottatt utbytte blir derimot skattlagt løpende. Ved
      overføring til Aksjesparekontoen (ASK) vil inngangsverdien videreføres med
      kontinuitet. Ved uttak fra kontoen anses Kundens innskudd som det som tas
      først ut. Uttak ut over det som er skutt inn på kontoen, skattlegges som
      aksjeinntekt hos Kunden.
      <br />
      <br />
    </p>
    <p>
      <b>2. Opprettelse av Aksjesparekonto (ASK)</b>
      <br />
      Foretaket har opprettet en Aksjesparekonto (ASK) for Kunden på de vilkår
      som fremgår av Avtalen.
      <br />
      <br />
    </p>
    <p>
      <b>3. Føring og vedlikehold av Aksjesparekontoen (ASK)</b>
      <br />
      Kontoen angir de opplysninger som til enhver tid kreves etter regelverket
      for Aksjesparekonto (ASK), for tiden
      <ul>
        <li>innskudd på kontoen </li>
        <li>oversikt over finansielle instrumenter</li>
        <li>kontantbeholdning</li>
        <li>ubenyttet skjermingsfradrag fra tidligere år</li>
        <li>skjermingsgrunnlag</li>
        <li>beregnet skjermingsfradrag for skattleggingsperioden</li>
        <li>kontoens markedsverdi ved årsskiftet</li>
        <li>mottatt utbytte</li>
      </ul>
      Foretaket sørger for oppdatering av opplysningene ved endringer.
      <br />
      <br />
    </p>
    <p>
      <b>4. Oppbevaring av kontante midler</b>
      <br />
      Kundens kontantbeholdning oppbevares på Foretakets klientkonto.
      <br />
      <br />
    </p>
    <p>
      <b>5. Oppbevaring av finansielle instrumenter</b>
      <br />
      Finansielle instrumenter som er registrert i Verdipapirsentralen (VPS) vil
      bli oppbevart på Kundens VPS-konto merket ASK. Ved inngåelsen av denne
      avtalen har Kunden gitt Foretaket handelsfullmakt på denne kunden, og
      Fortaket er Kundens kontofører
      <br />
      <br />
      For finansielle instrumenter som ikke er registrert i VPS, for eksempel
      aksjefondsandeler, har Foretaket opprettet en nominee-konto i Foretakets
      navn hos fondsforvaltningsselskapet og Kundens aksjefondsandeler er
      overført fra Kundens konto i andelseierregisteret til Foretakets
      nominee-konto.
      <br />
      <br />
    </p>
    <p>
      <b>6. Skatterapportering</b>
      <br />
      Foretaket er ansvarlig for skatterapportering av aksjesparerkontoen.
      Foretaket vil rapportere følgende opplysninger til Skatteetaten:
      <ul>
        <li>Innskuddene (inngangsverdien)</li>
        <li>Ubenyttet skjermingsfradrag fra tidligere år</li>
        <li>Skjermingsgrunnlag</li>
        <li>Mottatt utbytte</li>
        <li>Skattepliktig utbytte</li>
        <li>Kontantbeholdning</li>
        <li>Uttak fra kontoen i løpet av skattleggingsperioden</li>
        <li>Beregnet skjermingsfradrag i skattleggingsperioden</li>
        <li>
          Skattepliktig gevinst/tap ved uttak på kontoen i løpet av
          skattleggingsperioden
        </li>
        <li>Kontoens markedsverdi (formuesverdi) ved årsskiftet</li>
      </ul>
      Ved inngåelsen av avtalen om Aksjesparekonto (ASK) gir Kunden Foretaket
      fullmakt til å innhente opplysninger som trengs for korrekt
      skatterapportering hos skattemyndigheter, forvaltningsselskaper for
      aksjefond og eventuelle relevante organer eller foretak.
      <br />
      <br />
    </p>
    <p>
      <b>7. Flytting av Aksjesparekonto (ASK)</b>
      <br />
      Kunden kan flytte sin aksjesparerkonto helt eller delvis til annen
      Tilbyder etter begjæring fra Kunden. Foretaket er da forpliktet til å
      overføre all relevant informasjon av skattemessig betydning til Tilbyder.
      Ved Kundens anmodning om flytting, anses Kunden i å ha samtykket i at all
      relevant informasjon overføres til Tilbyder.
      <br />
      <br />
      Foretaket vil ved flyttebegjæring overføre verdipapirer, kontantbeholdning
      og nødvendige skatteopplysninger innen 10 dager etter at flyttebegjæring
      er mottatt. Dersom oppgjørsfristen for salg av finansielle instrumenter er
      lenger enn 10 dager, overførsel skje innen 5 dager etter oppgjørsfristen
      for salget.
      <br />
      <br />
    </p>
    <p>
      <b>8. Opphør av Aksjesparekonto (ASK)</b>
      <br />
      Kunden kan begjære Aksjesparekontoen (ASK) for opphørt. Foretaket vil ved
      begjæring av Kunden om opphør – etter kundens instruks – realisere
      finansielle instrumenter på Aksjesparekontoen (ASK) eller overføre de
      finansielle instrumenter til relevante Kontoer som kunden oppgir.
    </p>
  </div>
);

export const norneTerms = (
  <div>
    <p>
      <b>Relevante skatteregler</b>
      <br />
      Skattefordelene ved aksjesparekonto innebærer at du kan bytte aksjefond og
      aksjer uten at det utløser skatt umiddelbart. Skatten utløses først når
      gevinsten tas ut av Aksjesparekontoen. Du kan derfor ta ut opp til
      opprinnelig investert beløp uten å realisere skatt på gevinsten.
      <br />
      <br />
      Dersom du realiserer tap vil du ikke få fradrag for tapet uten å avslutte
      Aksjesparekontoen.
      <br />
      <br />
      Du oppnår fremdeles skjermingsfradrag på midler på aksjesparekontoen.
    </p>
    <br />
    <p>
      <b>Hvilke midler kan inngå på kontoen?</b>
      <br />
      Du kan eie norske børsnoterte aksjer og egenkapitalbevis, og norske
      aksjefond med en aksjeandel på over 80%. Du kan også oppbevare kontanter
      på aksjesparekonto, men husk at det ikke betales renter på kontanter på
      aksjesparekonto.
    </p>
    <br />
    <p>
      <b>Renter</b>
      <br />
      Etter gjeldende regler er det ikke tillatt å utbetale renter på
      kontantinnskudd på aksjesparekonto. Eventuelle kontanter på
      aksjesparekonto vil derfor ikke være rentebærende.
    </p>
    <br />
    <p>
      <b>Kostnader</b>
      <br />
      Kostnader forbundet med aksjesparekonto fremgår av vår prisliste:
      <br />
      <a
        href="https://norne.no/verdipapirhandel/priser/"
        target="_blank"
        rel="noopener noreferrer"
      >
        https://norne.no/verdipapirhandel/priser/
      </a>
    </p>
    <br />
    <p>
      <b>Innskuddsgarantiordningen</b>
      <br />
      Innskuddsgarantiordningen gjelder kontantinnskudd i bank og skal beskytte
      kunden dersom banken settes under offentlig administrasjon (går konkurs).
      Aksjesparekonto er ikke en bank-konto og er derfor ikke tilknyttet
      innskuddsgarantiordningen.
      <br />
      <br />
      Dine finansielle instrumenter på aksjesparekontoen kan bestå av aksjer,
      egenkapitalbevis og verdipapirfondsandeler. Aksjer og egenkapitalbevis vil
      stå oppført på din VPS-konto. Verdipapirfondsandeler registreres på Nornes
      nominee-konto. Kunden vil til enhver tid ha fullt innsyn i sin VPS-konto
      og beholdning av andeler i verdipapirfond registrert hos Norne. Dine
      finansielle instrumenter vil holdes utenfor en eventuell konkurs i Norne
      og du vil være sikret tilgang til disse om så skulle skje. De finansielle
      instrumentene på nominee-konto er dekket av Verdipapirforetakenes
      sikringsfond (
      <a
        href="http://www.verdipapirforetakenessikringsfond.no/"
        target="_blank"
        rel="noopener noreferrer"
      >
        http://www.verdipapirforetakenessikringsfond.no/
      </a>
      ), som dekker opp til NOK 200.000,- per kunde.
      <br />
      <br />
      Kontantinnskudd på aksjesparekonto vil være tilknyttet Norne sin
      klientkonto. Norne plikter til enhver tid å holde kundens midler atskilt
      fra Nornes egne midler og andre kunders midler ved separat registrering.
      Klientmidler vil således holdes utenfor boet i forbindelse med en
      eventuell konkurs i Norne. Bankenes sikringsfond (
      <a
        href="http://www.bankenessikringsfond.no"
        target="_blank"
        rel="noopener noreferrer"
      >
        http://www.bankenessikringsfond.no
      </a>
      ) dekker bankinnskudd opp til NOK 2 millioner per kunde dersom banken
      settes under offentlig administrasjon. For innskudd på klientkonto i
      Nornes navn anses Norne som kunde, slik at grensen på NOK 2 millioner
      gjelder for samlede innskudd på klientkontoen i banken.
    </p>
  </div>
);
