import { createContext, Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Sentry from 'utils/sentry';
import { logger } from '@oms/utils';
import { getUserId } from 'state/user/selectors';

import {
  addToWatchlist,
  removeFromWatchlist,
  getWatchlist,
} from 'utils/watchlists';

const log = logger('component:FavoritesRow');

const Context = createContext();
const { Provider, Consumer } = Context;

class WatchlistProvider extends Component {
  static propTypes = {
    userId: PropTypes.string,
    children: PropTypes.node.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      watchlist: [],
      handleWatchlist: this.handleWatchlist,
      watchlistError: null,
    };
  }

  componentDidUpdate(nextProps) {
    if (nextProps.userId !== this.props.userId) {
      this.fetchWatchList();
    }
  }

  async fetchWatchList() {
    const params = {
      userId: this.props.userId,
      watchlist: 'watchlist',
    };
    const result = await getWatchlist(params);

    if (!result.ok)
      return console.error(
        'Error when fetching watchlist:',
        result.status,
        result.statusText,
      );

    try {
      const response = await result.json();
      this.setState({ watchlist: response });
    } catch (e) {
      console.error('Error when parsing watchlist:', e);
    }
  }

  toggleState = itemSector => {
    this.setState(({ watchlist }) => {
      const isInWatchlist = watchlist.some(item => item === itemSector);

      if (isInWatchlist) {
        return {
          watchlist: watchlist.filter(item => item !== itemSector),
        };
      }

      return {
        watchlist: [...watchlist, itemSector],
      };
    });
  };

  handleWatchlist = async itemSector => {
    const params = {
      userId: this.props.userId,
      watchlist: 'watchlist',
      itemSector,
    };
    const inWatchList = this.state.watchlist.some(item => item === itemSector);

    // Optimistic update
    this.toggleState(itemSector);

    let result;
    try {
      if (inWatchList) {
        result = await removeFromWatchlist(params);
      } else {
        result = await addToWatchlist(params);
      }

      if (!result.ok) {
        this.setState({
          watchlistError: `${result.status}: ${result.statusText}`,
        });
        throw new Error(result.statusText);
      }
      if (result.ok) this.fetchWatchList();
    } catch (exception) {
      log('Failed to update the watchlist: %O', exception);

      Sentry.withScope(scope => {
        scope.setTag('type', 'toggleWatchlistState');
        scope.setExtras({
          result,
        });
        Sentry.captureException(exception);
      });

      // Revert optimistic update
      this.toggleState(itemSector);
    }
  };

  render() {
    return <Provider value={this.state}>{this.props.children}</Provider>;
  }
}

const mapStateToProps = state => ({
  userId: getUserId(state),
});
const ConnectedWatchlistProvider = connect(mapStateToProps)(WatchlistProvider);
export {
  Context as WatchlistContext,
  ConnectedWatchlistProvider as WatchlistProvider,
  Consumer as WatchlistConsumer,
};
