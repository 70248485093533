import { ALERTS_URL } from 'constants/Jaws';
import { post, get, del, put } from 'utils/fetch';
import { typeOptions } from 'utils/alarms/options';

export const getAlarms = async userId => get(`${ALERTS_URL}/${userId}`);

export const deleteAlarm = (userId, type, id) =>
  del(`${ALERTS_URL}/${userId}/${type}/${id}`);

export const editAlarm = (userId, payload, type, id) =>
  put(`${ALERTS_URL}/${userId}/${type}/${id}`, JSON.stringify(payload));

export const updateAlarmPayload = (values, toggle) => {
  const { item, sector, active, intradayTrigger, newsType } = values;

  const payload = {
    item,
    sector,
    active: toggle !== undefined ? toggle : active,
    rearmWhenTriggered: false, // TODO ?
    targets: ['EMAIL'], // TODO
  };

  if (newsType) {
    const newsPayload = { ...values, ...payload };
    delete newsPayload.id;
    delete newsPayload.userId;
    delete newsPayload.type;
    delete newsPayload.commoditiesType;
    delete newsPayload.instrumentDescription;
    delete newsPayload.createdTime;
    delete newsPayload.modifiedTime;
    newsPayload.rearmWhenTriggered = true;
    newsPayload.newsType = values?.newsType?.value || values?.newsType;

    return newsPayload;
  }

  if (
    intradayTrigger ||
    values.thresholdTrigger?.field?.value === 'HIGH_5YEARS'
  ) {
    payload.intradayTrigger = { field: 'HIGH_5YEARS' };
    return payload;
  }

  const { field, trigger, value } = values.thresholdTrigger;

  const useNegativeTriggerValue =
    (trigger === 'LTE' || trigger.value === 'LTE') &&
    (field === 'CHANGE' ||
      field.value === 'CHANGE' ||
      field === 'CHANGE_PCT' ||
      field.value === 'CHANGE_PCT');

  payload.thresholdTrigger = {
    field: field.value || field,
    trigger: trigger.value || trigger,
    value: useNegativeTriggerValue ? -Math.abs(value) : value,
  };

  return payload;
};

const createPayload = (values, itemSector) => {
  const { thresholdTrigger, newsType } = values;

  const item = itemSector.slice(0, itemSector.indexOf('.'));
  const sector = itemSector.slice(
    itemSector.lastIndexOf('.') + 1,
    itemSector.length,
  );

  const payload = {
    item,
    sector,
    active: true, // TODO ?
    rearmWhenTriggered: false, // TODO ?
    targets: ['EMAIL'], // TODO
  };

  if (!newsType) {
    const { field, trigger, value } = thresholdTrigger;
    if (field.value === 'HIGH_5YEARS') {
      payload.intradayTrigger = { field: field.value };
      return payload;
    }

    const useNegativeTriggerValue =
      trigger.value === 'LTE' &&
      (field.value === 'CHANGE' || field.value === 'CHANGE_PCT');

    payload.thresholdTrigger = {
      field: field.value,
      trigger: trigger.value,
      value: useNegativeTriggerValue ? -Math.abs(value) : value,
    };
  }

  if (newsType) {
    payload.newsType = newsType.value;
    payload.rearmWhenTriggered = true;
  }
  return payload;
};

export const createStockPayload = ({ thresholdTrigger, item, sector }) => {
  const payload = {
    item,
    sector,
    active: true,
    rearmWhenTriggered: false,
    targets: ['EMAIL'],
  };

  const { field, trigger, value } = thresholdTrigger;
  if (field.value === 'HIGH_5YEARS') {
    payload.intradayTrigger = { field: field.value };
    return payload;
  }
  payload.thresholdTrigger = {
    field: field.value,
    trigger: trigger.value,
    value,
  };

  return payload;
};

export const createMarkedAlarm = async (values, userId, item, sector) => {
  try {
    const stocks =
      values?.commoditiesType?.value === 'stocks' ||
      values?.commoditiesType === 'stocks';

    const itemSector = stocks ? `${item}.${sector}` : values?.selection?.value;

    const payload = values.id
      ? updateAlarmPayload(values, true)
      : createPayload(values, itemSector);
    let type = '';
    if (payload.newsType) type = 'NEWS';
    if (payload.intradayTrigger) type = 'INTRADAY';
    if (payload.thresholdTrigger) type = 'THRESHOLD';

    if (values.id) {
      return put(
        `${ALERTS_URL}/${values.userId}/${type}/${values.id}`,
        JSON.stringify(payload),
      );
    }

    return post(`${ALERTS_URL}/${userId}/${type}`, JSON.stringify(payload));
  } catch (error) {
    throw new Error(error);
  }
};

export const getInitialValues = alarm => {
  let initialValues = {
    commoditiesType: typeOptions.find(opt => opt.value === 'stocks'),
    ...alarm,
  };

  if (alarm?.thresholdTrigger) {
    const thresholdTrigger = {
      field: { value: alarm?.thresholdTrigger?.field },
      trigger: { value: alarm?.thresholdTrigger?.trigger },
      value: alarm?.thresholdTrigger?.value,
    };
    initialValues = {
      ...initialValues,
      thresholdTrigger,
    };
  }

  if (initialValues.thresholdTrigger) {
    initialValues.thresholdTrigger.value = Math.abs(
      initialValues.thresholdTrigger.value,
    );
  }

  return initialValues;
};
