import moment from 'moment';
import { logger, normalizeNumber } from '@oms/utils';
import createDecorator from 'final-form-calculate';
import { calculateCommission, Order } from 'utils/commission';
import { hashQueryKey } from 'react-query';

const log = logger('TradeForm:calculate');

const commissionCache: Record<
  string,
  { timestamp: number; comission: number }
> = {};

const decorator = createDecorator(
  {
    field: 'accountSelected',
    updates: {
      accountId: account => account?.value,
    },
  },
  {
    field: /(limit|volume|accountSelected)/,
    updates: {
      commission: async (fields: string, { userId, ...values }: any) => {
        const {
          item,
          sector,
          limit,
          volume,
          accountSelected,
          openVolume,
          hiddenVolume,
          bankAccount,
          useTriggerCriterion,
          stopLossLimit,
          tradeType,
        } = values;

        if (!values.accountId) return;

        const payload: Order = {
          accountId: accountSelected?.value || accountSelected,
          bankAccount,
          expirationDate: moment(values.calendar).format('YYYY-MM-DD'),
          exchange: sector,
          limit: normalizeNumber(limit),
          totalVolume: volume,
          openVolume,
          orderType: 'normal',
          ticker: item,
          tradeType,
          useOpenVolume: hiddenVolume,
          useTriggerCriterion,
          stopLossLimit: useTriggerCriterion
            ? normalizeNumber(stopLossLimit)
            : undefined,
          triggerCriterion: useTriggerCriterion ? 'lt' : undefined,
        };

        // Required values for comission calculation
        if (!limit || !volume) return;

        // Very simple caching system to prevent lots of duplicate queries (NOR-3431)
        const queryKey = hashQueryKey([userId, values.accountId, payload]);
        if (commissionCache[queryKey]) {
          const cached = commissionCache[queryKey];
          const oldCache = Date.now() - cached.timestamp > 1000 * 60 * 5; // 5 minutes
          if (!oldCache) return commissionCache[queryKey].comission;
        }

        try {
          const { COMMISSION } = await calculateCommission(
            userId,
            values.accountId,
            payload,
          );
          commissionCache[queryKey] = {
            comission: COMMISSION,
            timestamp: Date.now(),
          };
          return COMMISSION;
        } catch (error) {
          log('Failed fetching commission: %O', error);
        }
      },
    },
  },
);

export default decorator;
