import PropTypes from 'prop-types';
import moment from 'moment';
import { Form } from 'react-final-form';
import osloMoment from 'utils/osloMoment';
import { useHandleStatus } from 'state/Hooks/HandleStatus.js';
import { useSuggestionSelected } from 'state/Hooks/SuggestionSelected.js';
import ErrorMessage from 'components/other/ErrorMessage';
import { createTrade } from 'utils/fictivePortfolio';
import Button from 'components/other/Button';
import Limit from './LimitField';
import TickerSearch from './TickerSearch';
import Volume from './VolumeField';
import DateField from './DateField';
import PortfolioPicker from './PortfolioPicker';
import Summary from './Summary';

import styles from './Trade.module.scss';

const TradeFictivePortfolio = ({ portfolios, userId, fetchPortfolio }) => {
  const [itemSector, setInstrument] = useSuggestionSelected(undefined);
  const [status, setStatus] = useHandleStatus(null);

  async function submitTrade(values) {
    setStatus({ loading: true });
    const result = await createTrade(userId, values, itemSector);
    setStatus(result);
    if (result.success) fetchPortfolio();
  }

  function resetForm(reset) {
    reset();
    return setStatus(null);
  }

  function required(value) {
    return value ? undefined : 'Påkrevd felt';
  }

  const closeDates = [
    {
      daysOfWeek: [0, 6],
    },
    {
      before: moment()
        .subtract(10, 'years')
        .toDate(),
      after: moment()
        .add(19, 'days')
        .toDate(),
    },
  ];

  const portfolioOptions = portfolios.map(portfolio => ({
    value: portfolio.id,
    label: portfolio.accountName,
  }));

  return (
    <div className={styles.fictiveTradeForm}>
      <Form
        onSubmit={submitTrade}
        initialValues={{
          itemSector,
          accountSelected: portfolioOptions[0] || undefined,
          tradeTime: osloMoment(moment()).isAfter(moment('16:30', 'HH:mm'))
            ? moment().add(1, 'days')
            : moment(),
        }}
        render={({
          handleSubmit,
          values,
          submitting,
          submitSucceeded,
          form,
        }) => (
          <form onSubmit={handleSubmit}>
            <TickerSearch
              required={required}
              onChange={setInstrument}
              itemSector={itemSector}
            />
            <Limit />
            <Volume required={required} />
            <DateField required={required} closeDates={closeDates} />
            <div className={styles.divider} />
            <PortfolioPicker required={required} options={portfolioOptions} />
            <Summary values={values} />
            {status?.error && (
              <ErrorMessage
                message="Fiktiv transaksjon feilet"
                error={status?.error}
              />
            )}

            {(submitting || submitSucceeded) && (
              <div className={styles.orderStatus}>
                {submitting && <h3>Sender inn ordre...</h3>}
                {submitSucceeded && <h3>Ordre registrert</h3>}
              </div>
            )}

            <div className={styles.buttonFlex}>
              <Button disabled={status?.loading} primary type="submit">
                Legg til
              </Button>
              <Button
                disabled={status?.loading}
                secondary
                type="reset"
                onClick={() => resetForm(form.reset)}
              >
                Tøm skjema
              </Button>
            </div>
          </form>
        )}
      />
    </div>
  );
};

TradeFictivePortfolio.propTypes = {
  portfolios: PropTypes.array,
  userId: PropTypes.string.isRequired,
  fetchPortfolio: PropTypes.func.isRequired,
};

export default TradeFictivePortfolio;
