import PropTypes from 'prop-types';
import { formatNumber as number, formatDate } from '@oms/utils';
import { mapStatus } from 'utils/trade';
import Button from 'components/other/Button';

import styles from '../../TradeForm.module.scss';

const Receipt = ({ resetForm, order, values }) => (
  <>
    <ul className={styles.receipt}>
      <li>
        {values.tradeType === 'BUY' ? 'Kjøps' : 'Salgs'}ordren din er lagt inn.
      </li>
      <br />
      <li>Ordre id: {order.data?.ORDER_ID}</li>
      <li>Status: {mapStatus(order.data?.ORDER_STATUS)}</li>
      <li>Utløper: {formatDate(order.data?.EXPIRATION_DATE, 'DD.MM.YYYY')}</li>
      <li>Limit/pris: {number(order.data?.LIMIT)} kr</li>
      <li>Gjenstående: {order.data?.REMAINING_VOLUME}</li>
      <li>Estimert pris: {number(order.data?.ESTIMATED_PRICE)} kr</li>
    </ul>
    <Button stretch small onClick={resetForm} type="reset">
      Tøm skjema
    </Button>
  </>
);

Receipt.propTypes = {
  order: PropTypes.object,
  resetForm: PropTypes.func,
  values: PropTypes.shape({
    tradeType: PropTypes.string,
  }),
};

export default Receipt;
