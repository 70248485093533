import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './FictivePortfolio.module.scss';

export const MenuButtons = ({ viewPortfolio, handleMenu }) => (
  <header>
    <button
      disabled={viewPortfolio}
      onClick={handleMenu}
      className={classNames({
        [styles.active]: viewPortfolio,
      })}
    >
      Mine porteføljer
    </button>
    <button
      disabled={!viewPortfolio}
      onClick={handleMenu}
      className={classNames({
        [styles.active]: !viewPortfolio,
      })}
    >
      Legg til
    </button>
  </header>
);
MenuButtons.propTypes = {
  viewPortfolio: PropTypes.bool,
  handleMenu: PropTypes.func,
};

export default MenuButtons;
