import { Component } from 'react';
import PropTypes from 'prop-types';
import { JawsComponent } from '@oms/jaws-react';
import Button from 'components/other/Button';

import Row from './Row';
import styles from './OrderDepth.module.scss';

class OrderDepth extends Component {
  static propTypes = {
    itemSector: PropTypes.string.isRequired,
    levels: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    hasLevel2: PropTypes.bool,
  };

  state = {
    showLevels: false,
  };

  getTotal = items =>
    items.reduce(
      (a, b) => ({
        maxVolume:
          Math.max(b.get('BIDVOL'), b.get('ASKVOL'), a.maxVolume) ||
          a.maxVolume,
        minPrice:
          Math.min(b.get('BID'), b.get('ASK'), a.minPrice) || a.minPrice,
      }),
      {
        minPrice: Number.MAX_VALUE,
        maxVolume: 0,
      },
    );

  buildSpec = ({ itemSector, hasLevel2 }) => {
    if (!itemSector) return null;

    if (hasLevel2 && this.state.showLevels) {
      return {
        columns: 'BIDVOL, ASKVOL, BID, ASK, BIDNBR, ASKNBR, ITEM',
        convert: 'levels',
        dataSet: 'orders',
        limit: 5,
        itemSector,
      };
    }

    return {
      columns: 'BID_VOL as BIDVOL, ASK_VOL as ASKVOL, BID, ASK, ITEM',
      itemSector,
    };
  };

  showMore = () => {
    const { itemSector, hasLevel2, levels } = this.props;
    this.setState({ showLevels: !this.state.showLevels });
    this.buildSpec({ itemSector, hasLevel2, levels });
  };
  /* eslint-disable react/no-array-index-key */

  render() {
    const { itemSector, hasLevel2 } = this.props;
    const { showLevels } = this.state;

    return (
      <JawsComponent spec={this.buildSpec({ itemSector, hasLevel2 })}>
        {({ items }) => {
          const { maxVolume, minPrice } = this.getTotal(items);
          return (
            <div className={styles.wrapper}>
              {items
                .sortBy(item => item.get('ASK'))
                .map((item, key) => (
                  <Row
                    key={key}
                    item={{
                      ...item.toJS(),
                      minPrice,
                      percentOfMax: {
                        ask: Math.max(
                          item.get('ASKVOL') !== undefined
                            ? (item.get('ASKVOL') / maxVolume) * 100
                            : 0,
                          3,
                        ),
                        bid: Math.max(
                          item.get('BIDVOL') !== undefined
                            ? (item.get('BIDVOL') / maxVolume) * 100
                            : 0,
                          3,
                        ),
                      },
                    }}
                  />
                ))
                .toArray()}
              {hasLevel2 && (
                <div className={styles.showMore}>
                  <Button
                    aria-label={!showLevels ? 'Vis mer' : 'Vis mindre'}
                    onClick={this.showMore}
                    plain
                    size="small"
                    icon={!showLevels ? 'chevron-down' : 'chevron-up'}
                  />
                </div>
              )}
            </div>
          );
        }}
      </JawsComponent>
    );
  }
}

export default OrderDepth;
