import { useState } from 'react';
import { useJaws } from '@oms/jaws-react';
import * as React from 'react';
import {
  formatInteger,
  formatNumber,
  formatDate,
  decimalPlaces,
} from '@oms/utils';
import { ORDERS_URL } from 'constants/Jaws';
import { SidebarConsumer } from 'components/Sidebar';
import Button from 'components/other/Button';
import ErrorMessage from 'components/other/ErrorMessage';
import { mapStatus } from 'utils/trade';
import { del } from 'utils/fetch';

import styles from './ActiveOrders.module.scss';

const editableStatuses = [
  2, // IN_PROCESSING
  8, // EXPORTED
  9, // MANUAL
  10, // MANUAL_EXPIRED
];

type Props = {
  order: any;
};
export const ActiveOrders = ({ order }: Props) => {
  const [expanded, setExpanded] = useState(false);
  const [confirmed, setConfirmed] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [hideRow, setHideRow] = useState(false);

  const {
    EXPIRATION_DATE,
    HIDDEN_VOLUME,
    LIMIT,
    ORDER_ID,
    ORDER_STATUS,
    REMAINING_VOLUME,
    STOCK_EXCHANGE_ID,
    STOP_LOSS_LIMIT,
    TICKER_CODE,
    TOTAL_VOLUME,
    LAST_MODIFIED_DATE,
    INSTRUMENT_NAME,
  } = order;
  const { items } = useJaws({
    columns: 'LASTNZ_CA, LAST, CLOSENZ_CA, CLOSE, INSTRUMENT_TYPE',
    itemSector: TICKER_CODE + '.' + STOCK_EXCHANGE_ID,
  });
  const values = items.first()?.toJS();
  const last =
    values?.LASTNZ_CA ||
    values?.LAST ||
    values?.CLOSENZ_CA ||
    values?.CLOSE ||
    0;

  if (hideRow) return null;
  return (
    <SidebarConsumer>
      {({ handleEditOrder, clearInstrument }: any) => {
        const handleCancel = async (event: React.MouseEvent) => {
          event.stopPropagation();

          const { anonymizedOwnerId, id } = order.account;

          const orderId = order.ORDER_ID;
          const result = await del({
            url: `${ORDERS_URL}/${anonymizedOwnerId}/orders/${orderId}{?query*}`,
            query: {
              accountId: id,
            },
          });

          if (!result.ok) {
            setError(result.statusText);
            return;
          }

          setHideRow(true);
          // Fixes NOR-2374
          clearInstrument();
        };

        return (
          <section
            className={styles.card}
            style={{ borderLeftColor: order.TYPE === 0 ? 'green' : 'red' }}
          >
            <div>
              <h4>{INSTRUMENT_NAME || TICKER_CODE}</h4>

              <Button
                aria-label="Endre/slett"
                onClick={() => setExpanded(!expanded)}
                link
                size="small"
                reverse
              >
                #{ORDER_ID}
              </Button>
            </div>

            <small>
              <span>
                <ul>
                  <li>
                    <b>Type: </b>
                    {order.TYPE === 0 ? 'Kjøp' : 'Salg'}
                  </li>
                  <li>
                    <b>Status: </b> {mapStatus(ORDER_STATUS)}
                  </li>
                  <li>
                    <b>Utløper: </b> {formatDate(EXPIRATION_DATE, 'DD.MM.YYYY')}
                  </li>
                  <li>
                    <b>Oppdatert: </b>{' '}
                    {formatDate(LAST_MODIFIED_DATE, 'DD.MM.YYYY')}
                  </li>
                </ul>
              </span>

              <ul>
                <li>
                  <b>Volum:</b> {formatInteger(TOTAL_VOLUME)}
                </li>
                <li>
                  <b>Limit:</b>
                  {formatNumber(LIMIT, decimalPlaces(LIMIT))}
                </li>
                <li>
                  <b>Siste:</b>
                  {formatNumber(last, decimalPlaces(last))}
                </li>
              </ul>
            </small>

            {expanded && (
              <div className={styles.expandedRow}>
                <ul>
                  <li>
                    <b> Skjult volum:</b> {formatInteger(HIDDEN_VOLUME)}
                  </li>
                  <li>
                    <b> Gjenstående volum:</b> {formatInteger(REMAINING_VOLUME)}
                  </li>
                  <li>
                    <b> Stop Loss:</b>{' '}
                    {formatNumber(STOP_LOSS_LIMIT, decimalPlaces(last))}
                  </li>
                </ul>
                <div className={styles.buttonGroup}>
                  {STOCK_EXCHANGE_ID === 'NFF' ||
                  !editableStatuses.includes(ORDER_STATUS) ? (
                    <span>Ordren kan ikke endres</span>
                  ) : (
                    <>
                      <Button
                        primary
                        size="small"
                        onClick={() =>
                          handleEditOrder({
                            ...order,
                            item: values,
                          })
                        }
                      >
                        Endre
                      </Button>

                      <Button
                        onClick={() => setConfirmed(true)}
                        secondary
                        size="small"
                      >
                        Slett
                      </Button>
                    </>
                  )}
                </div>
                {confirmed && (
                  <div>
                    <h4>Er du sikker på at du vil slette ordren?</h4>
                    <Button stretch primary onClick={handleCancel}>
                      Bekreft
                    </Button>
                    <Button
                      stretch
                      secondary
                      onClick={() => setConfirmed(false)}
                    >
                      Avbryt
                    </Button>
                    {error && (
                      <ErrorMessage
                        message="Kunne ikke slette ordre"
                        error={error}
                      />
                    )}
                  </div>
                )}
              </div>
            )}
          </section>
        );
      }}
    </SidebarConsumer>
  );
};

export default ActiveOrders;
