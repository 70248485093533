import { Component } from 'react';
import PropTypes from 'prop-types';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { formatDate, parseDate } from 'react-day-picker/moment';
import moment from 'moment';
import osloMoment from 'utils/osloMoment';

import 'react-day-picker/lib/style.css';
import { getAmountOfValidDaysForOrder } from 'utils/trade';

const MONTHS = [
  'Januar',
  'Februar',
  'Mars',
  'April',
  'Mai',
  'Juni',
  'Juli',
  'August',
  'September',
  'Oktober',
  'November',
  'Desember',
];
const WEEKDAYS_SHORT = ['Sø', 'Ma', 'Ti', 'On', 'To', 'Fr', 'Lø'];

export class DayPicker extends Component {
  static propTypes = {
    closeRules: PropTypes.array.isRequired,
    tradeType: PropTypes.string.isRequired,
    orderId: PropTypes.string,
    selectedAccount: PropTypes.object,
    input: PropTypes.shape({
      value: PropTypes.array.isRequired,
      onBlur: PropTypes.func.isRequired,
      onFocus: PropTypes.func.isRequired,
      onChange: PropTypes.func.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
    disabled: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.handleDayClick = this.handleDayClick.bind(this);
  }
  state = { closeDaysArray: [], tradeDay: null };

  componentDidMount() {
    const { value, onChange } = this.props.input;

    if (!this.props.orderId) onChange(this.findFirstTradeDay(value));
  }

  getCloseDates() {
    const firstTradeDay = this.findFirstTradeDay();
    const { tradeType, selectedAccount } = this.props;
    const { paymentAgent } = selectedAccount || {};
    const validDays = getAmountOfValidDaysForOrder(tradeType, paymentAgent);

    return [
      {
        daysOfWeek: [0, 6],
      },
      {
        before: moment(firstTradeDay).toDate(),
        after: moment(firstTradeDay)
          .add(validDays, 'days')
          .toDate(),
      },
    ];
  }

  findFirstTradeDay = dayArg => {
    const day = dayArg instanceof moment ? dayArg : moment(dayArg);
    const closeRules = this.props?.closeRules;
    const closeDaysArray = closeRules?.data.map(item => new Date(item));
    const osloStockIsClosed = osloMoment(day).isAfter(moment('16:30', 'HH:mm'));

    if (!osloStockIsClosed) return day;

    const nextDay = day.add(1, 'days');
    const nextDayIsClosed = closeDaysArray?.some(item =>
      moment(item).isSame(nextDay, 'day'),
    );
    if (nextDayIsClosed) {
      return this.findFirstTradeDay(nextDay);
    }
    return nextDay;
  };

  handleDayClick(day, { disabled }) {
    const { onChange } = this.props.input;
    if (disabled) return;

    onChange(moment(day));
  }

  render() {
    const { input, disabled } = this.props;
    const { value, onBlur, onFocus, name } = input;
    const formattedValue = moment(value).format('DD.MM.YYYY');
    return (
      <DayPickerInput
        inputProps={{ readOnly: true, disabled }}
        placeholder="DD.MM.YYYY"
        name={name}
        format="DD.MM.YYYY"
        value={formattedValue}
        onDayChange={this.handleDayClick}
        onFocus={() => onFocus(value)}
        onBlur={() => onBlur(value)}
        dayPickerProps={{
          firstDayOfWeek: 1,
          months: MONTHS,
          weekdaysShort: WEEKDAYS_SHORT,
          fromMonth: moment().toDate(),
          selectedDays: [moment(value).toDate()],
          disabledDays: this.getCloseDates(),
        }}
        formatDate={formatDate}
        parseDate={parseDate}
      />
    );
  }
}

export default DayPicker;
