import { Component } from 'react';
import PropTypes from 'prop-types';

export default class Delay extends Component {
  static propTypes = {
    ms: PropTypes.number.isRequired,
    children: PropTypes.node.isRequired,
  };

  state = {
    visible: false,
  };

  componentDidMount = () => {
    this.mounted = true;
    setTimeout(this.setVisible, this.props.ms);
  };

  componentWillUnmount = () => {
    this.mounted = false;
  };

  setVisible = () => {
    if (this.mounted) {
      this.setState({ visible: true });
    }
  };

  mounted = false;

  render = () => (this.state.visible ? this.props.children : null);
}
