import shiroTrie from 'shiro-trie';
import objectAssign from 'object-assign';
import { setUser } from 'utils/sentry';
import {
  USER_INFO_URL,
  SESSION_KEEPALIVE_URL,
  ACCOUNTS_URL,
  CONTRACTS_URL,
  CONTACT_INFO_URL,
  ORDERS_URL,
} from 'constants/Jaws';

import * as UserUtils from 'utils/user';
import { get, put } from 'utils/fetch';
import {
  REQUEST_USER_INFO,
  RECEIVE_USER_INFO,
  USER_SET_SELECTED_ACCOUNT,
  USER_SET_SELECTED_USER,
  USER_NOT_AUTHENTICATED,
  JAWS_CONNECTION_CLOSED,
  JAWS_CONNECTION_ESTABLISHED,
  BALANCE_OR_MARGIN_FAILED,
  USER_INFO_SET_MARGIN_INFO,
  USER_INFO_SET_IPS_RETURN_CURRENT_YEAR,
  USER_INFO_SET_BALANCE,
  USER_SET_EDIT_ACCOUNT,
} from './types';
import {
  getUserId,
  getAccountId,
  getOwnerId,
  getIsIpsAccount,
  getSelectedAccountKey,
} from './selectors';

function requestUserInfo() {
  return { type: REQUEST_USER_INFO };
}

function receiveUserInfo(data) {
  return { type: RECEIVE_USER_INFO, data };
}

export function keepaliveSession() {
  return get(SESSION_KEEPALIVE_URL);
}

export function setSelectedAccount(accountId) {
  return { type: USER_SET_SELECTED_ACCOUNT, accountId };
}

export function setSelectedUserId(userId) {
  return { type: USER_SET_SELECTED_USER, userId };
}

export function setEditAccount(accountToEdit) {
  return { type: USER_SET_EDIT_ACCOUNT, accountToEdit };
}
function setNotAuthenticated() {
  return { type: USER_NOT_AUTHENTICATED };
}

function setNotConnected() {
  return { type: JAWS_CONNECTION_CLOSED };
}

function setConnected() {
  return { type: JAWS_CONNECTION_ESTABLISHED };
}

function handleError(dispatch, response) {
  if (response.statusCode === 401) {
    dispatch(setNotAuthenticated());
  }
  if (response.statusCode === 502) {
    dispatch(setNotConnected());
  }
}

export function reloadUserInfo() {
  return async dispatch => {
    dispatch(requestUserInfo());
    const result = await get(USER_INFO_URL);
    const status = result.status;
    const response = await result.json();

    if (status >= 400) {
      handleError(dispatch, response);
      throw response;
    }

    response.principals = response.principals.map(p =>
      objectAssign({}, p, {
        shiro: shiroTrie
          .new()
          .add(p.permissions)
          .add(p.entitledServices),
      }),
    );
    dispatch(receiveUserInfo(response));
  };
}

export function fetchUserInfo(clearStore = true) {
  return async (dispatch, getState) => {
    if (clearStore) {
      dispatch(requestUserInfo());
    }

    const result = await get(USER_INFO_URL);
    const status = result.status;
    const response = await result.json();

    if (status >= 400) {
      handleError(dispatch, response);
      throw response;
    }

    response.principals = response.principals.map(p =>
      objectAssign({}, p, {
        shiro: shiroTrie
          .new()
          .add(p.permissions)
          .add(p.entitledServices),
      }),
    );

    // TODO No need for setConnected to be it's own action, it should be
    // included into the receiveUserData reducer
    dispatch(setConnected());
    dispatch(receiveUserInfo(response));

    const customer = UserUtils.getCustomerData(getState().user);
    if (customer) {
      setUser(customer.toJS());
    }

    return response;
  };
}

export function saveContactInfo(data) {
  return (dispatch, getState) => {
    const user = getState().user;
    const userId = UserUtils.getUserId(user);

    return put(
      `${CONTACT_INFO_URL}/${userId}`,
      JSON.stringify(data),
    ).then(response => response.json());
  };
}

export function fetchMarginInfo(account) {
  return (dispatch, getState) => {
    const state = getState();

    if (!state.user || !state.user.get('authenticated')) return null;

    const userId = getUserId(state);
    const accountId = account || getAccountId(state);

    return get(`${ACCOUNTS_URL}/${userId}/account/${accountId}/margin-info`)
      .then(response => response.json())
      .then(response => {
        dispatch({
          type: USER_INFO_SET_MARGIN_INFO,
          marginInfo: response.data,
          accountId,
        });
      })
      .catch(error => {
        dispatch({
          type: BALANCE_OR_MARGIN_FAILED,
          error,
        });
      });
  };
}

export function fetchBalance(account) {
  return (dispatch, getState) => {
    const state = getState();

    if (!state.user || !state.user.get('authenticated')) return null;

    const userId = getUserId(state);
    const accountId = account || getAccountId(state);

    return get(`${ACCOUNTS_URL}/${userId}/balance/${accountId}`)
      .then(response => response.json())
      .then(response => {
        dispatch({
          type: USER_INFO_SET_BALANCE,
          balance: response.data.balance,
          accountId,
        });
      })
      .catch(error => {
        throw error;
      });
  };
}

export function fetchIPSContracts() {
  return (dispatch, getState) => {
    const state = getState();

    if (
      !state.user ||
      !state.user.get('authenticated') ||
      !getIsIpsAccount(state)
    )
      return null;

    const userId = getUserId(state);
    const accountId = getSelectedAccountKey(state);

    if (!userId || !accountId || accountId === 'ALL') return null;

    return get(`${CONTRACTS_URL}/${userId}/IPS/${accountId}`)
      .then(response => response.json())
      .then(response => {
        dispatch({
          type: USER_INFO_SET_IPS_RETURN_CURRENT_YEAR,
          ipsReturnCurrentYear: response.data.receivedCurrentYear,
          accountId,
        });
      })
      .catch(error => {
        throw error;
      });
  };
}

/* NOR-1003/NOR-1001 Added fetch for CUTOFF_DATE_TIME */
export function fetchOrderDetails(account) {
  return (dispatch, getState) => {
    const state = getState();

    if (!state.user || !state.user.get('authenticated')) return null;

    const ownerId = getOwnerId(state);
    const accountId = account || getAccountId(state);

    return get(`${ORDERS_URL}/${ownerId}/fund/orders/getOrderDetails`)
      .then(response => response.json())
      .then(response => {
        dispatch({
          type: USER_INFO_SET_MARGIN_INFO,
          marginInfo: response.data,
          accountId,
        });
      })
      .catch(error => {
        throw error;
      });
  };
}
