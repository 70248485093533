import { useState } from 'react';

function useHandleStatus(incomingStatus) {
  let statusObj = { error: null, loading: null, success: null };

  if (incomingStatus) {
    switch (incomingStatus) {
      case incomingStatus.loading:
        statusObj = { ...statusObj, loading: true };
        break;
      case incomingStatus.success:
        statusObj = { ...statusObj, success: true };
        break;
      case incomingStatus.error:
        statusObj = {
          ...statusObj,
          error: incomingStatus.error,
        };
        break;
      default:
    }
  }

  const [status, setStatus] = useState(statusObj);

  return [status, setStatus];
}
// eslint-disable-next-line import/prefer-default-export
export { useHandleStatus };
