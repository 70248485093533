import PropTypes from 'prop-types';
import Button from 'components/other/Button';

import styles from '../../TradeForm.module.scss';

const TradeFormButtons = ({
  tradeType,
  disabled,
  instrument,
  handleSelectType,
  pristine,
}) => {
  const editOrder = instrument.orderId && 'Lagre endringer';
  const trade = tradeType === 'BUY' ? 'KJØP' : 'SELG';

  return (
    <div className={styles.TradeFormButtons}>
      <Button
        stretch
        small
        disabled={disabled || (pristine && editOrder)}
        type="submit"
      >
        {editOrder || trade}
      </Button>
      {editOrder && (
        <Button
          small
          secondary
          stretch
          aria-label="Tilbake til ordrer"
          onClick={() => handleSelectType('ORDERS')}
        >
          Tilbake til ordre
        </Button>
      )}
    </div>
  );
};

TradeFormButtons.propTypes = {
  disabled: PropTypes.bool,
  tradeType: PropTypes.string.isRequired,
  instrument: PropTypes.shape({
    orderId: PropTypes.string,
  }),
  handleSelectType: PropTypes.func,
  pristine: PropTypes.shape({}),
};

export default TradeFormButtons;
