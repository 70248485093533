import PropTypes from 'prop-types';
import Select from 'react-select';
import { Field } from 'react-final-form';

import styles from '../../../TradeForm.module.scss';

const AccountPicker = props => {
  const { accountPickerOptions, disableField, accountSelected } = props;

  return (
    <Field name="accountSelected">
      {({ input, meta }) => (
        <div>
          <label htmlFor="accountSelected">
            Velg en konto du ønsker å handle på
          </label>
          {accountPickerOptions ? (
            <Select
              {...input}
              isSearchable={false}
              isClearable={false}
              placeholder={'Vis alle kontoer'}
              options={accountPickerOptions}
              isDisabled={disableField}
              value={accountSelected}
            />
          ) : (
            <article className={styles.infoBox}>
              <p>
                Du har ingen VPS-konto knyttet til dine konti. Vennligst ta
                kontakt med banken din.
              </p>
            </article>
          )}
          {meta.error && meta.touched && (
            <span className={styles.formError}>{meta.error}</span>
          )}
        </div>
      )}
    </Field>
  );
};

AccountPicker.propTypes = {
  accountPickerOptions: PropTypes.array,
  disableField: PropTypes.bool,
  accountSelected: PropTypes.number,
};

export default AccountPicker;
