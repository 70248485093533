import Immutable from 'immutable';
import { SET_INSTRUMENT_TO_TRADE } from './types';

const initialState = Immutable.fromJS({
  instrument: false,
});

export default function trader(state = initialState, action) {
  switch (action.type) {
    case SET_INSTRUMENT_TO_TRADE:
      return state.set('instrument', action.instrument);

    default:
      return state;
  }
}
