import PropTypes from 'prop-types';
import { JawsComponent } from '@oms/jaws-react';
import { formatNumber as number } from '@oms/utils';
import { Abbr } from '@oms/components';
import { getSpec } from 'utils/fictivePortfolio';
import Button from 'components/other/Button';
import Row from './row';

import styles from './Portfolio.module.scss';

const FictivePortfolio = ({
  activePortfolio,
  summary,
  setPortfolio,
  deleteInstrument,
}) => {
  if (!activePortfolio) return null;

  return (
    <div className={styles.Portfolio}>
      <Button
        aria-label="Tilbake"
        secondary
        size="small"
        icon="arrow-left"
        onClick={() => setPortfolio(null)}
      />
      <h2>Portefølje: {activePortfolio?.accountName}</h2>

      <div>
        <dl>
          <div>
            <dt>Verdi</dt>
            <dd>{number(summary.marketValue)}</dd>
          </div>
          <div>
            <dt>Ureal. gev./tap</dt>
            <dd>{number(summary.unrealizedProfit)}</dd>
          </div>
        </dl>
        <JawsComponent
          spec={getSpec(activePortfolio.id, activePortfolio.userId)}
        >
          {({ items }) =>
            items.size !== 0 && (
              <table className="table-base table-align-right fictive-table">
                <thead>
                  <tr>
                    <th className="text">Ticker</th>
                    <th>Antall</th>
                    <th>Siste</th>
                    <th>
                      <div>
                        <Abbr title="Urealisert gevinst eller tap">
                          Ureal. gev/tap.
                        </Abbr>
                      </div>
                    </th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {activePortfolio.instruments.map(({ ITEM, SECTOR }) => {
                    const mergeWithJawsData = items.find(
                      item => item.get('ITEM_SECTOR') === `${ITEM}.${SECTOR}`,
                    );
                    const {
                      LASTNZ_DIV,
                      CLOSE,
                      LAST,
                      POSITION_VOLUME,
                      COST_BUY_PRICE,
                      LONG_NAME,
                    } = mergeWithJawsData.toJS();
                    const last = LASTNZ_DIV || CLOSE || LAST || 0;
                    const MARKET_VALUE = POSITION_VOLUME * last;
                    const UNREALIZED_PROFIT = MARKET_VALUE - COST_BUY_PRICE;
                    return (
                      <Row
                        key={ITEM}
                        deleteInstrument={deleteInstrument}
                        item={ITEM}
                        sector={SECTOR}
                        volume={POSITION_VOLUME}
                        last={LAST}
                        longName={LONG_NAME}
                        unrealizedProfit={UNREALIZED_PROFIT}
                        portfolio={{
                          userid: activePortfolio.userId,
                          id: activePortfolio.id,
                        }}
                      />
                    );
                  })}
                </tbody>
              </table>
            )
          }
        </JawsComponent>
      </div>
    </div>
  );
};

FictivePortfolio.propTypes = {
  activePortfolio: PropTypes.object.isRequired,
  summary: PropTypes.object.isRequired,
  setPortfolio: PropTypes.func.isRequired,
  deleteInstrument: PropTypes.func.isRequired,
};

export default FictivePortfolio;
