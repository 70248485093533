import { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import Sentry from 'utils/sentry';
import { Notification } from 'react-notification';
import { logger } from '@oms/utils';
import human from './humaaan.svg';

const log = logger('components:ExceptionCatcher');

export class ExceptionCatcher extends Component {
  static propTypes = {
    children: PropTypes.element.isRequired,
    location: PropTypes.shape({}),
    history: PropTypes.shape({}),
  };

  state = {};

  /** Report error to sentry and enter application error state */
  componentDidCatch(exception, errorInfo) {
    const { location } = this.props;
    const isChunkError = /Loading.*chunk \d+ failed/.test(exception.message);
    /** Avoids entering reload loop by checking if we just reloaded */
    const isRepeatingReload = location.state?.isReload;

    if (isChunkError && !isRepeatingReload) {
      log('Missing chunk error encountered, reloading');
      Sentry.withScope(scope => {
        scope.setLevel(Sentry.Severity.Info);
        scope.setTag('type', 'isChunkError');
        scope.setExtras({ exception });
        Sentry.captureException(
          new Error(
            'Reloading due to missing chunk (probably new code deployed)',
          ),
        );
      });
      this.setReloadFlag(true);
      window.location.reload(true);
      return;
    }

    Sentry.withScope(scope => {
      scope.setTag('type', 'componentDidCatch');
      scope.setExtras({ errorInfo });
      Sentry.captureException(exception);
    });

    this.setState({
      exception,
      componentStack: errorInfo.componentStack,
      eventId: Sentry.lastEventId(),
    });
  }

  setReloadFlag = isReload => {
    const { history, location } = this.props;
    history.replace(location.pathname, { ...(location.state || {}), isReload });
  };

  unsetReloadFlag = () => this.setReloadFlag(false);

  render() {
    const { location, children } = this.props;
    const { exception, eventId } = this.state;
    const hasReloaded = location.state?.isReload;

    if (exception) {
      return (
        <div className="render-error">
          <img alt="Illustrasjon av menneske" src={human} />
          <h1>Oisann, her skjedde det noe galt</h1>
          <hr />
          <p role="alert">
            Under lasting av denne siden skjedde det noe uventet. Vi beklager
            eventuelle ulemper dette medfører.
          </p>
          <p role="alert">
            Dersom du klikker på knappen under og fyller ut hva du gjorde når
            denne feilen oppsto vil det hjelpe oss med å løse problemet.
          </p>
          <code role="alert">{exception.message}</code>
          <small>Feilkode: {eventId}</small>
          <div className="buttons">
            <a className="back-btn" href="/">
              Tilbake til forsiden
            </a>
          </div>
        </div>
      );
    }

    return (
      <>
        {children}
        <Notification
          isActive={hasReloaded}
          message="En ny versjon av applikasjonen var tilgjengelig og vi lastet inn siden på nytt"
          dismissAfter={8000}
          onDismiss={this.unsetReloadFlag}
        />
      </>
    );
  }
}

export default withRouter(ExceptionCatcher);
