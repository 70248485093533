import { useState } from 'react';
import PropTypes from 'prop-types';
import Skeleton from 'react-skeleton-loader';
import {
  handlePortfolioChange,
  deleteTransaction,
} from 'utils/fictivePortfolio';
import { useHandleStatus } from 'state/Hooks/HandleStatus.js';
import Button from 'components/other/Button';
import Portfolio from './Portfolio';
import FictivePortfolioForm from './CreateNewPortfolio';
import PortFoliosList from './PortfoliosList';

import styles from './Portfolios.module.scss';

const FictivePortfolio = ({ userId, portfolios, fetchPortfolio }) => {
  const [activePortfolio, setPortfolio] = useState(null);
  const [status, setStatus] = useHandleStatus(null);

  async function deleteInstrument(id, item, sector) {
    const result = await deleteTransaction(userId, id, item, sector);
    setStatus(result);
    fetchPortfolio();
    return null;
  }

  async function deletePortfolio(id, action) {
    const result = await handlePortfolioChange(userId, action, id);

    if (result.status.error) {
      return setStatus(result.status);
    }
    fetchPortfolio();
    return null;
  }

  return (
    <div className={styles.Portfolios}>
      {portfolios.length > 0 && !activePortfolio && (
        <PortFoliosList
          portfolios={portfolios}
          userId={userId}
          deletePortfolio={deletePortfolio}
          setPortfolio={setPortfolio}
        />
      )}
      {activePortfolio && (
        <Portfolio
          activePortfolio={portfolios.find(
            item => item.id === activePortfolio.portfolioId,
          )}
          summary={activePortfolio.summary}
          setPortfolio={setPortfolio}
          deleteInstrument={deleteInstrument}
        />
      )}
      {!activePortfolio && (
        <FictivePortfolioForm
          portfolios={portfolios}
          fetchPortfolio={fetchPortfolio}
        />
      )}
      {status?.error && (
        <div className={styles.error}>
          <p>Noe gikk galt {status.error}</p>
          <Button secondary size="small" onClick={() => setStatus(null)}>
            Lukk
          </Button>
        </div>
      )}
      {status?.loading && (
        <Skeleton count={3} width="100%" height="50" borderRadius="0" />
      )}
    </div>
  );
};

FictivePortfolio.propTypes = {
  portfolios: PropTypes.array.isRequired,
  userId: PropTypes.string.isRequired,
  fetchPortfolio: PropTypes.func.isRequired,
};

export default FictivePortfolio;
