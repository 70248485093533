import { Component } from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { formatDate, parseDate } from 'react-day-picker/moment';
import moment from 'moment';
import PropTypes from 'prop-types';

import 'react-day-picker/lib/style.css';

const MONTHS = [
  'Januar',
  'Februar',
  'Mars',
  'April',
  'Mai',
  'Juni',
  'Juli',
  'August',
  'September',
  'Oktober',
  'November',
  'Desember',
];
const WEEKDAYS_SHORT = ['Sø', 'Ma', 'Ti', 'On', 'To', 'Fr', 'Lø'];

export default class DayPicker extends Component {
  static propTypes = {
    input: PropTypes.shape({
      value: PropTypes.instanceOf(moment).isRequired,
      onBlur: PropTypes.func.isRequired,
      onFocus: PropTypes.func.isRequired,
      onChange: PropTypes.func.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
    closeDates: PropTypes.array.isRequired,
  };

  constructor(props) {
    super(props);
    this.handleDayClick = this.handleDayClick.bind(this);
  }

  state = { closeDaysArray: [], tradeDay: null };

  handleDayClick(day, { disabled }) {
    const { onChange } = this.props.input;
    if (disabled) return;

    onChange(moment(day));
  }

  render() {
    const { closeDates, input } = this.props;
    const { value, onBlur, onFocus, name } = input;

    const formattedTradeDay = moment(value).format('DD.MM.YYYY');

    return (
      <DayPickerInput
        inputProps={{ readOnly: true }}
        placeholder="DD.MM.YYYY"
        name={name}
        format="DD.MM.YYYY"
        value={formattedTradeDay}
        onDayChange={this.handleDayClick}
        onFocus={() => onFocus(value)}
        onBlur={() => onBlur(value)}
        dayPickerProps={{
          firstDayOfWeek: 1,
          months: MONTHS,
          weekdaysShort: WEEKDAYS_SHORT,
          fromMonth: moment()
            .subtract(10, 'years')
            .toDate(),
          selectedDays: [moment(value).toDate()],
          disabledDays: closeDates,
        }}
        formatDate={formatDate}
        parseDate={parseDate}
      />
    );
  }
}
