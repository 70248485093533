// Dependencies
import { createContext, Component } from 'react';
import PropTypes from 'prop-types';
import Button from 'components/other/Button';
import TestPage from 'containers/pages/TestPage';
import { SidebarConsumer } from 'components/Sidebar';
import styles from './Modal.module.scss';

export const ModalContext = createContext();
const { Provider, Consumer: ModalConsumer } = ModalContext;

class ModalProvider extends Component {
  static propTypes = {
    children: PropTypes.node,
  };
  state = { showModal: false };

  handleToggle = toggleSidebar => {
    if (toggleSidebar) toggleSidebar();
    this.setState(prevState => ({ showModal: !prevState.showModal }));
  };

  render() {
    const value = {
      showModal: this.state.showModal,
      handleToggle: this.handleToggle,
    };
    return <Provider value={value}>{this.props.children}</Provider>;
  }
}

export { ModalProvider, ModalConsumer };

const Modal = () => (
  <SidebarConsumer>
    {({ toggleSidebar, clearInstrument }) => (
      <ModalConsumer>
        {({ showModal, handleToggle }) =>
          showModal && (
            <div className={styles.Modal}>
              <div>
                <Button
                  plain
                  className={styles.close}
                  onClick={() => {
                    clearInstrument();
                    handleToggle(toggleSidebar);
                  }}
                  icon="times"
                >
                  Lukk
                </Button>
                <div>{<TestPage handleToggle={handleToggle} />}</div>
              </div>
            </div>
          )
        }
      </ModalConsumer>
    )}
  </SidebarConsumer>
);

export default Modal;
