import { normalizeNumber } from '@oms/utils';
import moment from 'moment';
import {
  FICTIVE_PORTFOLIOS_URL,
  FICTIVE_TRANSACTIONS_URL,
} from 'constants/Jaws';
import { post, get, del } from 'utils/fetch';

const initialSummary = {
  costPrice: 0,
  marketValue: 0,
  realizedProfit: 0,
  unrealizedProfit: 0,
  unrealizedProfitPct: 0,
  volume: 0,
  valueChangeToday: 0,
  valueChangeTodayPct: 0,
};

export const getSpec = (portfolioId, userId) => ({
  type: 'secure/portfolio',
  backend: 'fstock',
  portfolioId,
  userId,
  columns:
    'ITEM_SECTOR, POSITION_VOLUME, VALUE_CHANGE_TODAY, VALUE,' +
    'COST_BUY_PRICE, CURRENCY, REALIZED_PROFIT, LASTNZ_DIV, LAST, CLOSE,' +
    'INSTRUMENT_TYPE, LONG_NAME',
});

export const portfolioSummary = items =>
  items.reduce((prev, item) => {
    const {
      LASTNZ_DIV,
      CLOSE,
      LAST,
      POSITION_VOLUME,
      COST_BUY_PRICE,
      REALIZED_PROFIT,
    } = item.toJS();
    const last = LASTNZ_DIV || CLOSE || LAST || 0;
    const marketValue = POSITION_VOLUME * last;
    const unrealizedProfit = marketValue - COST_BUY_PRICE;

    return {
      costPrice: prev.costPrice + COST_BUY_PRICE,
      marketValue: prev.marketValue + marketValue,
      realizedProfit: prev.realizedProfit + REALIZED_PROFIT,
      unrealizedProfit: prev.unrealizedProfit + unrealizedProfit,
      unrealizedProfitPct: (unrealizedProfit / COST_BUY_PRICE) * 100,
      volume: prev.positionVolume + POSITION_VOLUME,
    };
  }, initialSummary);

export const createNewPortfolio = async (userId, accountName) => {
  const payload = JSON.stringify({
    backend: 'fstock',
    userId,
    currency: 'NOK',
    accountName,
    accountHolderName: 'string',
    defaultChargeAccount: 'string',
    onlineAvailable: true,
  });

  try {
    const result = await post(`${FICTIVE_PORTFOLIOS_URL}/${userId}`, payload);
    if (result.ok) {
      return { error: false, loading: false, success: result.ok };
    }
    return {
      error: `${result.status} :${result.statusText}`,
      loading: false,
    };
  } catch (err) {
    return { error: err, loading: false };
  }
};

export const getFictivePortfolios = async userId => {
  try {
    const result = await get(`${FICTIVE_PORTFOLIOS_URL}/${userId}`);
    if (result.ok) {
      return {
        portfolios: await result.json(),
        status: {
          error: false,
          loading: false,
        },
      };
    }
    return {
      portfolios: false,
      status: {
        error: result?.statusText,
        loading: false,
      },
    };
  } catch (err) {
    return { error: err, loading: false };
  }
};

export const getInstrumentsPerPortfolio = async portfolio => {
  const instrumentsData = await get(
    `${FICTIVE_PORTFOLIOS_URL}/${portfolio.userId}/instruments/${portfolio.id}`,
  );
  const instruments = await instrumentsData.json();
  const portfolioWithInstruments = { ...portfolio, instruments };
  return portfolioWithInstruments;
};

export const getInstruments = async portfolios => {
  const instrumentsPerPortfolio = await Promise.all(
    portfolios.map(portfolio => getInstrumentsPerPortfolio(portfolio)),
  );

  return instrumentsPerPortfolio;
};

export const handlePortfolioChange = async (userId, action, id) => {
  try {
    const result = await del(`${FICTIVE_PORTFOLIOS_URL}/${userId}/${id}`);
    if (result.ok) {
      return {
        status: {
          error: false,
          loading: false,
        },
      };
    }
    return {
      portfolios: false,
      status: {
        error: result?.statusText,
        loading: false,
      },
    };
  } catch (err) {
    return { error: err, loading: false };
  }
};

export const createTrade = async (userId, values, ticker) => {
  const portfolioId = values?.accountSelected?.value;
  const tradeTime = moment(values?.tradeTime).valueOf();

  const payload = JSON.stringify({
    item: ticker.ITEM,
    sector: ticker.SECTOR,
    tradeTime,
    volume: values.volume,
    price: normalizeNumber(values.limit),
    transFee: 0,
    type: 0,
  });

  try {
    const result = await post(
      `${FICTIVE_TRANSACTIONS_URL}/${userId}/${portfolioId}`,
      payload,
    );
    if (result.ok) {
      return { success: result };
    }

    return { error: `${result.status}: ${result.statusText}` };
  } catch (err) {
    return { error: err, loading: false };
  }
};

export const deleteTransaction = async (userId, id, item, sector) => {
  try {
    const result = await del(
      `${FICTIVE_TRANSACTIONS_URL}/${userId}/${id}?item=${item}&sector=${sector}`,
    );
    if (result.ok) {
      return { success: result };
    }

    return { error: `${result.status}: ${result.statusText}` };
  } catch (err) {
    return { error: err, loading: false };
  }
};
