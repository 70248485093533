import { forwardRef } from 'react';
import * as React from 'react';
import classNames from 'classnames';
import Icon from '../Icons';
import styles from './Checkbox.module.scss';

type Props = {
  className?: string;
  checked?: boolean;
  id?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  boxSize?: 'small' | 'medium' | 'large';
} & React.InputHTMLAttributes<HTMLInputElement>;

const Checkbox = forwardRef<HTMLInputElement, Props>(
  (
    {
      id,
      className,
      onChange: handleChange,
      checked = false,
      boxSize = 'medium',
      ...props
    },
    ref,
  ) => {
    let toggleClass = 'checkbox-off';
    if (checked) toggleClass = 'checkbox-on';
    if (props?.disabled) toggleClass = 'checkbox-disabled';

    return (
      <div
        className={classNames(
          'check-box',
          className,
          toggleClass,
          styles.Checkbox,
          {
            [styles[boxSize]]: boxSize,
          },
        )}
      >
        <input
          ref={ref}
          id={id}
          type="checkbox"
          checked={checked}
          {...props}
          onChange={handleChange}
        />
        <Icon
          icon={checked ? ['fas', 'check-square'] : ['far', 'square']}
          className={toggleClass}
        />
      </div>
    );
  },
);

Checkbox.displayName = 'Checkbox';

export default Checkbox;
