import Immutable from 'immutable';
import { createSelector } from 'reselect';
import { normalizeNumber } from '@oms/utils';
import INITIAL_FUND_PRICE from 'constants/initialFundPrice';

const getCartState = state => state.cart;

export const getCartItems = createSelector([getCartState], cart =>
  cart.get('items'),
);

/** Takes the state contents and turns it into a redux-form initial value */
export const getInitialTotalPrice = createSelector([getCartItems], items => {
  if (!items || items.size === 0) return undefined;

  return items.reduce(
    (sum, item) =>
      sum +
      normalizeNumber(item.amount != null ? item.amount : INITIAL_FUND_PRICE),
    0,
  );
});

/** Takes the state contents and turns it into a redux-form initial value */
export const getCartFormItems = createSelector([getCartItems], items => {
  // Needs to return an empty list to avoid NOR-1022
  if (!items || items.size === 0) return Immutable.List();

  const result = items
    .valueSeq()
    .map(item => {
      const { itemSector, name, SECURITYNAME, LONG_NAME, ITEM_SECTOR } = item;
      const amount = item.amount || INITIAL_FUND_PRICE;

      return {
        name: name || SECURITYNAME || LONG_NAME || ITEM_SECTOR,
        itemSector: itemSector || ITEM_SECTOR,
        amount,
      };
    })
    .toList()
    .toJS();

  return result;
});
