import { combineReducers } from 'redux';
import { reducer as form } from 'redux-form';

import user from './user/reducer';
import holdings from './holdings';
import cart from './cart';
import tax from './tax';
import graphdata from './graphdata/reducer';
import analysisChart from './analysisChart/reducer';
import orders from './orders';
import agreements from './agreements';
import balance from './balance';
import trader from './trader/reducer';

import sniplets from './admin/sniplets/reducer';
import adminCustomers from './admin/customers/reducer';
// TODO Refactor this - why do we have so many audit reducers?
import adminAuditLive from './admin/auditLive/reducer';
import adminAuditSearch from './admin/auditSearch/reducer';
import adminAuditStats from './admin/auditStats/reducer';
import adminAuditReport from './admin/auditReport/reducer';
import adminAssessmentTests from './admin/assessmentTests/reducer';

const rootReducer = combineReducers({
  user,
  holdings,
  cart,
  tax,
  graphdata,
  analysisChart,
  orders,
  agreements,
  balance,
  form,
  adminCustomers,
  adminAuditLive,
  adminAuditSearch,
  adminAuditStats,
  adminAuditReport,
  adminAssessmentTests,
  sniplets,
  trader,
});

export default rootReducer;
