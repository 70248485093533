import { useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useForm } from 'react-final-form';
import { formatNumber as number } from '@oms/utils';
import { getBalance } from 'utils/trade';
import { getUserId } from 'state/user/selectors';

import styles from '../../TradeForm.module.scss';

const AccountBalance = ({ selectedAccount, oldTotal }) => {
  const anonymizedOwnerId = useSelector(getUserId);
  const { change, getFieldState } = useForm();

  /**
   * Fetches balance for account, and updates `accountSelected` field value with the result
   * if account number hasn't changed in the meantime
   */
  const fetchAndUpdateBalance = useCallback(
    account => {
      /** Checks if the currently selected accountnumber is different from the one we fetched balance for */
      const hasChangedFrom = accountNumber => {
        const currentAccountNumber = getFieldState('accountSelected').value
          ?.value;
        if (currentAccountNumber !== accountNumber) return true;
        return false;
      };

      getBalance(account, account?.ask, anonymizedOwnerId)
        .then(accountWithBalance => {
          if (hasChangedFrom(accountWithBalance.value)) return;

          change('accountSelected', accountWithBalance);
        })
        .catch(error => {
          if (hasChangedFrom(account.value)) return;

          change('accountSelected', { ...account, error: `${error}` });
        });
    },
    [anonymizedOwnerId, change, getFieldState],
  );

  // When selected account changes, fetch balance
  useEffect(() => {
    // This stops balance-fetch looping when balance/error field is added on selected account object.
    // It does not stop refetch of balance when user selects a new option (or even the current option),
    // because options in the dropdown are "fresh" and doesn't contain these fields.
    if (
      selectedAccount.balance !== undefined ||
      selectedAccount.error !== undefined
    ) {
      return;
    }

    fetchAndUpdateBalance(selectedAccount);
  }, [selectedAccount, fetchAndUpdateBalance]);

  return (
    <div className={styles.infoMessage}>
      {!selectedAccount?.error ? (
        <p>
          Tilgjengelig på {selectedAccount.value}:
          <b>
            {' '}
            {selectedAccount?.balance
              ? number(selectedAccount?.balance + oldTotal)
              : 0}{' '}
            kr
          </b>
        </p>
      ) : (
        <p>
          Klarte ikke hente saldo på {selectedAccount.value}
          <br />
          <i>Banksystem utilgjengelig</i>
        </p>
      )}
    </div>
  );
};

AccountBalance.propTypes = {
  oldTotal: PropTypes.number,
  selectedAccount: PropTypes.shape({
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    ask: PropTypes.bool.isRequired,
    balance: PropTypes.number,
    error: PropTypes.string,
  }),
};

export default AccountBalance;
