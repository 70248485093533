import { CUSTOMERS_URL } from 'constants/Jaws';
import { post, del } from 'utils/fetch';

export const postCompetencyAssessmentStatus = (
  userId: string,
  centerId: string,
  competencyTestResult: any,
) =>
  post(
    `${CUSTOMERS_URL}/${userId}/competency-test/${centerId}`,
    JSON.stringify({ competencyTestResult }),
  );

export const deleteCompetencyAssessmentStatus = (
  userId: string,
  centerId: string,
) => del(`${CUSTOMERS_URL}/${userId}/competency-test/${centerId}`);
