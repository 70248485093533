import {
  isValidTickSize,
  getLimitRule,
  normalizeNumber,
  formatNumber,
} from '@oms/utils';
import { COMPONENTS_URL } from 'constants/Jaws';
import { get } from 'utils/fetch';

export function validateLimit(limit, limitRules) {
  const normalizedLimit = normalizeNumber(limit);
  const limitPositiveAndPresent = normalizedLimit && normalizedLimit > 0;
  const validTickSize = isValidTickSize(normalizedLimit, limitRules);

  if (limitPositiveAndPresent && !validTickSize) {
    // Allow empty while we are loading tickSize
    if (!limitRules) {
      return 'Feil i uthenting av limit regeler';
    }
    const tickSize = getLimitRule(normalizedLimit, limitRules);
    return `Feil bruk av desimaler etter komma. Dette papiret handles i intervaller på ${tickSize.accuracy}`;
  }

  if (!limitPositiveAndPresent) {
    return 'Ugyldig limit';
  }
  return undefined;
}
export async function fetchPeakSize(item, sector) {
  const query = {
    itemSector: `${item}.${sector}`,
    columns: 'EXCHANGE_MARKET_SIZE',
  };
  const response = await get({
    url: `${COMPONENTS_URL}{?query*}`,
    query,
  });

  if (!response.ok) {
    throw new Error(await response.text());
  }
  return response.json();
}

export async function validateOpenVolume({
  hiddenVolume,
  openVolume,
  volume,
  limit,
  item,
  sector,
}) {
  const fetchData = await fetchPeakSize(item, sector);
  const peakSize = fetchData?.rows[0].values.EXCHANGE_MARKET_SIZE || 0;
  // Is less than volume and greater than min peakSize
  const normalizedOpenVolume = normalizeNumber(openVolume);
  const normalizedVolume = normalizeNumber(volume);
  const normalizedLimit = normalizeNumber(limit);

  if (hiddenVolume) {
    if (!openVolume) {
      return 'Ugyldig synlig volum';
    }
    if (normalizedOpenVolume < peakSize) {
      return `Synlig volum må være større enn peak size: ${peakSize}`;
    }

    if (normalizedOpenVolume >= normalizedVolume) {
      return 'Synlig volum må være mindre enn totalt antall';
    }

    /*
     * Minimum peak size for iceberg orders (EMS)
     * Defines the minimum visible size (“the peak”) when entering
     * orders where a part of the total volume is hidden
     * (“iceberg orders”).
     */

    /*
     * Order size must be minimum 10000 EUR when using hidden
     * volume. We validate against NOK, and this value is adjusted
     * yearly.
     */
    const totalPrice = normalizedVolume * normalizedLimit;
    const minPrice = 100000; // Adjusted yearly
    if (totalPrice < minPrice) {
      return `Beløp må være større enn ${formatNumber(minPrice, 0)} kroner`;
    }
  }
  return undefined;
}
