import { Field } from 'react-final-form';
import { useBlockWheel } from 'utils/useBlockWheel';
import styles from '../../../TradeForm.module.scss';

export const Volume = () => {
  const onWheel = useBlockWheel();
  return (
    <Field name="volume">
      {({ input, meta }) => (
        <div>
          <label htmlFor="volume">Antall aksjer</label>
          <input
            {...input}
            placeholder="Antall"
            name="volume"
            type="number"
            onWheel={onWheel}
          />
          {meta.error && meta.touched && (
            <span className={styles.formError}>{meta.error}</span>
          )}
        </div>
      )}
    </Field>
  );
};

export default Volume;
