import Select from 'react-select';
import { Field, useFormState } from 'react-final-form';
import { tickerSelections } from 'utils/alarms/options';
import styles from '../../Alarms.module.scss';

const required = value => (value ? undefined : 'Velg et utvalg');

export const SelectionField = () => {
  const { values } = useFormState();

  const options = tickerSelections[values.commoditiesType?.value];

  return (
    <Field name="selection" validate={required}>
      {({ input, meta }) => (
        <div className={styles.field}>
          <label htmlFor="selection">{values?.commoditiesType?.label}</label>
          <Select
            {...input}
            isSearchable={false}
            isClearable={false}
            placeholder={`Utvalg av ${values?.commoditiesType?.label}`}
            options={options}
            value={
              options?.find(opt => opt.value === input.value?.value) || null
            }
          />
          {meta.error && meta.touched && (
            <span className={styles.error}>{meta.error}</span>
          )}
        </div>
      )}
    </Field>
  );
};

export default SelectionField;
