import PropTypes from 'prop-types';
import classNames from 'classnames';
import Icon from '../index';
import styles from './AnimatedDropDown.module.scss';

const AnimatedDropDownFund = ({ isOpen }) => (
  <Icon
    icon={['fas', 'angle-down']}
    className={classNames(styles.AnimatedDropDown, { [styles.isOpen]: isOpen })}
  />
);

AnimatedDropDownFund.propTypes = {
  isOpen: PropTypes.bool.isRequired,
};

export default AnimatedDropDownFund;
