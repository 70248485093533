import cookies from 'js-cookie';
import { createSelector } from 'reselect';
import { termsLastUpdated } from 'constants/terms';
import * as User from 'utils/user';
import * as Customer from 'utils/customer';

const getUserFilter = state => state.user;
const getCenterIdProp = (_, props) => props.centerId;

export const getUserId = createSelector([getUserFilter], user =>
  User.getUserId(user),
);

export const getUserCustomerNumber = createSelector([getUserFilter], user =>
  User.getUserCustomerNumber(user),
);

export const getCustomerData = createSelector([getUserFilter], user =>
  User.getCustomerData(user),
);

export const getIsFreeRTStreamingActive = createSelector(
  [getCustomerData],
  customer => {
    return customer?.get('freeRTStreamingActive');
  },
);

export const getGuardianData = createSelector([getUserFilter], user =>
  User.getGuardianData(user),
);

export const getAdminData = createSelector([getUserFilter], user =>
  User.getAdminData(user),
);

export const getHasElectronicIdentification = createSelector(
  [getCustomerData],
  customerData => {
    if (!customerData) return false;
    return customerData.get('hasElectronicIdentification');
  },
);

export const getcompetencyTestResult = createSelector(
  [getCustomerData],
  customerData => {
    if (!customerData) return false;
    return customerData.get('competencyTestResult');
  },
);

export const getcompetencyTestTimestamp = createSelector(
  [getCustomerData],
  customerData => {
    if (!customerData) return false;
    return customerData.get('competencyTestTimestamp');
  },
);

export const getAccountId = createSelector([getUserFilter], user =>
  Customer.getAccountId(user),
);

export const getCenterId = createSelector([getCustomerData], customer =>
  // Intentional use of undefined here, as null means norne securities
  customer ? customer.get('centerId') : undefined,
);

export const getBankPermissions = createSelector([getCustomerData], customer =>
  customer ? customer.get('bank').get('permissions') : undefined,
);

export const createBankPermissionSelector = permissionName =>
  createSelector([getBankPermissions], bankPermissions =>
    bankPermissions
      ? bankPermissions.some(item => item.get('name') === permissionName)
      : false,
  );

export const createCustomerDataFieldSelector = fieldName =>
  createSelector([getCustomerData], customerData =>
    customerData ? customerData.get(fieldName) : undefined,
  );

export const canUseSavingsRobot = createBankPermissionSelector('SAVINGS_ROBOT');

/** TODO: Consolidate these two flags please. Two flags is really unecessary */
export const canTradeStocks = createSelector([getCustomerData], customer =>
  customer
    ? customer.get('tradeStocks') && customer.get('bank')?.get('tradeStocks')
    : false,
);

export const canTradeFunds = createSelector([getCustomerData], customer =>
  customer ? customer.get('bank')?.get('tradeFunds') : false,
);

export const getAdminCenterId = createSelector([getAdminData], adminData => {
  return adminData
    ?.get('centerId')
    ?.toString()
    .padStart(4, '0');
});

export const getAccounts = createSelector([getCustomerData], customerData =>
  customerData?.get('accounts'),
);

export const getOwnAccounts = createSelector(
  [getAccounts, getUserCustomerNumber],
  (accounts, customerNumber) =>
    accounts?.filter(account => account.get('ownerId') === customerNumber),
);

/** Gets unique account ownerIds */
export const getUsers = createSelector([getAccounts], accounts =>
  accounts
    ?.groupBy(account => account.get('ownerId')) // Only keep first account (dedupe by user)
    .map(accountList => accountList.first())
    .toArray() // Prune account-specific properties
    .map(account => ({
      fullName: account.get('fullName'),
      ownerId: account.get('ownerId'),
    })),
);

export const getSelectedAccountKey = createSelector([getUserFilter], user =>
  user.get('selectedAccount'),
);

export const setSelectedUserId = createSelector([getUserFilter], user =>
  user.get('selectedUser'),
);

export const getEditAccountKey = createSelector([getUserFilter], user =>
  user.get('editAccount'),
);

export const getIsNewCustomer = createSelector(
  [getCustomerData],
  customerData => {
    if (!customerData) return false;
    return customerData.get('isNewCustomer', true);
  },
);

export const getMasterAccount = createSelector(
  [getCustomerData],
  customerData => {
    if (!customerData) return null;
    return customerData.get('masterAccount');
  },
);

export const getLegalEntity = createSelector(
  [getMasterAccount],
  masterAccount => {
    if (!masterAccount) return null;
    return masterAccount.get('legalEntity');
  },
);

export const getIsOrganization = createSelector(
  [getLegalEntity],
  legalEntity => {
    if (!legalEntity) return null;
    return legalEntity === 'Company';
  },
);

export const getMasterAccountId = createSelector(
  [getMasterAccount],
  masterAccount => {
    if (!masterAccount) return null;
    return masterAccount.get('id');
  },
);

export const getAgreedToTOS = createSelector(
  [getCustomerData],
  customerData => {
    if (!customerData) return false;
    const timestamp = customerData.get('userAgreementTimestamp');

    return timestamp && new Date(timestamp) > termsLastUpdated;
  },
);

export const createHasRoleSelector = role =>
  createSelector([getUserFilter], user => {
    const roles = user.getIn(['principals', 0, 'roles']);
    return roles ? roles.includes(role) : false;
  });

// TODO : Should be validated if this is really as intended.
export const getSelectedAccount = createSelector(
  [getCustomerData],
  customerData => Customer.getSelectedAccount(customerData),
);

// Used for HTest where you need the logged in user details
export const getOwnerId = createSelector(
  [getSelectedAccount],
  masterAccount => masterAccount && masterAccount.get('ownerId'),
);

export const getSelectedUserId = createSelector(
  [getUserFilter, getUserCustomerNumber, getMasterAccount, getAccounts],
  (user, userCustomerNumber, masterAccount, accounts) => {
    const selectedUser = user.get('selectedUser');
    if (selectedUser) {
      return selectedUser;
    }
    const cookieSelectedUser = User.getSelectedUserIdFromCookie();
    if (cookieSelectedUser) {
      const havePermission = accounts?.some(
        account => account.get('ownerId') === cookieSelectedUser,
      );
      if (havePermission) {
        return cookieSelectedUser;
      } else if (accounts?.size && !havePermission) {
        cookies.remove('selectedUserId');
      }
    }
    return masterAccount?.get('ownerId') || userCustomerNumber;
  },
);

export const getSelectedUserAnonymizedId = createSelector(
  [getSelectedUserId, getAccounts],
  (selectedUserId, accounts) => {
    const account = accounts?.find(
      accountId => accountId.get('ownerId') === selectedUserId,
    );
    return account?.get('anonymizedOwnerId');
  },
);

export const getAccountIdOnSelectedUser = createSelector(
  [getSelectedUserId, getAccounts],
  (selectedUserId, accounts) => {
    const account = accounts?.find(
      accountId => accountId.get('ownerId') === selectedUserId,
    );
    return account?.get('id');
  },
);

export const getAccountsOnSelectedUser = createSelector(
  [getSelectedUserId, getAccounts],
  (selectedUserId, accounts) =>
    accounts?.filter(account => account.get('ownerId') === selectedUserId),
);

export const getSelectedUserIsReadOnly = createSelector(
  [getAccountsOnSelectedUser],
  accounts => accounts?.some(account => account.get('isReadOnly')) || false,
);

export const getSelectedUserLegalEntity = createSelector(
  [getAccountsOnSelectedUser],
  accounts =>
    accounts?.find(account => account.get('legalEntity'))?.get('legalEntity'),
);

/** Get stock trading accounts on selected user */
export const getStockTradingAccounts = createSelector(
  [getAccountsOnSelectedUser],
  accountsOnSelectedUser =>
    accountsOnSelectedUser
      .filter(account => !account.get('ips'))
      .filter(account => account.get('vpsAccountNo') !== ''),
);

/**
 * Norne is a bit special in that multiple accounts can be selected for
 * viewing at the same time.
 */
export const getSelectedAccounts = createSelector(
  [getAccounts, getSelectedAccountKey, getAccountsOnSelectedUser],
  (accounts, selectedAccount, accountsOnSelectedUser) =>
    selectedAccount === 'ALL'
      ? accountsOnSelectedUser
      : accounts?.filter(account =>
          selectedAccount.split(',').includes(account.get('id')),
        ),
);

export const getFilterAccounts = createSelector(
  [getUserFilter, getAccounts, getSelectedAccountKey],
  (user, accounts, selectedAccount) => {
    const userName = user.getIn([
      'principals',
      '0',
      'masterAccount',
      'fullName',
    ]);

    return selectedAccount === 'ALL'
      ? accounts.filter(account => account.get('fullName') === userName)
      : accounts.filter(account =>
          selectedAccount.split(',').includes(account.get('id')),
        );
  },
);

export const getShouldShowCash = createSelector(
  [getSelectedAccounts],
  selectedAccounts =>
    selectedAccounts?.some(
      account => account.get('ask') || account.get('deposit'),
    ) || false,
);

/**
 * Used for fetching accounts that support the balance call
 */
export const getSelectedAccountsWithDepot = createSelector(
  [getSelectedAccounts],
  selectedAccounts =>
    selectedAccounts
      ? selectedAccounts.filter(
          account => account.get('ask') || account.get('ips'),
        )
      : selectedAccounts,
);

export const getDefaultAccount = createSelector(
  [getSelectedAccountKey],
  selectedAccount => (selectedAccount === 'ALL' ? null : selectedAccount),
);

export const getAccountsOnCenterId = createSelector(
  [getCenterIdProp, getAccounts],
  (centerId, accounts) =>
    accounts.filter(account => account.get('centerId') === centerId),
);

export const getUniqueCenterIds = createSelector([getAccounts], accounts =>
  accounts.reduce((set, account) => {
    const alreadyAdded = set.find(item => item.id === account.get('centerId'));
    if (alreadyAdded) return set;

    const bank = {
      id: account.get('centerId'),
      name: account.get('bankName'),
    };

    return [...set, bank];
  }, []),
);

export const getUserSelectedAccount = createSelector(
  [getAccounts, getSelectedAccountKey],
  (accounts, accountKey) =>
    accounts?.find(account => account.get('id') === accountKey),
);

export const getAccountsFilterCenterId = createSelector(
  [getAccounts, getCenterIdProp],
  (accounts, centerId) =>
    accounts.filter(
      account =>
        account.get('status') === 'A' && account.get('centerId') === centerId,
    ),
);

// Used for HTest where you need the logged in user details
export const getFullName = createSelector(
  [getCustomerData],
  accounts => accounts && accounts.get('masterAccount')?.get('fullName'),
);

export const getCustomerInformation = createSelector(
  [getMasterAccount],
  masterAccount => {
    return {
      address: masterAccount?.get('address'),
      addressCountryCode: masterAccount?.get('addressCountryCode'),
      zipNumber: masterAccount?.get('zipNumber'),
      zipDesc: masterAccount?.get('zipDesc'),
      mobileNumber: masterAccount?.get('mobileNumber'),
      nationality: masterAccount?.get('nationality'),
      ownerId: masterAccount?.get('ownerId'),
      email: masterAccount?.get('email'),
    };
  },
);

export const getMail = createSelector(
  [getCustomerData],
  accounts => accounts && accounts.get('masterAccount')?.get('email'),
);

export const getSelectedUserFullName = createSelector(
  [getSelectedUserId, getAccounts],
  (selectedUserId, accounts) => {
    if (!accounts) return false;
    const userAccount = accounts.find(
      account => account.get('ownerId') === selectedUserId,
    );
    return userAccount?.get('fullName');
  },
);

export const getHasIPSAccount = createSelector(
  [getAccounts, getSelectedUserId],
  (accounts, userId) =>
    accounts.some(
      account => account.get('ownerId') === userId && account.get('ips'),
    ),
);

export const getIsAsk = createSelector([getSelectedAccount], selectedAccount =>
  selectedAccount.get('ASK'),
);

export const getIsIpsAccount = createSelector(
  [getSelectedAccounts],
  selectedAccounts => selectedAccounts.some(account => account.get('ips')),
);

export const getIsMarginAccount = createSelector(
  [getSelectedAccount],
  selectedAccount => selectedAccount.get('isMarginAccount'),
);

export const getIsCustomerOrTakeover = createSelector([getUserFilter], user =>
  User.isCustomerOrTakeover(user),
);

export const getIsSupportTakeOver = createSelector([getUserFilter], user =>
  User.isSupportTakeover(user),
);

export const getUserIsFetching = createSelector([getUserFilter], user =>
  user.get('isFetching'),
);
export const getIsTakeover = createSelector([getUserFilter], user =>
  User.isTakeover(user),
);

export const getIsGuardian = createSelector([getUserFilter], user =>
  User.isGuardian(user),
);

export const getIsLoggedIn = createSelector([getUserFilter], user =>
  User.isLoggedIn(user),
);

export const getIsOwnTakeover = createSelector([getUserFilter], user =>
  User.isOwnTakeover(user),
);

const getAccountBalance = createSelector(
  [getSelectedAccount],
  selectedAccount => selectedAccount.get('balance'),
);

export const getIpsReturnCurrentYear = createSelector(
  [getUserSelectedAccount],
  selectedAccount => {
    return selectedAccount.get('ipsReturnCurrentYear');
  },
);

export const getMarginInfo = createSelector(
  [getSelectedAccount],
  selectedAccount => selectedAccount.get('marginInfo') || {},
);

export const getBalance = createSelector(
  [getIsMarginAccount, getAccountBalance, getMarginInfo],
  (isMarginAccount, balance, marginInfo) =>
    isMarginAccount ? marginInfo.buyingPowerMax : balance,
);

/** Check if user can view pension data from Norsk Pensjon */
export const getCanViewPension = createSelector(
  [getIsOrganization, getIsGuardian, getIsTakeover],
  (isOrg, isGuardian, isTakeover) => {
    if (isOrg || isGuardian || isTakeover) return false;
    return true;
  },
);

export const hasLevel2 = createSelector([getUserFilter], user =>
  User.hasLevel2(user),
);
