import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './Alarms.module.scss';
import { useSidebar } from 'components/Sidebar';

export const AlarmHeader = ({ handleAlarmMenu, showMyAlarms }) => {
  const { alarmToHandle } = useSidebar();
  return (
    <header>
      <button
        type="button"
        onClick={() => handleAlarmMenu(true)}
        className={classNames({
          [styles.active]: showMyAlarms,
        })}
      >
        Mine alarmer
      </button>
      <button
        type="button"
        onClick={() => handleAlarmMenu(false)}
        className={classNames({
          [styles.active]: !showMyAlarms,
        })}
      >
        {alarmToHandle ? 'Endre' : 'Ny'} alarm
      </button>
    </header>
  );
};
AlarmHeader.propTypes = {
  handleAlarmMenu: PropTypes.func,
  showMyAlarms: PropTypes.bool,
};

export default AlarmHeader;
