import { normalizeNumber, formatNumber } from '@oms/utils';

import styles from '../../TradeForm.module.scss';

type Props = {
  values: {
    tradeType: 'BUY' | 'SELL';
    orderType: 'normal' | 'fillAndKill' | 'fillOrKill';
    commission?: number;
    limit?: number;
    volume?: number;
  };
};

const Summary = ({ values }: Props) => {
  if (!values) return null;

  const normalizedLimit = normalizeNumber(values.limit);
  const normalizedVolume = normalizeNumber(values.volume);
  const totalPrice =
    values.tradeType === 'BUY'
      ? normalizedLimit * normalizedVolume + (values.commission || 0)
      : normalizedLimit * normalizedVolume - (values.commission || 0);
  return (
    <div className={styles.Summary}>
      <table>
        <tbody>
          <tr>
            <td>Kurtasje 0,05% per handel, minimum 95,-</td>
            <td>{formatNumber(values.commission)} kr</td>
          </tr>
          <tr>
            <td>Estimert ordreverdi</td>
            <td>{formatNumber(normalizedLimit * normalizedVolume)} kr</td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td>Total estimert beløp</td>
            <td>{formatNumber(totalPrice)} kr</td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
};

export default Summary;
