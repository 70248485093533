import { Iterable } from 'immutable';

type Account = {
  id: string;
  ips: boolean;
  ask: boolean;
  isMarginAccount?: boolean;
  bankAccountNo?: string;
  accountName?: string;
};

export const getShortAccountLabel = (account: Account) => {
  if (account.ips) return `IPS, ${account.id}`;
  if (account.ask) return `ASK, ${account.id}`;
  return account.id;
};

export const getLongAccountLabel = (account: Account) =>
  account.accountName
    ? `${account.accountName} (${getShortAccountLabel(account)})`
    : getShortAccountLabel(account);

export const getAccountTypeLabel = (type: 'ask' | 'equities' | 'ips') => {
  if (type === 'ask') return 'ASK';
  if (type === 'ips') return 'IPS';
  if (type === 'equities') return 'Fond/Aksjer utenfor ASK';
  return null;
};

export const dropdownOptions = (accounts: Iterable<any, any>) => {
  if (!accounts || accounts.size === 0) return [];
  return accounts
    .map(account => ({
      value: account.get('id'),
      label: getLongAccountLabel(account.toJS()),
      ask: account.get('ask'),
      isMarginAccount: account.get('isMarginAccount'),
      ips: account.get('ips'),
      equities: !account.get('ask') && !account.get('ips'),
      bankAccountNo: account.get('bankAccountNo'),
      accountName: account.get('accountName'),
      id: account.get('id'),
      paymentAgent: account.get('paymentAgent'),
    }))
    .toJS();
};
