import { useState } from 'react';
import { useSelector } from 'react-redux';
import { getMail } from 'state/user/selectors';

import AlarmTypeChooser from './AlarmTypeChooser';
import NewsAlarm from './NewsAlarm';
import MarkedAlarm from './MarkedAlarm';
import { useSidebar } from 'components/Sidebar';

export const AlarmForm = () => {
  const { alarmToHandle, handleAlarm } = useSidebar();
  const [newsAlarm, setNewsAlarm] = useState(alarmToHandle?.type === 'NEWS');
  const handleAlarmTypeChange = () => {
    handleAlarm();
    setNewsAlarm(s => !s);
  };
  const email = useSelector(state => getMail(state));

  return (
    <>
      <AlarmTypeChooser
        newsAlarm={newsAlarm}
        handleAlarmTypeChange={handleAlarmTypeChange}
        alarmToHandle={alarmToHandle}
      />
      {newsAlarm ? (
        <NewsAlarm alarmToHandle={alarmToHandle} />
      ) : (
        <MarkedAlarm alarmToHandle={alarmToHandle} />
      )}
      <article>
        <h3>Alarmer</h3>
        <p>
          Her kan du sette opp alarmer på aksjer du ønsker å følge ekstra nøye.{' '}
          <br />
          Når en alarm utløses sendes det e-post til: <br />{' '}
          <b>{email || 'Fant ingen epostadresse'}</b>.
        </p>
      </article>
    </>
  );
};

export default AlarmForm;
