import Immutable from 'immutable';
import cookies from 'js-cookie';
import {
  LOGOUT_USER,
  REQUEST_USER_INFO,
  RECEIVE_USER_INFO,
  USER_INFO_SET_IPS_RETURN_CURRENT_YEAR,
  USER_SET_SELECTED_ACCOUNT,
  USER_SET_SELECTED_USER,
  USER_NOT_AUTHENTICATED,
  BALANCE_OR_MARGIN_FAILED,
  USER_INFO_SET_MARGIN_INFO,
  USER_INFO_SET_BALANCE,
  USER_SET_EDIT_ACCOUNT,
} from './types';

const initialState = Immutable.fromJS({
  selectedAccount: 'ALL',
  isFetching: true,
  authenticated: false,
  principals: [],
  balanceOrMarginFailed: false,
});

export default function user(state = initialState, action) {
  switch (action.type) {
    case LOGOUT_USER:
      return initialState.set('isFetching', false);

    case REQUEST_USER_INFO:
      return state.set('isFetching', true);

    case RECEIVE_USER_INFO:
      return state.merge(Immutable.fromJS(action.data)).merge(
        Immutable.fromJS({
          isFetching: false,
        }),
      );

    case USER_SET_SELECTED_ACCOUNT:
      return state.set('selectedAccount', action.accountId);

    case USER_SET_SELECTED_USER:
      cookies.set('selectedUserId', action.userId);
      return state.set('selectedUser', action.userId);

    case USER_SET_EDIT_ACCOUNT:
      return state.set('editAccount', action.accountToEdit);

    case BALANCE_OR_MARGIN_FAILED:
      return state
        .set(
          'principals',
          state.get('principals').map(p => {
            if (p.get('userType') !== 'customer') return p;

            return p.set(
              'accounts',
              p
                .get('accounts')
                .map(account =>
                  account.get('id') === action.accountId
                    ? account.set('balance', null).set('marginInfo', {})
                    : account,
                ),
            );
          }),
        )
        .set('balanceOrMarginFailed', true);

    case USER_INFO_SET_BALANCE:
      return state
        .set(
          'principals',
          state.get('principals').map(p => {
            if (p.get('userType') !== 'customer') return p;

            return p.set(
              'accounts',
              p
                .get('accounts')
                .map(account =>
                  account.get('id') === action.accountId
                    ? account.set('balance', action.balance)
                    : account,
                ),
            );
          }),
        )
        .set('balanceOrMarginFailed', false);

    case USER_INFO_SET_IPS_RETURN_CURRENT_YEAR:
      return state
        .set(
          'principals',
          state.get('principals').map(p => {
            if (p.get('userType') !== 'customer') return p;

            return p.set(
              'accounts',
              p
                .get('accounts')
                .map(account =>
                  account.get('id') === action.accountId
                    ? account.set(
                        'ipsReturnCurrentYear',
                        action.ipsReturnCurrentYear,
                      )
                    : account,
                ),
            );
          }),
        )
        .set('balanceOrMarginFailed', false);

    case USER_INFO_SET_MARGIN_INFO:
      return state
        .set(
          'principals',
          state.get('principals').map(p => {
            if (p.get('userType') !== 'customer') return p;

            return p.set(
              'accounts',
              p
                .get('accounts')
                .map(account =>
                  account.get('id') === action.accountId
                    ? account.set('marginInfo', action.marginInfo)
                    : account,
                ),
            );
          }),
        )
        .set('balanceOrMarginFailed', false);

    case USER_NOT_AUTHENTICATED:
      return state.set('isFetching', false);

    default:
      return state;
  }
}
