import { Field } from 'react-final-form';

import styles from '../../../TradeForm.module.scss';

export const OpenVolume = () => (
  <Field name="openVolume">
    {({ meta }) => (
      <div>
        <label htmlFor="openVolume">Synlig antall</label>
        <Field
          name="openVolume"
          component="input"
          type="text"
          placeholder="Synlig antall"
        />
        {meta.error && meta.touched && (
          <span className={styles.formError}>{meta.error}</span>
        )}
      </div>
    )}
  </Field>
);

export default OpenVolume;
