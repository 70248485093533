// This is a duck experiment.. Quack! https://github.com/erikras/ducks-modular-redux
import Immutable from 'immutable';
import { ORDERS_URL } from 'constants/Jaws';

import { get } from 'utils/fetch';
import { getUserId, getAccountsOnSelectedUser } from 'state/user/selectors';

const RECIEVE_ORDERS = 'norne/orders/RECIEVE_ORDERS';

const initialState = Immutable.fromJS({
  portfolios: {},
});

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case RECIEVE_ORDERS:
      return state.set('portfolios', Immutable.fromJS(action.payload));
    default:
      return state;
  }
}

export const fetchOrders = () => async (dispatch, getState) => {
  const state = getState();
  const userId = getUserId(state);
  const accounts = getAccountsOnSelectedUser(state);

  const promises = accounts.map(async item => {
    const account = item.toJS();
    const ordersResponse = await get({
      /** (!) NOTE: This url does include stock-orders (!) */
      url: `${ORDERS_URL}/${userId}/fund/orders/active{?query*}`,
      query: { accountId: account.id },
    });

    const switchReponse = await get({
      url: `${ORDERS_URL}/${userId}/fund/orders/switch{?query*}`,
      query: { accountNumber: account.id, includeHistoricOrders: false },
    });

    const isJson = response =>
      response.headers.get('content-type') === 'application/json';

    const ordersContent = await (isJson(ordersResponse)
      ? ordersResponse.json()
      : ordersResponse.text());

    const switchContent = await (isJson(switchReponse)
      ? switchReponse.json()
      : switchReponse.text());

    return {
      account,
      error: ordersResponse.ok ? undefined : ordersContent, // TODO: Is this backwards-comp needed?
      orders: ordersResponse.ok ? ordersContent.data : { error: ordersContent },
      switches: switchReponse.ok ? switchContent : { error: switchContent },
    };
  });

  // Send in parallell, block with await afterwards
  const responses = await Promise.all(promises);
  const orders = responses.reduce(
    (result, data) => result.set(data.account.id, Immutable.fromJS(data)),
    Immutable.Map(),
  );

  dispatch({ type: RECIEVE_ORDERS, payload: orders });
};

/* TODO, check if this is right ↓
 * It's not, for it to be located here it should dispatch to set values into redux.
 */
export const fetchTodaysOrders = () => async (dispatch, getState) => {
  const state = getState();
  const userId = getUserId(state);
  const accounts = getAccountsOnSelectedUser(state);

  const promises = accounts.map(async item => {
    const account = item.toJS();
    const result = await get({
      url: `${ORDERS_URL}/${userId}/orders/today{?query*}`,
      query: { accountId: account.id },
    });

    const isJson = result.headers.get('content-type') === 'application/json';
    const content = await (isJson ? result.json() : result.text());

    if (!result.ok) {
      return {
        account,
        error: content,
      };
    }

    return {
      account,
      orders: content.data,
    };
  });

  // Send in parallell, block with await afterwards
  const responses = await Promise.all(promises);
  const orders = responses.reduce(
    (result, data) => result.set(data.account.id, Immutable.fromJS(data)),
    Immutable.Map(),
  );
  return orders;
};
