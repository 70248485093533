import {
  createContext,
  useContext,
  useEffect,
  useReducer,
  useMemo,
  useCallback,
} from 'react';
import { node, string } from 'prop-types';
import { connect } from 'react-redux';
import { getUserId } from 'state/user/selectors';
import {
  deleteAlarm as utilDeleteAlarm,
  getAlarms,
  editAlarm,
  updateAlarmPayload,
  createMarkedAlarm as utilCreateMarkedAlarm,
} from 'utils/alarms/alarms';
import { optimisticToggle, types, getType, alarmReducer } from './utils';

/** CONVERTED NOR-1735, NOR-1806 to be used with hooks   */
export const AlarmContext = createContext();
const useAlarm = () => useContext(AlarmContext);

function Provider({ children, userId }) {
  const [
    { alarms, newsAlarms, fetching, error, submitting },
    dispatch,
  ] = useReducer(alarmReducer, {
    previousAlarms: [],
    alarms: [],
    fetching: false,
    error: null,
    submitting: false,
  });

  // → These action functions don't like being wrapped with useCallback
  const fetchAlarms = useCallback(async () => {
    dispatch({ type: types.fetching });
    try {
      const result = await getAlarms(userId);
      if (!result.ok) {
        dispatch({ type: types.error, payload: await result.statusText });
      }
      const data = await result.json();
      dispatch({ type: types.success, payload: data });
    } catch (err) {
      dispatch({ type: types.error, payload: err });
    }
  }, [userId]);

  const toggleAlarm = useCallback(
    async (values, toggle = false) => {
      dispatch({ type: types.submitting });
      dispatch({
        type: types.success,
        payload: optimisticToggle(alarms, values),
      });

      const payload = updateAlarmPayload(values, toggle);

      try {
        const result = await editAlarm(
          values.userId,
          payload,
          getType(payload),
          values.id,
        );
        if (!result.ok) {
          dispatch({ type: types.error, payload: result.statusText });
        }
        // ✓ success, optimistic update already has taken care of state updates
      } catch (err) {
        dispatch({ type: types.error, payload: err });
      }
    },
    [alarms],
  );

  const deleteAlarm = useCallback(
    async ({ type, id }) => {
      dispatch({ type: types.submitting });

      const optimisticUpdate = alarms.filter(alarm => alarm.id !== id);

      dispatch({ type: types.success, payload: optimisticUpdate });

      try {
        const result = await utilDeleteAlarm(userId, type, id);

        if (!result.ok) {
          return dispatch({ type: types.error, payload: await result.text() });
        }
        // ✓ success, optimistic update already has taken care of state updates
      } catch (err) {
        return dispatch({ type: types.error, payload: err });
      }
      return null;
    },
    [alarms, userId],
  );

  const updateAlarm = useCallback(
    async values => {
      dispatch({ type: types.submitting });
      const payload = updateAlarmPayload(values);

      try {
        const result = await editAlarm(
          values.userId,
          payload,
          getType(payload),
          values.id,
        );

        if (!result.ok) {
          return dispatch({ type: types.error, payload: result.statusText });
        }
        // ✓ Success, → refetch alarms and flush stale data
        return fetchAlarms();
      } catch (err) {
        return dispatch({ type: types.error, payload: err });
      }
    },
    [fetchAlarms],
  );

  /**
   * Function that creates a new 'marked' alarm
   * @param {object} config { values, item, sector }
   * @param {*} callback success callback
   */
  const createNewMarkedAlarm = useCallback(
    async ({ values, item, sector }, callback = () => {}) => {
      dispatch({ type: types.submitting });

      try {
        const result = await utilCreateMarkedAlarm(
          values,
          userId,
          item,
          sector,
        );
        if (!result.ok) {
          return dispatch({ type: types.error, payload: result.statusText });
        }
        // ✓ Success, → refetch alarms and flush stale data, and trigger success callback
        fetchAlarms();
        return callback();
      } catch (err) {
        return dispatch({ type: types.error, payload: err });
      }
    },
    [fetchAlarms, userId],
  );

  /**
   * Function that creates a new 'news' alarm
   * @param {object} config { values, item, sector }
   * @param {*} callback success callback
   */
  const createNewsAlarm = useCallback(
    async ({ values, item, sector }, callback = () => {}) => {
      dispatch({ type: types.submitting });

      try {
        let result;

        if (values.id) {
          const payload = updateAlarmPayload(values, true);
          result = await editAlarm(userId, payload, values.type, values.id);
        } else {
          result = await utilCreateMarkedAlarm(values, userId, item, sector);
        }

        if (!result.ok) {
          return dispatch({ type: types.error, payload: result.statusText });
        }
        // ✓ Success, → refetch alarms and flush stale data, and trigger success callback
        fetchAlarms();
        return callback();
      } catch (err) {
        return dispatch({ type: types.error, payload: err });
      }
    },
    [fetchAlarms, userId],
  );

  useEffect(() => {
    if (userId) {
      fetchAlarms();
    }
  }, [userId, fetchAlarms]);

  const value = useMemo(
    () => ({
      alarms,
      error,
      newsAlarms,
      fetching,
      submitting,
      toggleAlarm,
      deleteAlarm,
      updateAlarm,
      createNewMarkedAlarm,
      createNewsAlarm,
      refetch: fetchAlarms,
    }),
    [
      fetchAlarms,
      alarms,
      error,
      newsAlarms,
      fetching,
      submitting,
      toggleAlarm,
      deleteAlarm,
      updateAlarm,
      createNewMarkedAlarm,
      createNewsAlarm,
    ],
  );

  return (
    <AlarmContext.Provider value={value}>{children}</AlarmContext.Provider>
  );
}
Provider.propTypes = {
  children: node,
  userId: string,
};

const mapStateToProps = state => ({
  userId: getUserId(state),
});

export const AlarmProvider = connect(mapStateToProps)(Provider);

export default useAlarm;
