import { stringify } from 'qs';
import Cookie from 'js-cookie';
import { ACCOUNTS_URL } from 'constants/Jaws';
import { get } from 'utils/fetch';

import { getCustomerData } from 'utils/user';

/**
 * If an account contains isSelected=true, return that one,
 * otherwise return default account.
 */
export function getSelectedAccount(customer) {
  if (!customer) {
    return null;
  }

  const accounts = customer.get('accounts');
  if (!accounts) {
    return null;
  }

  const selectedAccountId = Cookie.get('selectedAccount');
  const selectedAccount = accounts.find(account => !!account.get('isSelected'));

  // The order in which to look for the selected account is below

  return (
    // The currently selected value in redux
    // (Not currently used because we refresh on account change)
    selectedAccount ||
    // The Cookie value
    // Needs to override the others because we refresh on account selection
    accounts.find(account => account.get('id') === selectedAccountId) ||
    // A serverside override of what the selected acc should be
    accounts.find(account => !!account.get('defaultAccount')) ||
    // Lets try to get an account which is not authorizedThrough
    accounts.find(account => !account.get('authorizedThrough')) ||
    // If all else fails, give me the first account
    accounts.first()
  );
}

/**
 * Get account id for the currently selected account.
 *
 * When viewing portfolios and inventory, you want to view the account you
 * have selected, even if you are in a power of attourney situation.
 *
 * When trading, you want validation and redemptions to happen against
 * the account you have selected, even if you are in a power of attourney
 * situation.
 */
export function getAccountId(user) {
  if (!user) {
    throw new Error(`User.getCustomerNumber: user was ${user}`);
  }

  const customerData = getCustomerData(user);

  if (!customerData) {
    return null;
  }

  const selectedAccount = getSelectedAccount(customerData);

  return selectedAccount ? selectedAccount.get('id') : null;
}

/**
 * Get account id for the master account
 *
 * When viewing portfolios and inventory, you want to view the account you
 * have selected, even if you are in a power of attourney situation.
 */
export function getMasterAccountId(user) {
  if (!user) {
    throw new Error(`User.getMasterAccountId: user was ${user}`);
  }

  const customerData = getCustomerData(user);
  if (!customerData) {
    return null;
  }

  const accounts = customerData.get('accounts');
  if (!accounts) {
    return null;
  }

  const selectedAccount = accounts.reduce((cur, account) =>
    account.get('id') > cur.get('id') ? account : cur,
  );

  return selectedAccount ? selectedAccount.get('id') : null;
}

export function getAccountName(user) {
  if (!user) {
    throw new Error(`User.getCustomerNumber: user was ${user}`);
  }

  const customerData = getCustomerData(user);

  if (!customerData) {
    return null;
  }

  const selectedAccount = getSelectedAccount(customerData);

  return selectedAccount ? selectedAccount.get('accountName') : null;
}

export function getAccountById(customer, accountId) {
  if (!customer) return null;

  return customer
    .get('accounts')
    .find(account => account.get('id') === accountId);
}

export function getAccountByVpsAccount(customer, vpsAccount) {
  return customer
    .get('accounts')
    .find(account => account.get('vpsAccountNo') === vpsAccount);
}

export function getAvailableVolume(ownerId, accountId, data) {
  const query = stringify(data);
  return get(
    `${ACCOUNTS_URL}/${ownerId}/account/${accountId}/available-for-sale?${query}`,
  );
}

export function isPbDown(user) {
  const auth = user && user.get('authenticated');
  const customerData = auth && getCustomerData(user);
  const accountsRead = customerData && customerData.get('accountsRead');
  return auth && !accountsRead;
}

export function mapStatus(status) {
  return (
    {
      A: 'Aktiv',
      S: 'Blokkert for handel',
      K: 'Blokkert for kjøp',
      N: 'Blokkert for salg',
      U: 'Under registrering',
    }[status] || status
  );
}

/** onStatus('A').buyButtonIsVisible == false */
export function getStatusData(customer) {
  const defaultResponse = {
    buyButtonIsVisible: true,
    sellButtonIsVisible: true,
    description: null,
  };

  const selectedAccount = getSelectedAccount(customer);

  if (!selectedAccount) {
    return defaultResponse;
  }

  return (
    {
      // A is 'All is good'.
      // However, if the user does not have a VPS account, something is wrong,
      // and all is not good!
      A: !selectedAccount.get('vpsAccountNo')
        ? {
            buyButtonIsVisible: false,
            sellButtonIsVisible: false,
            description:
              'Noe er feil med aksjehandelskontoen din. Ring oss på ' +
              '55 26 02 22.',
          }
        : defaultResponse,
      S: {
        buyButtonIsVisible: false,
        sellButtonIsVisible: false,
        description:
          'Aksjehandelskontoen din er blokkert for handel. Ta ' +
          'eventuelt kontakt på 55 26 02 22.',
      },
      K: {
        buyButtonIsVisible: false,
        sellButtonIsVisible: true,
        description:
          'Aksjehandelskontoen din er blokkert for kjøp. Ta ' +
          'eventuelt kontakt på 55 26 02 22.',
      },
      N: {
        buyButtonIsVisible: true,
        sellButtonIsVisible: false,
        description:
          'Aksjehandelskontoen din er blokkert for salg. Ta ' +
          'eventuelt kontakt på 55 26 02 22.',
      },
      U: {
        buyButtonIsVisible: false,
        sellButtonIsVisible: false,
        description:
          'Vi er i ferd med opprette aksjehandelskontoen din. Prøv ' +
          'på nytt eller ring oss på 55 26 02 22.',
      },
    }[selectedAccount.get('status')] || defaultResponse
  );
}
