import styles from './TradeForm.module.scss';

type Props = {
  userMaySell: [
    {
      account: { id: string };
      values: { POSITION_VOLUME: number; ITEM: string; SECTOR: string };
    },
  ];
  tradeType: string;
  instrument: {
    ITEM: string;
    SECTOR: string;
  };
};

const OwnerStatus = ({ userMaySell, tradeType, instrument }: Props) => {
  if (!userMaySell.length && tradeType !== 'BUY') {
    return (
      <div className={styles.infoMessage}>
        <p>
          Du eier ikke{' '}
          <b>
            {instrument.ITEM}.{instrument.SECTOR}
          </b>
        </p>
      </div>
    );
  }
  if (userMaySell.length) {
    return (
      <div className={styles.infoMessage}>
        <table>
          <tr>
            <th>Ticker </th>
            <th>Antall </th>
            <th>Konto </th>
          </tr>
          {userMaySell.map(item => (
            <tr>
              <td>
                {item?.values?.ITEM}.{item?.values?.SECTOR}
              </td>
              <td>{item?.values?.POSITION_VOLUME}</td>
              <td>{item?.account?.id}</td>
            </tr>
          ))}
        </table>
      </div>
    );
  }
  return null;
};

export default OwnerStatus;
