export const TRADER = 'TRADER';
export const ORDERS = 'ORDERS';
export const HOLDINGS = 'HOLDINGS';
export const FAVORITES = 'FAVORITTER';
export const ALARMS = 'ALARMER';
export const FICTIVE_PORTFOLIO = 'FICTIVE_PORTFOLIO';

export const defaultConfig = [
  {
    label: 'Handle',
    to: false,
    type: TRADER,
    icon: ['repeat', ['fas', 'repeat']],
  },
  {
    label: 'Ordre',
    to: '?page=active-orders',
    type: ORDERS,
    icon: ['spinner', ['fas', 'spinner']],
  },
  {
    label: 'Beholdning',
    to: '?page=holdings',
    type: HOLDINGS,
    icon: ['clipboard-list', ['fas', 'clipboard-list']],
  },
  {
    label: 'Favoritter',
    to: '?page=favorites',
    type: FAVORITES,
    icon: ['star', ['fas', 'star']],
  },
  {
    label: 'Alarmer',
    to: '?page=alarms',
    type: ALARMS,
    icon: ['bell', ['fas', 'bell']],
  },
  {
    label: 'Fiktiv portefølje',
    to: '?page=fictive-portfolio',
    type: FICTIVE_PORTFOLIO,
    icon: ['file-signature', ['fas', 'file-signature']],
  },
];
