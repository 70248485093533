export const SELECTION_INTRADAY = 'SELECTION_INTRADAY';
export const SELECTION_WEEK = 'SELECTION_WEEK';
export const SELECTION_HISTORIC = 'SELECTION_HISTORIC';
export const SELECTION_ONE_MONTH = 'SELECTION_ONE_MONTH';
export const SELECTION_THREE_MONTHS = 'SELECTION_THREE_MONTHS';
export const SELECTION_SIX_MONTHS = 'SELECTION_SIX_MONTHS';
export const SELECTION_ONE_YEAR = 'SELECTION_ONE_YEAR';
export const SELECTION_THREE_YEARS = 'SELECTION_THREE_YEARS';
export const SELECTION_FIVE_YEARS = 'SELECTION_FIVE_YEARS';

export const PERIODS = {
  SELECTION_INTRADAY: '1days',
  SELECTION_WEEK: '5days',
  SELECTION_HISTORIC: '5years',
  SELECTION_ONE_MONTH: '1months',
  SELECTION_THREE_MONTHS: '3months',
  SELECTION_SIX_MONTHS: '6months',
  SELECTION_ONE_YEAR: '1years',
  SELECTION_THREE_YEARS: '3years',
  SELECTION_FIVE_YEARS: '5years',
};

export const SPACES = {
  SELECTION_INTRADAY: 'TICK',
  SELECTION_WEEK: 'TICK',
  SELECTION_HISTORIC: 'DAY',
  SELECTION_ONE_MONTH: 'DAY',
  SELECTION_THREE_MONTHS: 'DAY',
  SELECTION_SIX_MONTHS: 'DAY',
  SELECTION_ONE_YEAR: 'DAY',
  SELECTION_THREE_YEARS: 'DAY',
  SELECTION_FIVE_YEARS: 'DAY',
};

export const POINTS = {
  SELECTION_INTRADAY: 1000,
  SELECTION_WEEK: 200,
  SELECTION_HISTORIC: 1000,
  SELECTION_ONE_MONTH: 1000,
  SELECTION_THREE_MONTHS: 1000,
  SELECTION_SIX_MONTHS: 1000,
  SELECTION_ONE_YEAR: 1000,
  SELECTION_THREE_YEARS: 1000,
  SELECTION_FIVE_YEARS: 1000,
};

export const PRETTY_NAMES = {
  SELECTION_INTRADAY: 'Intradag',
  SELECTION_WEEK: '1 uke',
  SELECTION_HISTORIC: 'Historisk',
  SELECTION_ONE_MONTH: '1 måned',
  SELECTION_THREE_MONTHS: '3 måneder',
  SELECTION_SIX_MONTHS: '6 måneder',
  SELECTION_ONE_YEAR: '1 år',
  SELECTION_THREE_YEARS: '3 år',
  SELECTION_FIVE_YEARS: '5 år',
};

export const SUFFIX = {
  '5days': '_7DAYS',
  '1months': '_1MONTH',
  '3months': '_3MONTHS',
  '6months': '_6MONTHS',
  '1years': '_1YEAR',
  '3years': '_3YEARS',
  '5years': '_5YEARS',
};
