import PropTypes from 'prop-types';
import { formatNumber as number, decimalPlaces } from '@oms/utils';
import { Trend } from '@oms/components';
import { Link } from 'react-router-dom';
import Button from 'components/other/Button';

const Row = ({
  item,
  sector,
  volume,
  last,
  unrealizedProfit,
  portfolio,
  deleteInstrument,
  longName,
}) => (
  <tr key={item}>
    <td>
      <Link to={`/trader/instrument/${item}.${sector}`}>{longName}</Link>
    </td>
    <td>{volume}</td>
    <td>
      <Trend value={last}>{number(last, decimalPlaces(last))}</Trend>
    </td>
    <td>
      <Trend value={unrealizedProfit}>
        {number(unrealizedProfit, 2, 'plain')}
      </Trend>
    </td>
    <td>
      <Button
        aria-label={`Slett ${item}.${sector}`}
        icon="trash-alt"
        onClick={() => deleteInstrument(portfolio.id, item, sector)}
      />
    </td>
  </tr>
);

Row.propTypes = {
  item: PropTypes.string.isRequired,
  sector: PropTypes.string.isRequired,
  volume: PropTypes.number.isRequired,
  last: PropTypes.number.isRequired,
  unrealizedProfit: PropTypes.number.isRequired,
  portfolio: PropTypes.shape.isRequired,
  deleteInstrument: PropTypes.func.isRequired,
  longName: PropTypes.string.isRequired,
};

export default Row;
