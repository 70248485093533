import { Component } from 'react';
import Media from 'react-media';

function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

function withMedia(WrappedComponent) {
  class WithMedia extends Component {
    render() {
      return (
        <Media query="(min-width: 1626px)">
          {popSidebar => (
            <Media query="(max-width: 768px)">
              {tablet => (
                <Media query="(max-width: 548px)">
                  {toolbarBreakpoint => (
                    <Media query="(max-width: 420px)">
                      {small => (
                        <WrappedComponent
                          media={{
                            small,
                            toolbarBreakpoint,
                            tablet,
                            popSidebar,
                          }}
                          {...this.props}
                        />
                      )}
                    </Media>
                  )}
                </Media>
              )}
            </Media>
          )}
        </Media>
      );
    }
  }
  WithMedia.displayName = `WithMedia(${getDisplayName(WrappedComponent)})`;
  return WithMedia;
}

export default withMedia;
