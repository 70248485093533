import { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Suggest } from '@oms/components';
import { withRouter } from 'react-router-dom';
import styles from './Search.module.scss';
import Suggestion from './components/Suggestion';
import { SidebarConsumer } from '../../Sidebar';

class Search extends Component {
  static propTypes = {
    onClear: PropTypes.func,
    onSuggestionSelected: PropTypes.func,
    inputProps: PropTypes.shape({}),
    className: PropTypes.string,
    history: PropTypes.shape({
      push: PropTypes.func,
    }),
  };

  handleSelection = (event, { suggestion }, setInstrument, activeType) => {
    const { push } = this.props.history;
    const { ITEM, SECTOR } = suggestion;
    if (ITEM && SECTOR) {
      if (activeType === 'TRADER') {
        setInstrument({ ...suggestion, tradeType: 'BUY' });
      }
    }
    push(`/trader/instrument/${ITEM}.${SECTOR}`);
  };

  render() {
    const {
      inputProps,
      onSuggestionSelected,
      className,
      onClear = () => {},
    } = this.props;

    const handleSelection = onSuggestionSelected || this.handleSelection;

    return (
      <div className={classNames(styles.Search, className)}>
        <SidebarConsumer>
          {({ handleInstrument, activeType }) => (
            <Suggest
              group="SEARCH_EQUITIES"
              highlightFirstSuggestion
              renderSuggestion={Suggestion}
              onSuggestionSelected={(event, item) =>
                handleSelection(event, item, handleInstrument, activeType)
              }
              inputProps={inputProps}
              onClear={onClear}
            />
          )}
        </SidebarConsumer>
      </div>
    );
  }
}

export default withRouter(Search);
