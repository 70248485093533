// This is a duck experiment.. Quack! https://github.com/erikras/ducks-modular-redux
import Immutable from 'immutable';
import { getSelectedAccounts } from 'state/user/selectors';
import { getComponent } from 'utils/fetch';
import { portfolioColumns } from './utils';

const FETCH_HOLDINGS = 'norne/holdings/FETCH_HOLDINGS';
const FETCH_HOLDINGS_FAILED = 'norne/holdings/FETCH_HOLDINGS_FAILED';
const RECIEVE_HOLDINGS = 'norne/holdings/RECIEVE_HOLDINGS';

const initialState = Immutable.fromJS({
  portfolios: {},
});

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_HOLDINGS:
      return state.set('isFetching', true);
    case RECIEVE_HOLDINGS:
      return state
        .set('portfolios', Immutable.fromJS(action.payload))
        .set('isFetching', false);
    case FETCH_HOLDINGS_FAILED:
      return state.set('isFetching', false);
    default:
      return state;
  }
}

export const fetchHoldings = () => async (dispatch, getState) => {
  const state = getState();
  const accounts = getSelectedAccounts(state);
  if (!state?.user?.get('authenticated')) return null;

  dispatch({ type: FETCH_HOLDINGS });

  const promises = accounts.map(account =>
    getComponent({
      type: 'secure/portfolio',
      columns: portfolioColumns,
      leftJoins: 'feed.norne.contributed.omff.FUNDS as contributed on ITEM',
      backend: 'probroker',
      portfolioId: account.get('id'),
    })
      .then(data => data.json())
      .then(data => ({ positions: data.rows, account: account.toJS() }))
      .catch(error => {
        dispatch({ type: FETCH_HOLDINGS_FAILED });
        console.warn(`Failed to fetch portfolio: ${account.get('id')}`, error);
      }),
  );

  // Send in parallell, block with await afterwards
  const responses = await Promise.all(promises);
  const portfolios = responses.reduce((result, data) => {
    if (!data?.account) return result;
    return result.set(data.account.id, data);
  }, Immutable.Map());

  dispatch({ type: RECIEVE_HOLDINGS, payload: portfolios });
};
