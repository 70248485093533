export const ANALYSIS_CHART_REQUEST_DATA =
  'analysisChart/ANALYSIS_CHART_REQUEST_DATA';
export const ANALYSIS_CHART_SET_MAIN_INSTRUMENT =
  'analysisChart/ANALYSIS_CHART_SET_MAIN_INSTRUMENT';
export const ANALYSIS_CHART_CLEAR_MAIN_INSTRUMENT =
  'analysisChart/ANALYSIS_CHART_CLEAR_MAIN_INSTRUMENT';
export const ANALYSIS_CHART_RECEIVE_GRAPHDATA_MAIN =
  'analysisChart/ANALYSIS_CHART_RECEIVE_GRAPHDATA_MAIN';
export const ANALYSIS_CHART_RECEIVE_GRAPHDATA =
  'analysisChart/ANALYSIS_CHART_RECEIVE_GRAPHDATA';
export const ANALYSIS_CHART_ADD_COMPARE =
  'analysisChart/ANALYSIS_CHART_ADD_COMPARE';
export const ANALYSIS_CHART_REMOVE_COMPARE =
  'analysisChart/ANALYSIS_CHART_REMOVE_COMPARE';
export const ANALYSIS_CHART_INVALIDATE_ALL =
  'analysisChart/ANALYSIS_CHART_INVALIDATE_ALL';
export const ANALYSIS_CHART_SET_SELECTION =
  'analysisChart/ANALYSIS_CHART_SET_SELECTION';
export const ANALYSIS_CHART_TOGGLE_ADDITION =
  'analysisChart/ANALYSIS_CHART_TOGGLE_ADDITION';
export const ANALYSIS_CHART_REMOVE_ADDITION =
  'analysisChart/ANALYSIS_CHART_REMOVE_ADDITION';
export const ANALYSIS_CHART_RECEIVE_ADDITION =
  'analysisChart/ANALYSIS_CHART_RECEIVE_ADDITION';
export const ANALYSIS_CHART_ADD_TECHNICAL_INDICATOR =
  'analysisChart/ANALYSIS_CHART_ADD_TECHNICAL_INDICATOR';
export const ANALYSIS_CHART_REMOVE_TECHNICAL_INDICATOR =
  'analysisChart/ANALYSIS_CHART_REMOVE_TECHNICAL_INDICATOR';
export const ANALYSIS_CHART_REMOVE_ALL_TECHNICAL_INDICATORS =
  'analysisChart/ANALYSIS_CHART_REMOVE_ALL_TECHNICAL_INDICATORS';
