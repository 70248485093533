/* eslint-disable */
/**
 * Several modules require Intl, but ancient browsers do not support it.
 * This module conditionally polyfills it for the browsers that do not.
 */

export default function ensureIntlSetup() {
  return new Promise(resolve => {
    if (!global.Intl) {
      import('intl')
        .then(
          Promise.all([
            import('intl/locale-data/jsonp/en.js'),
            import('intl/locale-data/jsonp/nb.js'),
          ]),
        )
        .then(resolve);
    } else {
      resolve();
    }
  });
}
