import { Field } from 'react-final-form';
import {
  normalizeNumber,
  formatNumber as number,
  decimalPlaces,
} from '@oms/utils';
import PropTypes from 'prop-types';
import { safeguardCheck } from 'utils/trade';
import { browserIsEdge, browserIsSafari } from 'utils/browser';
import { useBlockWheel } from 'utils/useBlockWheel';
import HelpText from 'components/other/HelpText';
import styles from '../../../TradeForm.module.scss';

export const Limit = ({ last, sell }) => {
  const onWheel = useBlockWheel();
  return (
    <Field name="limit">
      {({ input, meta }) => {
        const passSafeguardCheck = safeguardCheck(
          normalizeNumber(input.value),
          last,
        );
        return (
          <div>
            <HelpText
              className={styles.helpText}
              title={<h3>Pris per aksje</h3>}
            >
              Den {sell ? 'laveste' : 'høyeste'} prisen du er villig til å{' '}
              {sell ? 'selge' : 'kjøpe'} en aksje for
            </HelpText>
            <input
              {...input}
              placeholder={`Siste : ${number(last, decimalPlaces(last))}`}
              name="limit"
              type={browserIsEdge() || browserIsSafari() ? 'text' : 'number'}
              autoComplete="off"
              autoFocus
              onWheel={onWheel}
            />
            {meta.error && meta.touched && (
              <span className={styles.formError}>{meta.error}</span>
            )}
            {!passSafeguardCheck && !meta.pristine && last !== 0 && (
              <span className={styles.formError}>
                Limit avviker mer enn 30% fra sist omsatte kurs
              </span>
            )}
          </div>
        );
      }}
    </Field>
  );
};

Limit.propTypes = {
  last: PropTypes.number,
  sell: PropTypes.bool,
};

export default Limit;
