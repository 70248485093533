import { useMemo } from 'react';
import { useJaws } from '@oms/jaws-react';
import { Abbr } from '@oms/components';
import Row from './Row';

import styles from './Favorites.module.scss';

const buildSpec = (watchlist: string[]) => ({
  columns: 'ITEM_SECTOR,ITEM,LONG_NAME,LAST,CHANGE_PCT,TIME',
  itemSector: watchlist.join(),
});

type Props = {
  watchlist: string[];
  handleWatchlist: (itemSector: string) => void;
};

const FavoritesTable = ({ watchlist, handleWatchlist }: Props) => {
  const spec = useMemo(() => buildSpec(watchlist), [watchlist]);

  const { items, jawsFetching, resource } = useJaws(spec);

  if (jawsFetching && resource) {
    throw resource.promise;
  }

  if (items.isEmpty()) return null;

  return (
    <table className="table-base">
      <caption>
        <div className={styles.Heading}>
          <h2>Mine Favoritter</h2>
        </div>
      </caption>
      <thead>
        <tr>
          <th>Navn</th>
          <th className="number">Siste</th>
          <th className="number">
            <Abbr title="Endring i prosent">+/-%</Abbr>
          </th>
          <td />
        </tr>
      </thead>
      <tbody>
        {items
          ?.sortBy(item => item.get('ITEM_SECTOR'))
          .map(item => (
            <Row
              item={item}
              key={item.get('ITEM_SECTOR')}
              handleWatchlist={handleWatchlist}
            />
          ))
          .toArray()}
      </tbody>
    </table>
  );
};

export default FavoritesTable;
