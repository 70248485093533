import { Component } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const iconTypes = [
  'lifeRing',
  'island-tropical',
  'userSecret',
  'envelope',
  'badge-check',
  'user-ninja',
  'user-check',
  'user-circle',
  'user-alt',
  'user-plus',
  ['fas', 'user'],
  'user-friends',
  'users',
  'chart-bar',
  'chart-area',
  'chart-line',
  'chart-pie',
  'analytics',
  'angle-up',
  'angle-down',
  'chevron-up',
  'chevron-down',
  'chevron-left',
  'chevron-right',
  'arrow-left',
  'arrow-right',
  'caret-down',
  'caret-up',
  'bars',
  'briefcase',
  'clipboard-list',
  'repeat',
  'history',
  'search',
  ['fas', 'globe'],
  'redo',
  'sync',
  'sync-alt',
  'filter',
  'sort',
  'sort-up',
  'sort-down',
  'sort-amount-up',
  'sort-amount-down',
  'wrench',
  'sign-in',
  'sign-out',
  'percentage',
  'percent',
  'bell',
  ['fas', 'bell'],
  'star',
  ['fas', 'star'],
  'heart',
  'shopping-cart',
  'spinner',
  'clock',
  'broadcast-tower',
  'file',
  'file-pdf',
  'file-alt',
  'file-invoice',
  'file-contract',
  'file-signature',
  ['fas', 'file-signature'],
  'file-download',
  'file-excel',
  'plus',
  'minus',
  'trash-alt',
  'calendar',
  'calendar-alt',
  'info-circle',
  'question-circle',
  'exclamation-triangle',
  ['fas', 'circle'],
  ['fas', 'bolt'],
  'times',
  'edit',
  'coins',
  'hand-holding-usd',
  'hand-holding-box',
  ['fas', 'times-circle'],
  ['fas', 'inbox'],
  ['fas', 'leaf'],
];

const iconSizeTypes = [
  'xs',
  'sm',
  'lg',
  '2x',
  '3x',
  '4x',
  '5x',
  '6x',
  '7x',
  '8x',
  '9x',
  '10x',
];

class Icon extends Component {
  static propTypes = {
    className: PropTypes.string,
    ariaLabel: PropTypes.string,
    icon: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
    size: PropTypes.oneOf(iconSizeTypes),
    fixedWidth: PropTypes.bool,
    inverse: PropTypes.bool,
    rotation: PropTypes.number,
    flip: PropTypes.oneOf(['horizontal', 'vertical', 'both']),
    spin: PropTypes.bool,
    pulse: PropTypes.bool,
    border: PropTypes.bool,
    mask: PropTypes.bool,
    color: PropTypes.string,
  };
  displayName = this.props.icon;

  render() {
    const { icon, ariaLabel, className, mask, ...props } = this.props;
    let iconSelected = icon;
    let iconMask;
    if (!icon) return null;
    if (typeof icon === 'string') iconSelected = ['fal', icon];
    if (mask) iconMask = ['fas', 'circle'];
    const iconProps = mask ? { mask: iconMask, ...props } : props;
    return (
      <FontAwesomeIcon
        className={className}
        aria-label={ariaLabel}
        icon={iconSelected}
        {...iconProps}
      />
    );
  }
}

export default Icon;
