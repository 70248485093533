const TableHead = () => (
  <thead>
    <tr>
      <th>Ticker</th>
      <th>Antall</th>
      <th>Siste</th>
      <th>+/-%</th>
      <th>Ureal. gev/tap</th>
    </tr>
  </thead>
);

export default TableHead;
