import * as React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import Icon from '../Icons';
import styles from './Button.module.scss';

type Props = {
  /** Gives the buttom primary button styles */
  primary?: boolean;
  link?: boolean;
  success?: boolean;
  aml?: boolean;
  danger?: boolean;
  warning?: boolean;
  plain?: boolean;
  clean?: boolean;
  linkTo?: string | {};
  secondary?: boolean;
  className?: string;
  size?: 'small' | 'medium' | 'large' | 'dense';
  stretch?: boolean;
  center?: boolean;
  icon?: string | string[];
  reverse?: boolean;
  pending?: boolean;
  children?: React.ReactNode;
  iconProps?: {
    className?: string;
    ariaLabel?: string;
    icon?: string | Array<string>;
    fixedWidth?: boolean;
    inverse?: boolean;
    rotation?: number;
    flip?: 'horizontal' | 'vertical' | 'both';
    spin?: boolean;
    pulse?: boolean;
    border?: boolean;
    mask?: boolean;
    color?: string;
  };
  noTooltip?: boolean;
  tooltipPosition?: 'left' | 'right' | 'bottom' | 'top';
  iconButton?: boolean;
  type?: 'button' | 'submit' | 'reset';
  disabled?: boolean;
  onClick?: (
    e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>,
  ) => void;
  autoFocus?: boolean;
  href?: string;
};

/**
 * A component which exposes a class that the banks can use to style on.
 * Use this for anything that LOOKS like a button, even links.
 *
 * Rendering a button that looks like a link is possible by passing `link` prop.
 *
 * Passing a `linkTo`-prop will render a Link, which can then be used to
 * render a link that looks like a button.
 *
 * If you want the above, but also to use browser navigation instead of react-router
 * you can pass in a `href`-prop.
 *
 * Rendering links that look like links should instead use a simple Link
 * component.
 *
 * See doc/branding.md for more information.
 */
export const Button = ({
  children,
  primary,
  secondary,
  success,
  danger,
  warning,
  aml,
  plain,
  clean,
  link,
  className,
  linkTo,
  size = 'medium',
  stretch,
  center,
  icon,
  pending,
  reverse,
  iconProps,
  noTooltip,
  tooltipPosition = 'left',
  iconButton,
  type = 'button',
  href,
  ...rest
}: Props) => {
  let variant = 'primary';
  let buttonSize: any = size;
  let buttonIcon;
  let first;
  let second;
  let showTooltip = !noTooltip;
  if (secondary) variant = 'secondary';
  if (success) variant = 'success';
  if (danger) variant = 'danger';
  if (warning) variant = 'warning';
  if (aml) variant = 'aml';
  if (plain) variant = 'plain';
  if (clean) variant = 'clean';
  if (link) {
    variant = 'link';
    buttonSize = null;
  }
  if (icon || pending) {
    buttonIcon = (
      <Icon
        className={styles.icon}
        icon={pending ? ['fas', 'spinner'] : icon}
        {...iconProps}
      />
    );
    first = !reverse ? buttonIcon : '';
    second = reverse ? buttonIcon : '';
  }
  let isIconButton = React.Children.count(children) === 0;
  if (isIconButton) {
    variant = 'plain';
  }
  if (!isIconButton) {
    showTooltip = false;
  }

  if (iconButton) {
    variant = 'plain';
    showTooltip = !noTooltip;
    isIconButton = true;
  }

  // If you find this to have too much duplication
  // feel free to write it smartlier :p
  if (href) {
    return (
      <a
        href={href}
        {...rest}
        className={classNames(className, {
          [variant]: true,
          [styles[variant]]: true,
          [styles[buttonSize]]: !!buttonSize,
          [styles.IconButton]: isIconButton,
          [styles.IconButtonTooltip]: showTooltip,
          [styles[tooltipPosition]]: tooltipPosition,
          [styles.stretch]: stretch,
          [styles.pending]: pending,
        })}
      >
        {first}
        {children && <span>{children}</span>}
        {second}
      </a>
    );
  }

  if (linkTo) {
    return (
      <Link
        to={linkTo}
        {...rest}
        className={classNames(className, {
          [variant]: true,
          [styles[variant]]: true,
          [styles[buttonSize]]: !!buttonSize,
          [styles.IconButton]: isIconButton,
          [styles.IconButtonTooltip]: showTooltip,
          [styles[tooltipPosition]]: tooltipPosition,
          [styles.stretch]: stretch,
          [styles.center]: center,
          [styles.pending]: pending,
        })}
      >
        {first}
        {children && <span>{children}</span>}
        {second}
      </Link>
    );
  }

  return (
    <button
      type={type}
      {...rest}
      className={classNames(className, {
        [variant]: true,
        [styles[variant]]: true,
        [styles[buttonSize]]: !!buttonSize,
        [styles.IconButton]: isIconButton,
        [styles.IconButtonTooltip]: showTooltip,
        [styles[tooltipPosition]]: tooltipPosition,
        [styles.stretch]: stretch,
        [styles.center]: center,
        [styles.pending]: pending,
      })}
    >
      {first}
      {children && <span>{children}</span>}
      {second}
    </button>
  );
};

export default Button;
