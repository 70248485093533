import PropTypes from 'prop-types';
import styles from './TradeForm.module.scss';

const ActiveOrder = ({ instrument }) => (
  <div className={styles.infoMessage}>
    <p>
      Du endrer nå en aktiv {instrument.tradeType === 'BUY' ? 'kjøps' : 'salgs'}
      ordre
      <b> ({instrument.orderId}) </b>, og har begrenset med felt du kan endre.
    </p>
  </div>
);

ActiveOrder.propTypes = {
  instrument: PropTypes.shape({
    tradeType: PropTypes.string,
    orderId: PropTypes.string,
  }),
};

export default ActiveOrder;
