import moment from 'moment';
import {
  ORDERS_URL,
  AGREEMENT_URL,
  EXCHANGE_URL,
  ACCOUNTS_URL,
  WITHDRAWALAGREEMENTS,
} from 'constants/Jaws';
import { getDepotBalance } from 'utils/ask';

import { post, put, get, del } from 'utils/fetch';

export const validateFundOrder = async (userId, data) =>
  post(
    `${ORDERS_URL}/${userId}/fund/orders/validate`,
    JSON.stringify({ data }),
  );

export const executeFundOrder = async (anonymizedUserId, data) =>
  post(
    `${ORDERS_URL}/${anonymizedUserId}/fund/orders/execute`,
    JSON.stringify({ data }),
  );

export const validateStockOrder = async (anonymizedUserId, data) =>
  post(
    `${ORDERS_URL}/${anonymizedUserId}/orders/validate`,
    JSON.stringify({ data }),
  );

export const executeStockOrder = async (anonymizedUserId, data) =>
  post(
    `${ORDERS_URL}/${anonymizedUserId}/orders/execute`,
    JSON.stringify({ data }),
  );

export const updateStockOrder = async (anonymizedUserId, orderId, data) =>
  put(
    `${ORDERS_URL}/${anonymizedUserId}/orders/${orderId}/execute`,
    JSON.stringify({ data }),
  );

export const getOrder = async (orderId, account, anonymizedOwnerId) =>
  get(
    `${ORDERS_URL}/${anonymizedOwnerId}/orders/${orderId}?accountId=${account}`,
  );

export const getDetails = async (userId, accountId, orderId) =>
  get(`${ORDERS_URL}/${userId}/fund/orders/${orderId}?accountId=${accountId}`);

export const deleteOrder = async (userId, accountId, orderId) =>
  del(`${ORDERS_URL}/${userId}/fund/orders/${orderId}?accountId=${accountId}`);

export const deleteFundSwitchOrder = async (userId, accountId, orderId) =>
  del(
    `${ORDERS_URL}/${userId}/fund/orders/switch/${orderId}?accountNumber=${accountId}`,
  );

export const getOrderDetails = async (ownerId, orderId, accountId) =>
  get(
    `${ORDERS_URL}/${ownerId}/fund/orders/getOrderDetails?orderId=${orderId}&accountId=${accountId}`,
  );

export const deleteAgreement = async (userId, accountId, agreementId) =>
  del({
    url: `${AGREEMENT_URL}/${userId}/deleteAgreement{?query*}`,
    query: { accountId, agreementId },
  });

export const deleteWithdrawalAgreement = async (id, accountId) =>
  del(`${WITHDRAWALAGREEMENTS}/${accountId}/withdrawal-agreements/${id}`);

// Se http://manawiki.osl.manamind.com/index.php/Meglersystemer
export const mapStatus = statusInt =>
  ({
    0: 'Ikke på børs', // Mottatt, ikke på børs
    1: 'Avvist',
    2: 'Akseptert',
    3: 'Venter',
    4: 'Utført',
    5: 'Kansellert',
    6: 'Utgått',
    7: 'Trukket',
    8: 'Stop loss',
    9: 'Manuell',
    10: 'Venter',
  }[statusInt] || '-');

export const mapType = typeInt =>
  ({
    0: 'Kjøp',
    1: 'Selg',
  }[typeInt] || '-');

/**
 * Fat finger check.
 * Guard is percent, meaning a deviance of over 30 percent against
 * a master value (last) will fail the fat finger check.
 */
export const safeguardCheck = (limit, last, guard = 30) => {
  const deviation = Math.abs(100 * (100 * ((last - limit) / last)));
  return deviation <= guard * 100;
};

const FEE_MIN = 95;
const FEE_ASK_MIN = 49;
const FEE_MAX = Infinity;

export const calculateCommission = (limit, volume, ask) => {
  const total = limit * volume;
  return Math.min(
    FEE_MAX,
    Math.max(ask ? FEE_ASK_MIN : FEE_MIN, total * 0.0005),
  );
};

export const getAmountOfValidDaysForOrder = (tradeType, paymentAgent) => {
  if (tradeType === 'SELL') return 90;
  if (paymentAgent === 'EVRY') return 21;
  return 365;
};

export const needTradeTest = (
  testResult,
  testTimestamp,
  instrumentType,
  sector,
) => {
  const testTimestampDate = new Date(testTimestamp);
  const testCompleted = testResult ? testResult !== 'NOT_COMPLETED' : false;
  const oldTestResults = testTimestamp
    ? testTimestampDate.setFullYear(testTimestampDate.getFullYear() + 1) <
      new Date()
    : true;

  // Instrumenttype PCC: Egenkapitalbevis
  // Instrumenttype RIGHTS: Tegningsretter
  // Sector NFF: Unoterte aksjer
  const subscriptionsRights =
    instrumentType === 'RIGHTS' || instrumentType === 'SUBSCRIPTION_RIGHTS';
  const ETN =
    instrumentType === 'EXCHANGE_TRADED_NOTES' || instrumentType === 'ETNS';

  const testRequiredForTrade = ETN || subscriptionsRights || sector === 'NFF';

  return (!testCompleted || oldTestResults) && testRequiredForTrade;
};

export const getRules = async instrument => {
  const { SECTOR, ITEM } = instrument;
  const url = `${EXCHANGE_URL}/${SECTOR}`;
  try {
    const limit = get({ url: `${url}/ticker/${ITEM}/limit-rules` });
    const close = get({
      url: `${url}/closed-dates{?query*}`,
      query: {
        start: moment().format('YYYY-MM-DD'),
        stop: moment()
          .add(30, 'days')
          .format('YYYY-MM-DD'),
      },
    });

    const [limitRules, closeRules] = await Promise.all([limit, close]);

    const rules = {
      limitRules: await limitRules.json(),
      closeRules: await closeRules.json(),
    };

    return rules;
  } catch (error) {
    return false;
  }
};

export const getBalance = async (account, askAccount, anonymizedOwnerId) => {
  const accountSelected = account;

  if (askAccount) {
    const balance = await getDepotBalance(
      anonymizedOwnerId,
      accountSelected.value,
    );
    return { ...account, balance };
  }
  const result = await get(
    `${ACCOUNTS_URL}/${anonymizedOwnerId}/balance/${accountSelected.value ||
      accountSelected}`,
  );

  if (!result.ok) {
    throw result.statusText();
  }

  const balance = await result.json();
  return {
    ...account,
    balance: balance?.data?.BALANCE,
  };
};
