import Immutable from 'immutable';
import * as ActionTypes from './types';

const initialState = {
  isFetching: false,
  data: Immutable.fromJS([]),
};

export default function sniplets(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.REQUEST_SNIPLETS:
      return Object.assign({}, state, {
        isFetching: true,
      });

    case ActionTypes.RECEIVE_SNIPLETS:
      return Object.assign({}, state, {
        data: Immutable.fromJS(action.data),
        isFetching: false,
      });
    /**
     * Updates a sniplet in the set based on id.
     * NOTE: If a sniplet with the same ID is NOT found,
     * it will be appended to the end of the state.data
     * list.
     */
    case ActionTypes.UPDATE_SNIPLET:
      return Object.assign({}, state, {
        // Find and update sniplet
        data: state.data.set(
          state.data.findIndex(e => e.id === action.data.id),
          Immutable.fromJS(action.data),
        ),
        isFetching: false,
      });

    default:
      return state;
  }
}
