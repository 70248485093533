import {
  TRANSFER_POSITIONS_URL,
  ACCOUNTS_URL,
  COMPONENTS_URL,
} from 'constants/Jaws';
import { get, post } from 'utils/fetch';

export function postPositions(owner, from, ask, data) {
  const payload = JSON.stringify(data);
  return post(
    `${TRANSFER_POSITIONS_URL}/${owner}/${from}/${ask}/transfer`,
    payload,
  );
}

export function createASKAccount(ownerId, accountId) {
  return post(`${ACCOUNTS_URL}/${ownerId}/createASK/${accountId}`);
}

export async function getAskTradableFunds() {
  const result = await get(
    `${COMPONENTS_URL}?source=feed.norne.quotes.ASK&columns=ITEM_SECTOR`,
  );
  if (!result.ok) throw await result.text();
  const { rows } = await result.json();

  return rows.map(item => item.values.ITEM_SECTOR);
}

/** Filters out any rows without ISIN */
export async function getAskTradeableFundsISIN() {
  const result = await get(
    `${COMPONENTS_URL}?source=feed.norne.omff.ASK&columns=ISIN,ITEM`,
  );
  if (!result.ok) throw await result.text();
  const { rows } = await result.json();

  return rows
    .filter(item => item.values.ISIN)
    .map(item => ({
      ITEM: item.values.ITEM,
      ISIN: item.values.ISIN,
    }));
}

export async function getDepotBalance(userId, accountId) {
  const result = await get(`${ACCOUNTS_URL}/${userId}/balance/${accountId}`);
  if (!result.ok) throw await result.text();
  const { data } = await result.json();
  return data.BALANCE;
}
