import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getUserId } from 'state/user/selectors';
import { ORDERS_URL } from 'constants/Jaws';

import useDebounce from 'utils/useDebounce';
import { post } from 'utils/fetch';

export type Order = {
  tradeType: 'BUY' | 'SELL';
  orderType: 'normal' | 'fillAndKill' | 'fillOrKill';
  accountId?: string;
  bankAccount?: string;
  /** Formatted as DD-MM-YYYY */
  expirationDate?: string;
  limit?: number;
  amount?: number;
  totalVolume?: number;
  filledVolume?: number;
  openVolume?: number;
  ticker?: string;
  exchange?: string;
  useOpenVolume?: boolean;
  useTriggerCriterion?: boolean;
  triggerCriterion?: 'gt' | 'gte' | 'lt' | 'lte';
  stopLossLimit?: number;
};

type CommissionResult = {
  STOCK_UNIT_PRICE: number;
  COMMISSION: number;
  CUSTOMER_ID: string;
  BACKEND_ID: string;
};

const fetchCommission = async (
  ownerId: string,
  accountId: string,
  order: Order,
) =>
  post(
    `${ORDERS_URL}/${ownerId}/orders/calculateCommission?accountId=${accountId}`,
    JSON.stringify(order),
  );

export const calculateCommission = async (
  ownerId: string,
  accountId: string,
  order: Order,
): Promise<CommissionResult> => {
  const result = await fetchCommission(ownerId, accountId, order);

  if (!result.ok) {
    throw result;
  }

  const json: CommissionResult = await result.json();

  return json;
};

export const useCommission = (values: Order) => {
  const userId = useSelector(getUserId);
  const [commission, setCommission] = useState<number>();
  const debouncedValues = useDebounce(values, 1000);

  useEffect(() => {
    const accountId = debouncedValues.accountId;

    if (!accountId) return;

    calculateCommission(userId, accountId, debouncedValues).then(
      (commissionData: CommissionResult) => {
        setCommission(commissionData.COMMISSION);
      },
    );
  }, [userId, debouncedValues]);

  return commission;
};
