import { useEffect } from 'react';

const useKeyPress = (targetKey, callBack) => {
  const upHandler = e => {
    if (e.keyCode === targetKey) {
      callBack();
    }
  };

  useEffect(() => {
    window.addEventListener('keyup', upHandler);
    return () => {
      window.removeEventListener('keyup', upHandler);
    };
  });
};

export default useKeyPress;
