import { useSidebar } from 'components/Sidebar';
import Button from 'components/other/Button';
import Icon from 'components/other/Icons';
import styles from './AlarmToHandleHeader.module.scss';

const AlarmToHandleHeader = () => {
  const { alarmToHandle, handleAlarm } = useSidebar();
  return (
    <div className={styles.AlarmToHandleHeader}>
      <b>{alarmToHandle.item}</b>
      <Button
        clean
        aria-label={`fjern ${alarmToHandle.item}`}
        onClick={() => handleAlarm()}
      >
        <Icon icon="times" aria-hidden="true" />
      </Button>
    </div>
  );
};

export default AlarmToHandleHeader;
