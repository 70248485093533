import Immutable from 'immutable';
import { Link } from 'react-router-dom';
import { formatNumber as number, decimalPlaces } from '@oms/utils';
import { Trend } from '@oms/components';

import Button from 'components/other/Button';

type Props = {
  item: Immutable.Map<any, any>;
  handleWatchlist: (itemSector: string) => any;
};

export const Row = ({ item, handleWatchlist }: Props) => {
  const { ITEM_SECTOR, LONG_NAME, LAST, CHANGE_PCT } = item.toJS();

  return (
    <tr>
      <td>
        <Link to={`/trader/instrument/${ITEM_SECTOR}`}>{LONG_NAME}</Link>
      </td>
      <td className="number">{number(LAST, decimalPlaces(LAST))}</td>
      <td className="number">
        <Trend value={CHANGE_PCT}>{number(CHANGE_PCT)}</Trend>
      </td>
      <td>
        <Button
          aria-label={`Fjern ${LONG_NAME} fra din favorittliste`}
          icon={['fas', 'star']}
          iconProps={{ color: 'orange' }}
          onClick={() => handleWatchlist(ITEM_SECTOR)}
          tooltipPosition="left"
        />
      </td>
    </tr>
  );
};

export default Row;
