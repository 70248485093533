import PropTypes from 'prop-types';
import Select from 'react-select';
import { Field } from 'react-final-form';

import styles from '../Trade.module.scss';

export const PortfolioPicker = ({ options, disableField, required }) => {
  return (
    <Field name="accountSelected" validate={required}>
      {({ input, meta }) => (
        <div>
          <label htmlFor="accountSelected">Velg fiktiv portefølje</label>
          <Select
            {...input}
            isSearchable={false}
            isClearable={false}
            isDisabled={disableField}
            placeholder="Velg portefølje"
            options={options}
            value={options?.find(opt => opt.value === input.value?.value)}
          />
          {meta.error && meta.touched && (
            <span className={styles.formError}>{meta.error}</span>
          )}
        </div>
      )}
    </Field>
  );
};

PortfolioPicker.propTypes = {
  options: PropTypes.array,
  disableField: PropTypes.bool,
  required: PropTypes.func.isRequired,
};

export default PortfolioPicker;
