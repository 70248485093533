import * as ActionTypes from './types';

const initialState = {
  data: {},
  isFetching: false,
};

export default function auditData(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.REQUEST_TESTS:
      return Object.assign({}, state, {
        isFetching: true,
      });

    case ActionTypes.RECEIVE_TESTS:
      return Object.assign({}, state, {
        isFetching: false,
        data: action.data,
      });

    case ActionTypes.UPDATE_TEST:
      return Object.assign({}, state, {
        // Find and update assessmentTest
        isFetching: false,
        data: action.data,
      });

    case ActionTypes.CLEAR_TEST:
      return initialState;

    default:
      return state;
  }
}
