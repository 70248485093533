import { Field } from 'react-final-form';
import { useId } from '@reach/auto-id';
import { DecimalInput } from '@oms/components-fields';
import classNames from 'classnames';

import HelpText from 'components/other/HelpTextAlt';

import Icon from '../../Icons';
import styles from './Field.module.scss';
import React, { WheelEventHandler } from 'react';
import { NumberInput } from 'utils/useBlockWheel';

const Input = (props: React.HTMLProps<HTMLInputElement>) => (
  <input {...props} />
);
const Textarea = (props: React.HTMLProps<HTMLTextAreaElement>) => (
  <textarea {...props} />
);

const inputTypes = [
  'text',
  'date',
  'datetime-local',
  'email',
  'month',
  'number',
  'decimal',
  'range',
  'search',
  'textarea',
  'tel',
] as const;

type Props = {
  name: string;
  label: string;
  id?: string;
  type?: typeof inputTypes[number];
  placeholder?: string | number;
  icon?: string | string[];
  disabled?: boolean;
  autoComplete?: string;
  autoFocus?: boolean;
  cols?: number;
  rows?: number;
  helpText?: string;
  message?: string;
  messageLocation?: 'top' | 'bottom';
  onWheel?: WheelEventHandler;
  className?: string;
};

/* eslint-disable no-unused-vars */
const FormField = ({
  helpText,
  name,
  type,
  label,
  id,
  placeholder,
  icon,
  disabled,
  autoComplete,
  autoFocus,
  cols,
  rows,
  message,
  messageLocation = 'top',
  onWheel = undefined,
  className,
  ...fieldProps
}: Props) => {
  let InputComponent: typeof DecimalInput | typeof Textarea | typeof Input;
  if (type === 'decimal') {
    InputComponent = DecimalInput;
  } else if (type === 'textarea') {
    InputComponent = Textarea;
  } else if (type === 'number') {
    InputComponent = NumberInput;
  } else {
    InputComponent = Input;
  }

  const generatedId = useId(id);

  const userInputProps = {
    type: type !== 'decimal' && type !== 'textarea' ? type : undefined,
    name,
    autoComplete: autoComplete?.toString() || 'false',
    autoFocus,
    disabled,
    cols,
    rows,
    placeholder,
    id: generatedId,
    onWheel,
  };

  return (
    <Field name={name} {...fieldProps}>
      {({ input: inputProps, meta: { error, touched } }) => (
        <div className={classNames(styles.FieldWrapper, className)}>
          <label className={styles.formFieldLabel} htmlFor={generatedId}>
            {label}
          </label>
          {helpText && <HelpText title="Mer om…">{helpText}</HelpText>}
          <div className={styles.InputField}>
            {!!message && messageLocation === 'top' && <span>{message}</span>}
            {icon ? (
              <>
                <Icon icon={icon} />
                <InputComponent {...userInputProps} {...inputProps} />
              </>
            ) : (
              <InputComponent {...userInputProps} {...inputProps} />
            )}
            {!!message && messageLocation === 'bottom' && (
              <span>{message}</span>
            )}
          </div>
          {error && touched && (
            <div className={styles.error}>
              <Icon icon="exclamation-triangle" />
              <span>{error}</span>
            </div>
          )}
        </div>
      )}
    </Field>
  );
};

export default FormField;
