import { createContext, Component, useContext } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import { getAskTradableFunds } from 'utils/ask';
import { getRules } from 'utils/trade';
import { isOldBrowser } from 'utils/browser';
import withMedia from '../../MediaHOC';
import { HOLDINGS, ALARMS, defaultConfig } from './types';

const SidebarContext = createContext();

class SidebarProvider extends Component {
  static propTypes = {
    children: PropTypes.node,
    history: PropTypes.shape({
      push: PropTypes.func,
    }),
    match: PropTypes.shape({
      path: PropTypes.string.isRequired,
      url: PropTypes.string,
    }).isRequired,
    media: PropTypes.shape({
      small: PropTypes.bool,
      toolbarBreakpoint: PropTypes.bool,
      tablet: PropTypes.bool,
      popSidebar: PropTypes.bool,
    }),
  };

  state = {
    activeType: HOLDINGS,
    sidebarOpen: true,
    instrument: false,
    askTradableInstruments: [],
    showMyAlarms: false,
  };

  componentDidMount() {
    const { match } = this.props;
    if (!this.state.askTradableInstruments.length) {
      this.getAskTradable();
    }
    this.toggleSidebar(
      match.url.startsWith('/trader') &&
        this.props.media.popSidebar &&
        !isOldBrowser(),
    );
  }

  componentDidUpdate(prevProps) {
    const prevRouteWasTrader = prevProps.match?.path.startsWith('/trader');
    const thisRouteIsTrader = this.props.match?.path.startsWith('/trader');
    const wasLargeScreen = prevProps.media.popSidebar;
    const isOnLargeScreen = this.props.media.popSidebar;

    // When resizing the viewport to a small device, ensure the sidebar is hidden
    if (!isOnLargeScreen && wasLargeScreen) {
      this.toggleSidebar(false);
    }

    // When navigating to trader, open on large screens
    if (!prevRouteWasTrader && thisRouteIsTrader && isOnLargeScreen) {
      this.toggleSidebar(true);
    }

    // When navigating out from trader, hide sidebar
    if (prevRouteWasTrader && !thisRouteIsTrader) {
      this.toggleSidebar(false);
    }
  }

  getAskTradable = async () => {
    this.setState({ askTradableInstruments: await getAskTradableFunds() });
  };

  getRules = async instrument => {
    const rules = await getRules(instrument);
    return rules;
  };

  setShowMyAlarms = (bool, callback = () => {}) => {
    this.setState({ showMyAlarms: bool }, callback());
  };

  toggleSidebar = sidebarOpen =>
    sidebarOpen
      ? this.setState({ sidebarOpen, activeType: HOLDINGS })
      : this.setState({ sidebarOpen, activeType: '' });

  clearInstrument = () => {
    this.setState({ instrument: false });
  };

  handleEditOrder = async order => {
    const {
      TICKER_CODE,
      TOTAL_VOLUME,
      LIMIT,
      INSTRUMENT_NAME,
      EXPIRATION_DATE,
      ESTIMATED_PRICE,
      STOCK_EXCHANGE_ID,
      TYPE,
      ORDER_ID,
      item,
      HIDDEN_VOLUME,
      account,
    } = order;

    const label = account?.accountName
      ? `${account.accountName} (${account.id})`
      : account.id;

    const instrument = {
      accountSelected: {
        label,
        value: account?.id,
        isMarginAccount: account?.isMarginAccount,
        paymentAgent: account?.paymentAgent,
      },
      INSTRUMENT_TYPE: item.INSTRUMENT_TYPE,
      ITEM: TICKER_CODE,
      limit: LIMIT,
      hiddenVolume: HIDDEN_VOLUME,
      volume: TOTAL_VOLUME,
      LONG_NAME: INSTRUMENT_NAME,
      SECTOR: STOCK_EXCHANGE_ID,
      EXPIRATION_DATE,

      tradeType: (TYPE === 0 && 'BUY') || (TYPE === 1 && 'SELL'),
      orderId: ORDER_ID,
      oldTotal: ESTIMATED_PRICE,
    };

    const tradeRules = await this.getRules(instrument);

    if (this.props.media.small || this.props.media.tablet) {
      this.props.history.push(
        `/trader/instrument/${TICKER_CODE}.${STOCK_EXCHANGE_ID}?&trader=active`,
      );
    }

    return this.setState({
      instrument: { ...instrument, tradeRules },
      activeType: 'TRADER',
    });
  };

  handleSelectType = type => {
    const { activeType, sidebarOpen } = this.state;

    if (type === activeType && sidebarOpen) {
      this.setState({ sidebarOpen: false }, () =>
        setTimeout(() => this.setState({ activeType: null }), 500),
      );
    } else {
      this.setState({ activeType: type, sidebarOpen: true });
    }
  };

  // → 🚨
  handleAlarm = alarm => {
    if (alarm) {
      return this.setState(
        {
          sidebarOpen: true,
          activeType: ALARMS,
          alarmToHandle: alarm,
        },
        () => {
          if (this.props.media.small || this.props.media.tablet) {
            this.props.history.push(`/trader?page=alarms`);
          }
        },
      );
    }
    return this.setState({
      activeType: ALARMS,
      alarmToHandle: undefined,
    });
  };

  inAskList = instrument =>
    this.state.askTradableInstruments.some(
      item => item === `${instrument.ITEM}.${instrument.SECTOR}`,
    );

  /* eslint-disable consistent-return */
  handleInstrument = async instrument => {
    if (!instrument) return this.setState({ instrument: false });

    const askTradable = this.inAskList(instrument);
    const instrumentChanged =
      !this.state.instrument ||
      !this.state.instrument?.ITEM_SECTOR ||
      instrument.ITEM_SECTOR !== this.state.instrument?.ITEM_SECTOR;

    this.setState(state => ({
      instrument: { ...state.instrument, askTradable },
      sidebarOpen: true,
      activeType: 'TRADER',
    }));
    if (instrumentChanged) {
      this.clearInstrument();
      const tradeRules = await this.getRules(instrument);
      this.setState(state => ({
        instrument: { ...state.instrument, tradeRules, askTradable },
      }));
    }

    this.setState(
      state => ({
        instrument: { ...state.instrument, ...instrument },
      }),
      () => {
        if (this.props.media.small || this.props.media.tablet) {
          this.props.history.push(`?&trader=active`);
        }
      },
    );
  };

  handleSuggestSelected = async (event, item) => {
    const tradeRules = await this.getRules(item.suggestion);
    const askTradable = this.inAskList(item.suggestion);
    const url = `${item.suggestion.ITEM}.${item.suggestion.SECTOR}`;

    this.setState({
      instrument: {
        ...item.suggestion,
        INSTRUMENT_TYPE: item.suggestion.TYPE,
        tradeType: 'BUY',
        tradeRules,
        askTradable,
      },
    });
    this.props.history.push(`/trader/instrument/${url}`);
  };

  render() {
    const {
      sidebarOpen,
      activeType,
      orderId,
      instrument,
      alarmToHandle, // → 🚨
      showMyAlarms,
      askTradableInstruments,
    } = this.state;
    const { small, toolbarBreakpoint, tablet, popSidebar } = this.props.media;
    const value = {
      mq: { small, toolbarBreakpoint, tablet, popSidebar },
      orderId,
      instrument,
      activeType,
      showMyAlarms,
      alarmToHandle, // → 🚨
      sidebarOpen,
      askTradableInstruments,
      list: defaultConfig,
      clearInstrument: this.clearInstrument,
      setShowMyAlarms: this.setShowMyAlarms,
      toggleSidebar: this.toggleSidebar,
      handleSelectType: type => this.handleSelectType(type),
      handleInstrument: item => this.handleInstrument(item),
      handleEditOrder: order => this.handleEditOrder(order),
      handleAlarm: alarm => this.handleAlarm(alarm), // → 🚨
      handleSuggestSelected: (event, item) =>
        this.handleSuggestSelected(event, item),
    };

    return (
      <SidebarContext.Provider value={value}>
        {this.props.children}
      </SidebarContext.Provider>
    );
  }
}

const WrappedSidebarProvider = compose(withMedia, withRouter)(SidebarProvider);

const SidebarConsumer = SidebarContext.Consumer;
const useSidebar = () => useContext(SidebarContext);

export {
  useSidebar,
  WrappedSidebarProvider as SidebarProvider,
  SidebarConsumer,
  SidebarContext,
};
