import PropTypes from 'prop-types';
import { Component } from 'react';
import { compose } from 'redux';
import reactTimeout from 'react-timeout';
import { withRouter } from 'react-router-dom';

import * as UserActions from 'state/user/actions';

/*
 * TODO: I don't see why this should be a react component. setState keeps giving
 * Warning: setState(...): Can only update a mounted or mounting component.
 *
 * Just make it a startKeepAlive() method in main instead
 */
class KeepAlive extends Component {
  static propTypes = {
    setInterval: PropTypes.func.isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
  };

  constructor(props) {
    super(props);

    this.endListen = this.endListen.bind(this);
    this.checkAlive = this.checkAlive.bind(this);
    this.handleUserInteraction = this.handleUserInteraction.bind(this);
  }

  state = {
    alive: false,
    interval: null,
    millis: 10000,
  };

  componentDidMount() {
    // Send KeepAlive signal on refresh
    UserActions.keepaliveSession();

    this.startInterval();
  }

  componentWillUnmount() {
    clearInterval(this.state.interval);
  }

  startInterval() {
    this.setState(
      {
        interval: this.props.setInterval(this.checkAlive, this.state.millis),
      },
      this.startListen,
    );
  }

  async checkAlive() {
    if (this.state.alive) {
      const response = await UserActions.keepaliveSession();
      if (!response.ok) {
        this.props.history.push('/login');
      }
    }

    // Reset state for next iteration
    this.resetAlive();
    this.startListen();
  }

  resetAlive() {
    this.setState({ alive: false });
  }

  startListen(event = 'click') {
    document.addEventListener(event, this.handleUserInteraction);
  }

  endListen(event = 'click') {
    document.removeEventListener(event, this.handleUserInteraction);
  }

  handleUserInteraction() {
    this.setState({ alive: true }, this.endListen);
  }

  render() {
    return false;
  }
}

export default compose(withRouter, reactTimeout)(KeepAlive);
