import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import CompetencyAssessmentTest from 'components/CompetancyTest/index.js';
import { getCustomerData } from 'utils/user';

export const TestPage = ({ user, handleToggle }) => {
  const customerData = getCustomerData(user);
  if (!customerData) return null;

  return <CompetencyAssessmentTest handleToggle={handleToggle} />;
};

const mapStateToProps = state => ({
  user: state.user,
});

TestPage.propTypes = {
  user: PropTypes.string.isRequired,
  handleToggle: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps)(TestPage);
