import { css } from '@emotion/core';

export const isOldBrowser = () =>
  navigator.userAgent.indexOf('MSIE') !== -1 ||
  navigator.appVersion.indexOf('Trident/') > -1;

export const browserIsEdge = () => /Edge/.test(navigator.userAgent);
export const browserIsSafari = () =>
  /.*Version.*Safari.*/.test(navigator.userAgent);

export const addCrappyOldBrowserStyles = style =>
  isOldBrowser() ? style : null;

export const ieMainStyle = props =>
  addCrappyOldBrowserStyles(css`
    @media (min-width: 2290px) {
      margin-right: ${props.sidebarOpen ? 'calc(472px + 15px)' : 'auto'};
    }
    @media (max-width: 2289px) {
      padding-right: ${props.sidebarOpen
        ? 'calc(472px + 15px)'
        : 'calc(72px + 15px)'};
    }
  `);

export default isOldBrowser;
