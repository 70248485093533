import { Component, createContext } from 'react';
import Cookie from 'js-cookie';

import PropTypes from 'prop-types';

const { Provider, Consumer: UpdatePageConsumer } = createContext();

class UpdatePageProvider extends Component {
  static propTypes = {
    children: PropTypes.node,
    streamingAllowed: PropTypes.bool,
    settings: PropTypes.shape({
      useWebSockets: PropTypes.bool,
    }),
  };
  state = { checked: this.props.settings.useWebSockets };

  handleSwitch = () => {
    const streamingEnabled = Cookie.get('streamingEnabled') === 'true';
    Cookie.set('streamingEnabled', !streamingEnabled);
    this.setState({ checked: !streamingEnabled });
    this.handleRefreshData(1500);
  };

  handleRefreshData = (timeout = 0) =>
    setTimeout(window.location.reload(), timeout);

  render() {
    const value = {
      canStream: this.props.streamingAllowed,
      checked: this.state.checked,
      handleSwitch: this.handleSwitch,
      handleRefreshData: this.handleRefreshData,
    };
    return <Provider value={value}>{this.props.children}</Provider>;
  }
}

export { UpdatePageProvider, UpdatePageConsumer };
