import { useSidebar } from 'components/Sidebar';
import MyAlarms from './MyAlarms';
import AlarmHeader from './AlarmHeader';
import AlarmForm from './AlarmForm';
import styles from './Alarms.module.scss';

export const Alarms = () => {
  const { showMyAlarms, setShowMyAlarms, handleAlarm } = useSidebar();

  const toggleAlarmHeader = () => {
    handleAlarm();
    setShowMyAlarms(!showMyAlarms);
  };
  return (
    <div className={styles.Alarms}>
      <h2>Alarmer</h2>
      <AlarmHeader
        showMyAlarms={showMyAlarms}
        handleAlarmMenu={toggleAlarmHeader}
      />
      {showMyAlarms ? (
        <MyAlarms handleAlarmMenu={toggleAlarmHeader} />
      ) : (
        <AlarmForm />
      )}
    </div>
  );
};

export default Alarms;
