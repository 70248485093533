import Select from 'react-select';
import { Field, useFormState } from 'react-final-form';
import {
  conditions,
  conditionsLast,
  conditionsChange,
} from 'utils/alarms/options';
import styles from '../../Alarms.module.scss';

const required = value =>
  value ? undefined : 'Velg hvordan alarmen skal trigges';

const getOptions = value => {
  if (value === 'CHANGE') return conditionsChange;
  if (value === 'LAST') return conditionsLast;
  if (value === 'CHANGE_PCT') return conditions;
  return [];
};

export const TriggerField = () => {
  const { values } = useFormState();
  if (!values.thresholdTrigger?.field?.value) return null;

  const options = getOptions(values.thresholdTrigger?.field?.value);

  return (
    <Field name="thresholdTrigger.trigger" validate={required}>
      {({ input, meta }) => (
        <div className={styles.field}>
          <label htmlFor="trigger">Trigger</label>
          <Select
            {...input}
            isSearchable={false}
            isClearable={false}
            placeholder="Velg trigger"
            options={options}
            value={
              options.find(opt => opt.value === input.value?.value) || null
            }
          />
          {meta.error && meta.touched && (
            <span className={styles.error}>{meta.error}</span>
          )}
        </div>
      )}
    </Field>
  );
};

export default TriggerField;
