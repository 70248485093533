// This is a duck experiment.. Quack! https://github.com/erikras/ducks-modular-redux
import Immutable from 'immutable';
import { logger } from '@oms/utils';
import { AGREEMENT_URL } from 'constants/Jaws';

import { get } from 'utils/fetch';
import { getSelectedAccounts } from 'state/user/selectors';

const RECIEVE_AGREEMENTS = 'norne/agreements/RECIEVE_AGREEMENTS';
const log = logger('state:agreements');

const initialState = Immutable.fromJS({
  portfolios: {},
});

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case RECIEVE_AGREEMENTS:
      return state.set('portfolios', Immutable.fromJS(action.payload));

    default:
      return state;
  }
}

export const fetchAgreements = () => async (dispatch, getState) => {
  const state = getState();
  const accounts = getSelectedAccounts(state);

  const promises = accounts.map(async account => {
    const result = await get(
      `${AGREEMENT_URL}/${account.get(
        'anonymizedOwnerId',
      )}/list?accountId=${account.get('id')}`,
    );

    if (!result.ok) {
      // Promise.all is fail-fast. We don't want everything to fail, so we
      // return no data and filter later
      const error = await result.text();
      log(
        `Failed to fetch agreements on ${account.get('id')} because ${error}`,
      );
      return Promise.resolve();
    }

    const { data } = await result.json();
    return {
      agreements: data,
      account: account.toJS(),
    };
  });

  // Send in parallell, block with await afterwards
  const responses = await Promise.all(promises);
  const agreements = responses
    .filter(result => result)
    .reduce(
      (result, data) => result.set(data.account.id, data),
      Immutable.Map(),
    );

  dispatch({ type: RECIEVE_AGREEMENTS, payload: agreements });
};
