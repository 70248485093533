import { Field } from 'react-final-form';
import { normalizeNumber } from '@oms/utils';

import styles from '../Trade.module.scss';

const validateLimit = limit => {
  const normalized = normalizeNumber(limit);
  if (!normalized || normalized <= 0) {
    return 'Ugyldig limit';
  }
  return undefined;
};

export const Limit = () => (
  <Field name="limit" validate={validateLimit}>
    {({ input, meta }) => (
      <div>
        <label htmlFor="volume">Limit/Pris</label>
        <input
          {...input}
          placeholder={`Limit`}
          name="limit"
          type="text"
          autoComplete="off"
          data-lpignore="true"
        />
        {meta.error && meta.submitFailed && (
          <span className={styles.formError}>{meta.error}</span>
        )}
      </div>
    )}
  </Field>
);

export default Limit;
