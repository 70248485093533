import { Field } from 'react-final-form';
import Radio from 'components/other/Radio';
import Icon from 'components/other/Icons';

import styles from './TestForm.module.scss';

const YearsOfExperienceField = field => (
  <div className={styles.FieldContainer} tabIndex="0">
    <p>Kunden har mer enn ett års yrkeserfaring med finansielle instrumenter</p>
    <Field name="financeWorkExperience" type="radio" value="true">
      {({ input }) => (
        <label htmlFor="financeWorkExperience_true">
          <Radio
            id="financeWorkExperience_true"
            type="radio"
            value="true"
            checked={input.checked}
            onChange={input.onChange}
          />
          Ja
        </label>
      )}
    </Field>
    <Field name="financeWorkExperience" type="radio" value="false">
      {({ input }) => (
        <label htmlFor="financeWorkExperience_false">
          <Radio
            id="financeWorkExperience_false"
            type="radio"
            value="false"
            checked={input.checked}
            onChange={input.onChange}
          />
          Nei
        </label>
      )}
    </Field>
    {field.meta.error && field.meta.touched && (
      <div className={styles.errorContainer}>
        <Icon icon="exclamation-triangle" />
        <span>{field.meta.error}</span>
      </div>
    )}
  </div>
);

export default YearsOfExperienceField;
