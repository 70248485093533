import { Field } from 'react-final-form';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Suggest } from '@oms/components';
import Suggestion from 'components/Trader/Search/components/Suggestion';
import styles from './search.module.scss';

export const TickerSearch = ({ onChange }) => (
  <Field name="ticker">
    {({ input, meta }) => (
      <div>
        <div className={classNames(styles.Search)}>
          <Suggest
            {...input}
            autoFocus
            highlightFirstSuggestion
            group="SEARCH_EQUITIES"
            renderSuggestion={Suggestion}
            onSuggestionSelected={(event, { suggestion }) => {
              event.preventDefault();
              onChange(suggestion);
            }}
            inputProps={{
              'data-lpignore': 'true',
            }}
          />
        </div>
        {meta.error && meta.touched && (
          <span className={styles.formError}>{meta.error}</span>
        )}
      </div>
    )}
  </Field>
);

TickerSearch.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default TickerSearch;
