import { useEffect, useState, useMemo } from 'react';
import Immutable from 'immutable';
import { useActiveOrdersContext } from 'state/activeOrders';
import ActiveOrdersTable from './ActiveOrdersTable';
import Filters from './Filters';
import styles from './ActiveOrders.module.scss';

export const BUY = 0;
export const SELL = 1;
export const TODAY = 2;
const includeStatuses = [0, 1, 2, 3, 4, 5, 6, 8, 9, 10];
const searchFields = [
  'LIMIT',
  'TOTAL_VOLUME',
  'TICKER_CODE',
  'INSTRUMENT_NAME',
  'ORDER_ID',
  'REMAINING_VOLUME',
];

function objContainsValue(object: Object, value: any) {
  return Object.entries(object).some(([key, entry]) => {
    if (!searchFields.includes(key)) return false;

    const matchFound = entry
      ?.toString()
      .toLowerCase()
      .includes(value.toString().toLowerCase());

    return matchFound;
  });
}

export const OrdersExpand = () => {
  const [filter, setFilter] = useState<string | null>(null);
  const [status, setStatus] = useState<number | number[]>();
  const [today, setToday] = useState<any>();
  const { fetchToday, updateSeenOrders } = useActiveOrdersContext();

  useEffect(() => {
    handleFetchToday();
    updateSeenOrders();
    // Start polling.
    // NOTE: This is very heavy on probroker, so we can't do this in a more
    // global context than this tab
    const fetchInterval = setInterval(() => {
      handleFetchToday();
    }, 30000);

    return () => {
      if (fetchInterval) {
        clearInterval(fetchInterval);
      }
    };
    // Kept it close to original CDM, but maybe it could/should include dependencies.
    //eslint-disable-next-line
  }, []);

  const handleFetchToday = async () => {
    const today = (await fetchToday()).toJS();
    setToday(today);
  };

  const filteredOrders = useMemo((): any[] => {
    if (!today) return [];

    const result = Immutable.fromJS(
      Object.values(today)
        .filter((account: any) => account.orders?.length > 0)
        .reduce(
          (result: any[], item: any) =>
            result.concat(
              item.orders.map((order: any) => ({
                ...order,
                account: item.account,
              })),
            ),
          [],
        ),
    )
      .sortBy((order: Immutable.Map<any, any>) => order.get('CREATED_DATE'))
      .reverse()
      .filter(
        (order: Immutable.Map<any, any>) =>
          order?.get('item')?.get('INSTRUMENT_TYPE') !== 'FUNDS',
      )
      .filter((order: Immutable.Map<any, any>) =>
        includeStatuses.includes(order.get('ORDER_STATUS')),
      )
      .toJS()
      .filter((order: any) => {
        if (!status) return true;
        if (!Array.isArray(status) && order.ORDER_STATUS === status)
          return true;
        if (Array.isArray(status) && status.includes(order.ORDER_STATUS))
          return true;
        return false;
      })
      .filter((order: any) => !filter || objContainsValue(order, filter));

    return result;
  }, [today, filter, status]);

  return (
    <div className={styles.ActiveOrders}>
      <div className={styles.header}>
        <h2>Aktive Ordre</h2>
      </div>

      <Filters
        onSearch={newFilter => setFilter(newFilter)}
        onChangeStatus={(newStatus: any) => setStatus(newStatus)}
      />

      {filteredOrders?.map(order => (
        <ActiveOrdersTable order={order} />
      ))}
      {!filteredOrders?.length && <i>Ingen aktive ordre</i>}
    </div>
  );
};

export default OrdersExpand;
