import Immutable from 'immutable';
import * as Graphdata from 'constants/Graphdata';

import * as ActionTypes from './types';

const initialState = Immutable.fromJS({
  selection: Graphdata.SELECTION_SIX_MONTHS,
  main: {
    stale: true,
    instrument: null,
    series: null,
  },
  compares: {},
  technicalIndicators: {},
  additions: {
    news: {
      name: 'Nyheter',
      active: false,
      stale: true,
      flags: true,
      color: '#00BD3C',
    },
    dividends: {
      name: 'Utbytte',
      active: false,
      stale: true,
      flags: true,
      color: '#FF7738',
    },
    volume: {
      name: 'Volum',
      active: false,
      stale: true,
      flags: false,
      color: '#38C0FF',
    },
    turnover: {
      name: 'Omsetning',
      active: false,
      stale: true,
      flags: false,
      color: '#FA4F00',
    },
    candlestick: {
      name: 'Candlestick',
      active: false,
      stale: true,
      flags: false,
      color: '#0088bc',
    },
    transactions: {
      name: 'Dine handler',
      active: false,
      stale: true,
      flags: true,
      color: '#00ABFA',
    },
  },
});

export default function analysisChart(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.ANALYSIS_CHART_SET_MAIN_INSTRUMENT:
      return state.setIn(
        ['main', 'instrument'],
        Immutable.fromJS(action.instrument),
      );

    case ActionTypes.ANALYSIS_CHART_CLEAR_MAIN_INSTRUMENT:
      return state.set('main', initialState.get('main'));

    case ActionTypes.ANALYSIS_CHART_RECEIVE_GRAPHDATA_MAIN:
      return state
        .setIn(['main', 'series'], action.series)
        .setIn(['main', 'stale'], false);

    case ActionTypes.ANALYSIS_CHART_RECEIVE_GRAPHDATA:
      return state
        .setIn(
          ['compares', action.instrument.get('itemSector'), 'series'],
          action.series,
        )
        .setIn(
          ['compares', action.instrument.get('itemSector'), 'stale'],
          false,
        );

    case ActionTypes.ANALYSIS_CHART_ADD_COMPARE:
      return state
        .setIn(
          ['compares', action.instrument.get('itemSector'), 'instrument'],
          action.instrument,
        )
        .setIn(
          ['compares', action.instrument.get('itemSector'), 'stale'],
          true,
        );

    case ActionTypes.ANALYSIS_CHART_REMOVE_COMPARE:
      return state.deleteIn(['compares', action.key]);

    case ActionTypes.ANALYSIS_CHART_INVALIDATE_ALL:
      return state
        .setIn(['main', 'stale'], true)
        .set(
          'compares',
          state.get('compares').map(item => item.set('stale', true)),
        )
        .set(
          'additions',
          state.get('additions').map(item => item.set('stale', true)),
        )
        .set(
          'technicalIndicators',
          state
            .get('technicalIndicators')
            .map(item => item.set('stale', true).set('series', null)),
        );

    case ActionTypes.ANALYSIS_CHART_SET_SELECTION:
      return state.set('selection', action.selection);

    case ActionTypes.ANALYSIS_CHART_TOGGLE_ADDITION:
      return state.setIn(
        ['additions', action.key, 'active'],
        !state.getIn(['additons', action.key, 'active']),
      );

    case ActionTypes.ANALYSIS_CHART_REMOVE_ADDITION:
      return state.setIn(['additions', action.key, 'active'], false);

    case ActionTypes.ANALYSIS_CHART_RECEIVE_ADDITION:
      return state
        .setIn(['additions', action.key, 'series'], action.series)
        .setIn(['additions', action.key, 'stale'], false);

    case ActionTypes.ANALYSIS_CHART_ADD_TECHNICAL_INDICATOR:
      return state.setIn(
        ['technicalIndicators', action.key, 'series'],
        action.data,
      );

    case ActionTypes.ANALYSIS_CHART_REMOVE_TECHNICAL_INDICATOR:
      return state.deleteIn(['technicalIndicators', action.key]);

    case ActionTypes.ANALYSIS_CHART_REMOVE_ALL_TECHNICAL_INDICATORS:
      return state.set('technicalIndicators', Immutable.fromJS({}));

    default:
      return state;
  }
}
