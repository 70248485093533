const validate = values => {
  const errors = {};
  const required = [
    'financeWorkExperience',
    'economicsEducation',
    'unlistedTradesAmount',
    'unlistedTradesKnowledge',
    'subscriptionRightsAmount',
    'subscriptionRightsKnowledge',
    'listedTradesAmount',
    'listedTradesKnowledge',
    'etnTradesAmount',
    'etnTradesKnowledge',
    'highestEducation',
    'occupation',
  ];

  // Check what fields are lacking in values and add those to the errors-object as "Påkrevd"
  required
    .filter(requiredField => !(requiredField in values))
    .forEach(lackingField => {
      errors[lackingField] = 'Påkrevd';
    });

  return errors;
};

export default validate;
