import { useState } from 'react';
import Select from 'react-select';

import styles from './ActiveOrders.module.scss';

const options = [
  {
    value: false,
    label: 'Alle statuser',
  },
  {
    label: 'Ikke på børs',
    value: 0,
  },
  {
    label: 'Avvist',
    value: 1,
  },
  {
    label: 'Akseptert',
    value: 2,
  },
  {
    label: 'Venter',
    value: [3, 10],
  },
  {
    label: 'Utført',
    value: 4,
  },
  {
    label: 'Kansellert',
    value: 5,
  },
  {
    label: 'Utgått',
    value: 6,
  },
  {
    label: 'Trukket',
    value: 7,
  },
  /*
  Not included
  {
    label: 'Stop loss',
    value: 8,
  },
  {
    label: 'Manuell',
    value: 9,
  },
  {
    label: 'Venter',
    value: 10,
  },
  */
];

type Props = {
  onSearch: (s: string) => void;
  onChangeStatus: (v?: string | boolean) => void;
};

type Status = {
  label: string;
  value: boolean | number | number[];
};

export const Filters = ({ onSearch, onChangeStatus }: Props) => {
  const [orderStatus, setStatus] = useState<Status | null>(options[0]);

  return (
    <div className={styles.filters}>
      <label className={styles.search} htmlFor="SearchOrders">
        <span className="sr-only">Filtrer liste med fritekstsøk</span>
        <input
          type="search"
          onChange={({ target }) => onSearch(target.value)}
          placeholder="Søk etter ordre"
          id="SearchOrders"
        />
      </label>

      <label className={styles.search} htmlFor="StatusOrders">
        <span className="sr-only">Status</span>
        <Select
          inputId="StatusOrders"
          value={orderStatus}
          isClearable={false}
          options={options}
          onChange={(newStatus: any) => {
            setStatus(newStatus);
            onChangeStatus(newStatus.value);
          }}
        />
      </label>
    </div>
  );
};

export default Filters;
