import { Component } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import Cookie from 'js-cookie';
import { JawsProvider } from '@oms/jaws-react';
import Jaws from '@oms/jaws';
import { BASE_URL, BASE_SECURE_URL } from 'constants/Jaws';
import 'focus-visible';
import KeepAlive from 'components/other/KeepAlive';
import { SidebarProvider } from 'components/Sidebar';
import Modal, { ModalProvider } from 'components/Modal';
import BankStylesheet from 'components/other/BankStylesheet';
import ScrollToTopButton from 'components/other/ScrollToTopButton';
import { UpdatePageProvider } from 'components/other/UpdatePage';
import JumpLink from 'components/other/JumpLink';
import { ScrollToTop } from 'components/other/ScrollToTop';

import * as UserActions from 'state/user/actions';
import { canStream } from 'utils/user';

import 'components/other/Icons';

class App extends Component {
  static propTypes = {
    children: PropTypes.element.isRequired,
    fetchUserInfo: PropTypes.func.isRequired,
  };

  state = {};

  async componentDidMount() {
    const data = await this.props.fetchUserInfo();
    const streamingAllowed = canStream(Immutable.fromJS(data));
    const streamingCookie = Cookie.get('streamingEnabled');
    const useWebSockets = streamingAllowed && streamingCookie !== 'false';

    if (useWebSockets) {
      Cookie.set('streamingEnabled', true, {
        expires: 365000,
      });
    }
    const jaws = new Jaws({
      url: data.authenticated ? BASE_SECURE_URL : BASE_URL,
      useWebSockets,
    });

    // eslint-disable-next-line react/no-did-mount-set-state
    this.setState({
      jaws,
      streamingAllowed,
    });
  }

  render() {
    const { children } = this.props;
    const { jaws } = this.state;

    if (!jaws) return null;

    return (
      <JawsProvider value={{ instance: jaws }}>
        <ScrollToTop />
        <div className="application">
          <BankStylesheet />
          <JumpLink />
          <JumpLink targetDomId="content-wrapper" text="Hopp til undermeny" />
          <ModalProvider>
            <SidebarProvider>
              <Modal />
              <UpdatePageProvider
                settings={jaws}
                streamingAllowed={this.state.streamingAllowed}
              >
                <KeepAlive />
                {children}
                <ScrollToTopButton />
              </UpdatePageProvider>
            </SidebarProvider>
          </ModalProvider>
        </div>
      </JawsProvider>
    );
  }
}

// Inject user and actions
export default connect(null, UserActions)(App);
