import PropTypes from 'prop-types';
import Media from 'react-media';
import Skeleton from 'react-skeleton-loader';
import Icon from 'components/other/Icons';
import Button from 'components/other/Button';

const InstrumentTitle = ({ handleInstrument, instrument }) => {
  const { ITEM, SECTOR, LONG_NAME } = instrument;
  if (!ITEM || !SECTOR || !LONG_NAME) {
    return <Skeleton width={'420px'} height={'35px'} color={'#f7f7f7'} />;
  }

  const getMarketplace = () => {
    switch (SECTOR?.toUpperCase()) {
      case 'MERK':
        return 'Euronext Growth';
      case 'OAX':
        return 'Euronext Expand';
      case 'OSE':
        return 'Oslo Børs';
      default:
        return SECTOR;
    }
  };

  return (
    <Media query="(max-width: 420px)">
      {small => (
        <>
          <h3>{LONG_NAME} </h3>
          <p>{`  ${getMarketplace()}: ${ITEM} `}</p>
          {!small && (
            <Button
              clean
              aria-label={`fjern ${ITEM}`}
              onClick={() => handleInstrument(false)}
            >
              <Icon icon="times" />
            </Button>
          )}
        </>
      )}
    </Media>
  );
};

InstrumentTitle.propTypes = {
  handleInstrument: PropTypes.func,
  instrument: PropTypes.shape({
    ITEM: PropTypes.string,
    SECTOR: PropTypes.string,
    LONG_NAME: PropTypes.string,
  }),
};

export default InstrumentTitle;
