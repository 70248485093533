import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import Daypicker from './Daypicker';

import styles from '../Trade.module.scss';

export const Calendar = ({ closeDates }) => (
  <Field name="tradeTime">
    {({ meta }) => (
      <div>
        <label htmlFor="tradeTime">Første trekkdato</label>
        <Field
          name="tradeTime"
          type="select"
          component={Daypicker}
          closeDates={closeDates}
        />
        {meta.error && meta.touched && (
          <span className={styles.formError}>{meta.error}</span>
        )}
      </div>
    )}
  </Field>
);

Calendar.propTypes = {
  closeDates: PropTypes.array,
};

export default Calendar;
