import PropTypes from 'prop-types';
import { JawsComponent } from '@oms/jaws-react';
import { formatNumber as number } from '@oms/utils';
import { portfolioSummary, getSpec } from 'utils/fictivePortfolio';
import Button from 'components/other/Button';

const PortfolioRow = ({
  accountName,
  portfolioId,
  deletePortfolio,
  userId,
  setPortfolio,
}) => (
  <JawsComponent spec={getSpec(portfolioId, userId)}>
    {({ items }) => {
      const summary = portfolioSummary(items);
      if (summary) {
        return (
          <tr>
            <td className="text">
              <Button
                link
                size="small"
                onClick={() => setPortfolio({ portfolioId, summary })}
              >
                {accountName}
              </Button>
            </td>
            <td>{number(summary?.marketValue)}</td>
            <td>
              <Button
                onClick={() => deletePortfolio(portfolioId, 'delete')}
                aria-label={`Slett ${accountName}`}
                value={portfolioId}
                icon="trash-alt"
              />
            </td>
          </tr>
        );
      }
      return null;
    }}
  </JawsComponent>
);

PortfolioRow.propTypes = {
  setPortfolio: PropTypes.func.isRequired,
  userId: PropTypes.string.isRequired,
  portfolioId: PropTypes.string.isRequired,
  deletePortfolio: PropTypes.func.isRequired,
  accountName: PropTypes.string.isRequired,
};

export default PortfolioRow;
