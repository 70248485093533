import template from 'url-template';
import URLEncodedFormData from 'utils/URLEncodedFormData';
import { COMPONENTS_SECURE_URL } from '../constants/Jaws';

type Resource = string | { url: string; [key: string]: any };
type Body =
  | string
  | FormData
  | Blob
  | ArrayBufferView
  | ArrayBuffer
  | URLSearchParams
  | ReadableStream<Uint8Array>
  | null;
type Headers = { [header: string]: string };
type Spec = { [header: string]: string };

const buildResource = (resource: Resource) => {
  if (typeof resource === 'string') {
    return resource;
  } else if (resource.url) {
    const { url, ...variables } = resource;
    const parsedUrl = template.parse(url);

    return parsedUrl.expand(variables);
  }

  throw new Error(
    'Resource must be either a string or an object with a url template property and variables. ' +
      `Got ${resource}.`,
  );
};

const getContentType = (body?: Body) => {
  let contentType = 'application/json; charset=UTF-8';
  if (body instanceof FormData) {
    contentType = 'multipart/form-data; charset=UTF-8';
  } else if (body instanceof URLEncodedFormData) {
    contentType = 'application/x-www-form-urlencoded; charset=UTF-8';
  }

  return contentType;
};

export const get = (resource: Resource, headers?: Headers) =>
  fetch(buildResource(resource), {
    credentials: 'same-origin',
    headers: headers ? new Headers(headers) : {},
  });

export const post = (resource: Resource, body?: Body, headers?: Headers) => {
  let parsedBody;
  if (!body) {
    parsedBody = undefined;
  } else if (body && getContentType(body).includes('multipart/form-data')) {
    parsedBody = body;
  } else {
    parsedBody = body.toString();
  }

  return fetch(buildResource(resource), {
    method: 'POST',
    credentials: 'same-origin',
    body: parsedBody,
    headers: new Headers({
      'Content-Type': getContentType(body),
      ...headers,
    }),
  });
};

export const filePost = (
  resource: Resource,
  body?: Body,
  headers?: Headers,
) => {
  let parsedBody;
  if (!body) {
    parsedBody = undefined;
  } else if (body && getContentType(body).includes('multipart/form-data')) {
    parsedBody = body;
  } else {
    parsedBody = body.toString();
  }

  return fetch(buildResource(resource), {
    method: 'POST',
    credentials: 'same-origin',
    body: parsedBody,
    headers: new Headers({
      ...headers,
    }),
  });
};

export const filePut = (resource: Resource, body?: Body, headers?: Headers) => {
  let parsedBody;
  if (!body) {
    parsedBody = undefined;
  } else if (body && getContentType(body).includes('multipart/form-data')) {
    parsedBody = body;
  } else {
    parsedBody = body.toString();
  }

  return fetch(buildResource(resource), {
    method: 'PUT',
    credentials: 'same-origin',
    body: parsedBody,
    headers: new Headers({
      ...headers,
    }),
  });
};

export const put = (resource: Resource, body?: Body, headers?: Headers) =>
  fetch(buildResource(resource), {
    method: 'PUT',
    credentials: 'same-origin',
    body: body ? body.toString() : undefined,
    headers: new Headers({
      'Content-Type': getContentType(body),
      ...headers,
    }),
  });

export const del = (resource: Resource, headers?: Headers) =>
  fetch(buildResource(resource), {
    method: 'DELETE',
    credentials: 'same-origin',
    headers: headers ? new Headers(headers) : {},
  });

export const getComponent = (spec: Spec) =>
  get({
    url: `${COMPONENTS_SECURE_URL}{?query*}`,
    query: spec,
  });
