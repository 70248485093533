import { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Collapse } from 'react-collapse';
import { VisuallyHidden } from '@oms/components-typography';

import Icon from 'components/other/Icon';
import styles from './HelpText.module.scss';

export default class HelpText extends Component {
  static propTypes = {
    title: PropTypes.node.isRequired,
    children: PropTypes.node.isRequired,
    startExpanded: PropTypes.bool,
    className: PropTypes.string,
  };

  static defaultProps = {
    startExpanded: false,
  };

  state = {
    expanded: this.props.startExpanded,
  };

  handleExpand = () => {
    this.setState({ expanded: !this.state.expanded });
  };

  render() {
    const { title, children, className } = this.props;
    const { expanded } = this.state;

    /*
     * button is hidden for sreen readers because text is always
     * mounted, and thus readable for screen readers.
     */
    return (
      <div className={classNames('HelpText', className, styles.HelpText)}>
        <VisuallyHidden>{title}</VisuallyHidden>
        <button type="button" onClick={this.handleExpand} aria-hidden="true">
          {title} <Icon type="fa-question-circle-o" />
        </button>
        <Collapse isOpened={expanded}>
          <button
            type="button"
            onClick={this.handleExpand}
            tabIndex={expanded ? 0 : -1}
          >
            lukk
          </button>
          {children}
        </Collapse>
      </div>
    );
  }
}
