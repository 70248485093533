import * as Sentry from '@sentry/browser';

type SetupOptions = {
  /** The Sentry DSN to use when in prod */
  prodDSN: string;
  /** The Sentry DSN to use when not in prod */
  devDSN: string;
  /** A regex to determine if the current browser URL is a prod URL */
  prodUrl: string;
  /** A version string of the current running app */
  release?: string;
};
/** Configures the error tracking for this session */
export function setup(options: SetupOptions) {
  if (!options) {
    throw new Error('Sentry.setup: options object is required');
  }

  const { prodDSN, devDSN, prodUrl, release } = options;

  if (!prodDSN || !devDSN || !prodUrl) {
    throw new Error(
      'Sentry.setup: prodDSN, devDSN, prodUrl ' +
        'are all required option fields. options=' +
        JSON.stringify(options),
    );
  }

  var isProd = new RegExp(prodUrl).test(window.location.hostname);
  Sentry.init({ dsn: isProd ? prodDSN : devDSN, release });
}

/**
 * Sets the user in context for future sentry error logging. This adds metadata
 * of the user object to the errors in the sentry web gui.
 *
 * Also see https://docs.getsentry.com/on-premise/learn/context/#capturing-the-user
 *
 * @param {object} user The user object to set as metadata
 */
export function setUser(user: any) {
  if (!user) {
    throw new Error(
      'Sentry.setUser: user is required. user=' + JSON.stringify(user),
    );
  }

  Sentry.configureScope(scope => {
    scope.setUser(user);
  });
}

/**
 * To be used when the user submits a form, yet validation is invalid. Useful
 * when debugging issues with browser-side validation in production.
 *
 * Should be combined with user metadata set in Sentry.setUser to make
 * this data usable for searching
 *
 * @param {string} title The title of this event. Should be something
 * descriptive of the form if there are many forms in the application.
 * @param {object} data Arbitrary data to include in the exception. Probably
 * most useful if this is the current fields validation state and content.
 */
export function reportValidationFailed(title = 'Validation error', data: any) {
  Sentry.withScope(scope => {
    scope.setLevel(Sentry.Severity.Info);
    scope.setTag('type', 'validation');
    scope.setExtras(data);
    Sentry.captureException(new Error(title));
  });
}

/**
 * Send an error to Sentry, then get the user to fill in more information
 * via a modal popup.
 *
 * @param {object} err An error to attach the user's report to
 */
export function throwErrorAndCollectFeedbackFromUser(err: Error) {
  if (!err) {
    throw new Error(
      'Sentry.showReportDialog: An error object is required in ' +
        'order to show the report dialog',
    );
  }

  Sentry.captureException(err);
  Sentry.showReportDialog();
}

export default Sentry;
