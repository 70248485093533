import PropTypes from 'prop-types';
import { normalizeNumber, formatNumber } from '@oms/utils';
import { useCommission } from 'utils/commission';

import styles from './summary.module.scss';

const Summary = ({ values }) => {
  const commission = useCommission(values);

  if (!values) {
    return null;
  }

  const normalizedLimit = normalizeNumber(values.limit);
  const normalizedVolume = normalizeNumber(values.volume);
  const totalPrice = normalizedLimit * normalizedVolume + (commission || 0);
  return (
    <div className={styles.Summary}>
      <table>
        <tbody>
          <tr>
            <td>Kurtasje 0,05% per handel, minimum 95</td>
            <td>{formatNumber(commission)} kr</td>
          </tr>
          <tr>
            <td>Estimert ordreverdi</td>
            <td>{formatNumber(normalizedLimit * normalizedVolume)} kr</td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td>Total estimert beløp</td>
            <td>{formatNumber(totalPrice)} kr</td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
};

Summary.propTypes = {
  values: PropTypes.object,
};

export default Summary;
