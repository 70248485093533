import { Component } from 'react';
import { formatNumber, decimalPlaces } from '@oms/utils';
import PropTypes from 'prop-types';
import Button from 'components/other/Button';
import Switch from 'components/other/Switch';
import { fields, newsTypes } from 'utils/alarms/options';
import { SidebarConsumer } from 'components/Sidebar';

export class AlarmRow extends Component {
  static propTypes = {
    alarm: PropTypes.shape({}).isRequired,
    deleteAlarm: PropTypes.func.isRequired,
    toggleAlarm: PropTypes.func.isRequired,
    handleAlarmMenu: PropTypes.func.isRequired,
  };

  mapCriteria = alarm => {
    const { intradayTrigger, thresholdTrigger } = alarm;

    if (thresholdTrigger) {
      const { field, value, trigger } = thresholdTrigger;
      const absValue = Math.abs(value);
      const upOrDown = trigger.includes('LT') ? 'faller ' : 'stiger ';
      if (field.includes('CHANGE')) {
        return `Kursen ${upOrDown} med minst
				${formatNumber(absValue, decimalPlaces(absValue))}${
          field.includes('PCT') ? '%' : ' kr'
        }   på én dag`;
      }

      if (field.includes('LAST')) {
        return `Siste ${upOrDown} til minst ${formatNumber(
          absValue,
          decimalPlaces(absValue),
        )}kr`;
      }
    }

    if (intradayTrigger && intradayTrigger.field === 'HIGH_5YEARS') {
      return fields.find(item => item.value === intradayTrigger?.field).label;
    }

    return '';
  };

  handleToggle = () => this.setState({ alarmList: !this.state.alarmList });

  handleEditAlarm = (alarm, handleAlarm) => {
    this.props.handleAlarmMenu(false);
    handleAlarm(alarm);
  };

  render() {
    const { alarm } = this.props;

    return (
      <ul>
        {alarm.type === 'THRESHOLD' || alarm.type === 'INTRADAY' ? (
          <li>
            <b> {`${alarm.item}`} </b>
            {this.mapCriteria(alarm)}
          </li>
        ) : (
          <li>
            <b>{alarm.item}</b>
            Nyheter:{' '}
            {newsTypes.find(type => type.value === alarm?.newsType)?.label}
          </li>
        )}
        <li>
          <SidebarConsumer>
            {({ handleAlarm }) => (
              <>
                <Button
                  onClick={() => this.handleEditAlarm(alarm, handleAlarm)}
                  aria-label="Endre alarm"
                  icon={'edit'}
                />
                <Button
                  onClick={() => this.props.deleteAlarm(alarm)}
                  aria-label="Slett alarm"
                  icon={'trash-alt'}
                />
                <Switch
                  id={alarm.id}
                  checked={alarm.active}
                  onSwitch={() => this.props.toggleAlarm(alarm, !alarm.active)}
                />
              </>
            )}
          </SidebarConsumer>
        </li>
      </ul>
    );
  }
}

export default AlarmRow;
