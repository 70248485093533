import { useEffect, useRef, forwardRef } from 'react';

/**
 * Returns a onWheel-function that blocks changing default behaviour like changing value in number-inputs
 *
 * https://github.com/facebook/react/issues/14856#issuecomment-762970883
 * I'm not happy with this, but it works
 */
export const useBlockWheel = () => {
  const wheelTimeout = useRef<NodeJS.Timeout | false>(null!);

  const onWheel = (e: React.WheelEvent<HTMLInputElement>) => {
    // while wheel is moving, do not release the lock
    if (wheelTimeout.current) clearTimeout(wheelTimeout.current);

    wheelTimeout.current = setTimeout(() => {
      wheelTimeout.current = false;
    }, 300);
  };

  // block the body from scrolling (or any other element)
  useEffect(() => {
    const cancelWheel = (e: WheelEvent) => {
      const isFocused = document.activeElement === e.target;
      if (wheelTimeout.current && isFocused) e.preventDefault();
    };
    document.body.addEventListener('wheel', cancelWheel, { passive: false });
    return () => document.body.removeEventListener('wheel', cancelWheel);
  }, []);

  return onWheel;
};

/** This is a number-input that disables onWheel-functionality  */
export const NumberInput = forwardRef<
  HTMLInputElement,
  React.HTMLProps<HTMLInputElement>
>((props, ref) => {
  const onWheel = useBlockWheel();
  return <input ref={ref} type="number" onWheel={onWheel} {...props} />;
});
