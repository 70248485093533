import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import HelpText from 'components/other/HelpText';
import Daypicker from './Daypicker';
import { getAmountOfValidDaysForOrder } from 'utils/trade';

import styles from '../../../../TradeForm.module.scss';

export const Calendar = ({
  selectedAccount,
  closeRules,
  tradeType,
  orderId,
  disabled,
}) => {
  const validDaysForBuyOrder = getAmountOfValidDaysForOrder(
    'BUY',
    selectedAccount?.paymentAgent,
  );
  const validDaysForSellOrder = getAmountOfValidDaysForOrder(
    'SELL',
    selectedAccount?.paymentAgent,
  );

  return (
    <Field name="calendar">
      {({ meta }) => (
        <div>
          {disabled && (
            <div className={styles.infoMessage}>
              <p>
                Du må endre limit og/eller antall for å kunne endre utløpsdato.
              </p>
            </div>
          )}
          <HelpText
            className={styles.helpText}
            title={<label htmlFor="calendar">Dato</label>}
          >
            Etter denne datoen vil ordren automatisk bli kansellert. Maksimalt{' '}
            {validDaysForBuyOrder - 1} dager for en kjøpsordre.{' '}
            {validDaysForSellOrder} dager for en salgsordre
          </HelpText>
          <Field
            name="calendar"
            type="select"
            component={Daypicker}
            selectedAccount={selectedAccount}
            closeRules={closeRules}
            tradeType={tradeType}
            orderId={orderId}
            disabled={disabled}
          />
          {meta.error && meta.touched && (
            <span className={styles.formError}>{meta.error}</span>
          )}
        </div>
      )}
    </Field>
  );
};

Calendar.propTypes = {
  closeRules: PropTypes.array.isRequired,
  tradeType: PropTypes.string.isRequired,
  selectedAccount: PropTypes.object,
  orderId: PropTypes.string,
  disabled: PropTypes.bool,
};

export default Calendar;
