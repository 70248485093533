import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';

import Button from 'components/other/Button';
import OccupationField from './OccupationField';
import YearsOfExperienceField from './YearsOfExperienceField';
import EducationField from './EducationField';
import EconomicsEducationField from './EconomicsEducationField';
import UnlistedTradesAmountField from './UnlistedTradesAmountField';
import UnlistedTradesKnowledgeField from './UnlistedTradesKnowledgeField';
import SubscriptionRightsAmountField from './SubscriptionRightsAmountField';
import SubscriptionRightsKnowledgeField from './SubscriptionRightsKnowledgeField';
import ListedTradesKnowledgeField from './ListedTradesKnowledgeField';
import ListedTradesAmountField from './ListedTradesAmountField';
import EtnTradesAmountField from './EtnTradesAmountField';
import EtnTradesKnowledgeField from './EtnTradesKnowledgeField';

import styles from './TestForm.module.scss';

import validate from './validate';

const TestForm = ({ onSubmit }) => (
  <section>
    <Form
      validate={validate}
      onSubmit={onSubmit}
      render={({ handleSubmit, submitting, pristine, form }) => (
        <form onSubmit={handleSubmit} className={styles.Form}>
          <Field name="occupation" component={OccupationField} />
          <Field
            name="financeWorkExperience"
            component={YearsOfExperienceField}
          />
          <Field name="highestEducation" component={EducationField} />
          <Field
            name="economicsEducation"
            component={EconomicsEducationField}
          />
          <Field
            name="unlistedTradesAmount"
            component={UnlistedTradesAmountField}
          />
          <Field
            name="unlistedTradesKnowledge"
            component={UnlistedTradesKnowledgeField}
          />
          <Field
            name="subscriptionRightsAmount"
            component={SubscriptionRightsAmountField}
          />
          <Field
            name="subscriptionRightsKnowledge"
            component={SubscriptionRightsKnowledgeField}
          />
          <Field
            name="listedTradesAmount"
            component={ListedTradesAmountField}
          />
          <Field
            name="listedTradesKnowledge"
            component={ListedTradesKnowledgeField}
          />
          <Field name="etnTradesAmount" component={EtnTradesAmountField} />
          <Field
            name="etnTradesKnowledge"
            component={EtnTradesKnowledgeField}
          />

          <div className={styles.buttons}>
            <Button type="submit" primary disabled={submitting}>
              Fullfør testen
            </Button>
            <Button
              type="button"
              secondary
              onClick={form.reset}
              disabled={submitting || pristine}
            >
              Tøm skjema
            </Button>
          </div>
        </form>
      )}
    />
  </section>
);

TestForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default TestForm;
