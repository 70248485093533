const columns = [
  'ITEM_SECTOR',
  'LASTNZ_DIV',
  'LAST',
  'CLOSE',
  'COST_BUY_PRICE',
  'CHANGE',
  'CHANGE_PCT',
].join(',');

const sortHoldings = holdings =>
  holdings.sort((a, b) => (a?.ITEM < b?.ITEM ? -1 : 1));

export const mapStocksToAccount = stocks => {
  if (!stocks) return [];
  const accounts = [];
  stocks.map(item => {
    const index = accounts.findIndex(acc => acc.account.id === item.account.id);
    if (index === -1) {
      return accounts.push({
        account: {
          id: item.account.id,
          accountName: item?.account?.accountName,
        },
        stocks: [item.values],
      });
    }
    return accounts[index]?.stocks.push(item.values);
  });
  accounts?.map(item => sortHoldings(item?.stocks));
  return accounts;
};

const getItemSectors = stocks =>
  stocks.map(stock => stock.values.ITEM_SECTOR).join();

export const getHoldingsSpec = stocks => {
  const itemSector = getItemSectors(stocks);
  return itemSector ? { itemSector, columns } : null;
};
