import { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import Skeleton from 'react-skeleton-loader';
import { connect } from 'react-redux';
import { getUserId } from 'state/user/selectors';
import { getFictivePortfolios, getInstruments } from 'utils/fictivePortfolio';

import Portfolios from './Portfolios';
import Trade from './Trade';
import Menu from './menuButtons';

import styles from './FictivePortfolio.module.scss';

const FictivePortfolio = ({ userId }) => {
  const [showPortfolios, handleMenu] = useState(true);
  const [portfolios, setPortfolios] = useState([]);
  const [{ loading }, statusState] = useState({
    loading: true,
  });

  const fetchPortfolio = useCallback(async () => {
    if (!userId) return null;

    const result = await getFictivePortfolios(userId);
    if (!result.status.error) {
      statusState(result.status);
      const instruments = await getInstruments(result.portfolios);
      return setPortfolios(instruments);
    }
    return statusState(result.status);
  }, [userId]);

  useEffect(() => {
    fetchPortfolio();
  }, [fetchPortfolio]);

  function handleMenuChange() {
    handleMenu(!showPortfolios);
  }
  return (
    <div className={styles.FictivePortfolio}>
      <h2>Fiktiv portefølje</h2>
      <Menu viewPortfolio={showPortfolios} handleMenu={handleMenuChange} />
      <section>
        {loading && <Skeleton width={'350px'} height={'15px'} count={2} />}
        {showPortfolios ? (
          <Portfolios
            key={portfolios.instruments?.length}
            portfolios={portfolios}
            userId={userId}
            fetchPortfolio={fetchPortfolio}
          />
        ) : (
          <Trade
            portfolios={portfolios}
            userId={userId}
            fetchPortfolio={fetchPortfolio}
          />
        )}
      </section>
    </div>
  );
};

FictivePortfolio.propTypes = {
  userId: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({ userId: getUserId(state) });

export default connect(mapStateToProps)(FictivePortfolio);
