import styles from '../../Alarms.module.scss';
import useAlarm from 'state/alarm';
import { useFormState } from 'react-final-form';

const StatusField = () => {
  const { submitting, error } = useAlarm();
  const { submitSucceeded } = useFormState();

  return (
    <div className={styles.statusField}>
      {submitting && <p> Sender inn alarm...</p>}
      {submitSucceeded && !error && <p> Alarm opprettet!</p>}
      {error && <p> Noe gikk galt! : {error}</p>}
    </div>
  );
};

export default StatusField;
