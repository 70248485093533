import { sanitize } from 'dompurify';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';

import { logger } from '@oms/utils';
import { getCenterId } from 'state/user/selectors';
import { fetchBankFilteredSniplet } from 'utils/sniplets';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

const log = logger('components:BankStylesheet');

export const BankStylesheet = () => {
  const centerId = useSelector(getCenterId);
  const location = useLocation();
  const [stylesheetLink, setStylesheetLink] = useState<string>();

  useEffect(() => {
    const fetchAndSetStylesheet = async () => {
      try {
        const data = await fetchBankFilteredSniplet(centerId, 'stylesheet');

        if (!data || !data.bodies || !data.bodies.NO || !data.bodies.NO.body) {
          log(`Not setting stylesheet, no data found for centerId ${centerId}`);
          return;
        }

        // Sanitize and strip all the markup from the admin WYSIWIG editor
        const element = sanitize(data.bodies.NO.body, {
          RETURN_DOM_FRAGMENT: true,
        });
        const newStylesheetLink = element.textContent || undefined;
        log(`Parsed the stylesheet link as "${newStylesheetLink}"`);

        setStylesheetLink(newStylesheetLink);
      } catch (e) {
        log(`Not setting stylesheet, fetching threw ${e}`);
      }
    };

    fetchAndSetStylesheet();
  }, [centerId]);

  const shouldRender = useMemo(() => {
    if (!stylesheetLink) return false;
    if (location.pathname.includes('trader')) return false;
    return true;
  }, [location, stylesheetLink]);

  return (
    <Helmet>
      <link
        rel="stylesheet"
        crossOrigin="anonymous"
        referrerPolicy="no-referrer"
        href={shouldRender ? stylesheetLink : undefined}
      />
    </Helmet>
  );
};

export default BankStylesheet;
