import ImmutablePropTypes from 'react-immutable-proptypes';
import PropTypes from 'prop-types';
import TableHead from './TableHead';
import Row from './Row';
import TableFooter from './TableFooter';

export const HoldingsTable = ({ stocks, jawsItems }) => (
  <table className="table-base">
    <TableHead />
    <tbody>
      {stocks.map(stock => (
        <Row
          key={stock.ITEM}
          instrument={stock}
          item={jawsItems.find(
            jawsItem => jawsItem.get('ITEM_SECTOR') === stock.ITEM_SECTOR,
          )}
        />
      ))}
    </tbody>
    <TableFooter stocks={stocks} />
  </table>
);

HoldingsTable.propTypes = {
  stocks: PropTypes.arrayOf(
    PropTypes.shape({
      LASTNZ_DIV: PropTypes.number,
      CLOSE: PropTypes.number,
      LAST: PropTypes.number,
      POSITION_VOLUME: PropTypes.number,
      COST_BUY_PRICE: PropTypes.number,
    }),
  ),

  jawsItems: PropTypes.oneOfType([PropTypes.shape({}), ImmutablePropTypes.map]),
};

export default HoldingsTable;
