import PropTypes from 'prop-types';
import { formatNumber } from '@oms/utils';

const calcTotal = items =>
  items.reduce(
    (acc, { LASTNZ_DIV, CLOSE, LAST, POSITION_VOLUME, COST_BUY_PRICE }) => {
      const last = LASTNZ_DIV || CLOSE || LAST || 0;
      const MARKET_VALUE = POSITION_VOLUME * last;
      const UNREALIZED_PROFIT = MARKET_VALUE - COST_BUY_PRICE || 0;
      return acc + UNREALIZED_PROFIT;
    },
    0,
  );

const TableFooter = ({ stocks }) => (
  <tfoot>
    <tr>
      <th>Total</th>
      <td colSpan="4">{formatNumber(calcTotal(stocks), 2)}</td>
    </tr>
  </tfoot>
);

TableFooter.propTypes = {
  stocks: PropTypes.arrayOf(
    PropTypes.shape({
      LASTNZ_DIV: PropTypes.number,
      CLOSE: PropTypes.number,
      LAST: PropTypes.number,
      POSITION_VOLUME: PropTypes.number,
      COST_BUY_PRICE: PropTypes.number,
    }),
  ),
};

export default TableFooter;
