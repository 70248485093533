import classnames from 'classnames';

import Delay from 'components/other/Delay';
import styles from './Loading.module.scss';

const Loading = ({ cover = false, color = 'red' }) => (
  <Delay ms={300}>
    <div
      data-testid="Loading"
      className={classnames(styles.loading, styles[color], {
        [styles.cover]: cover,
      })}
    >
      <i />
    </div>
  </Delay>
);

export default Loading;
