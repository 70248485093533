import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Icon from '../Icons';
import styles from './Switch.module.scss';

const Switch = forwardRef(
  (
    {
      id,
      onLabel = 'PÅ',
      offLabel = 'AV',
      labeled = true,
      checked = false,
      onSwitch: handleSwitch,
      renderSwitchBallIcon,
      icon,
      withComponent = 'button',
      form,
      ...props // Enable passing things like disabled
    },
    ref,
  ) => {
    let ballIcon = null;
    if (icon) {
      ballIcon = <Icon icon={icon} />;
    } else if (renderSwitchBallIcon) {
      ballIcon = renderSwitchBallIcon;
    }
    const switchStateClass = checked ? 'switch-on' : 'switch-off';

    if (withComponent === 'input') {
      return (
        <div className={classNames('switch', styles.SwitchInputWrapper)}>
          <input
            ref={ref}
            id={id}
            type="checkbox"
            onChange={event => {
              form.change('openVolume', null);
              handleSwitch(event);
            }}
            defaultChecked={checked}
            {...props}
          />
          <div className={classNames(styles.Switch, switchStateClass)}>
            <div>
              <span>{labeled && checked ? onLabel : ''}</span>
              <div className="switch-ball">{ballIcon && ballIcon}</div>
              <span>{labeled && !checked ? offLabel : ''}</span>
            </div>
          </div>
        </div>
      );
    }
    return (
      <button
        ref={ref}
        id={id}
        className={classNames(styles.Switch, 'switch', switchStateClass)}
        role="switch"
        aria-checked={checked}
        onClick={handleSwitch}
        type="button"
        {...props}
      >
        <div>
          <span>{labeled && checked ? onLabel : ''}</span>
          <div className="switch-ball">{ballIcon && ballIcon}</div>
          <span>{labeled && !checked ? offLabel : ''}</span>
        </div>
      </button>
    );
  },
);

Switch.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  labeled: PropTypes.bool,
  onLabel: PropTypes.string,
  offLabel: PropTypes.string,
  onSwitch: PropTypes.func,
  checked: PropTypes.bool,
  renderSwitchBallIcon: PropTypes.func,
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  withComponent: PropTypes.oneOf(['button', 'input']),
  form: PropTypes.object,
};

export default Switch;
