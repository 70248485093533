import Media from 'react-media';
import { UpdatePageProvider, UpdatePageConsumer } from './Context';
import Button from '../Button';

import styles from './UpdatePage.module.scss';

const UpdatePage = () => (
  <Media query="(max-width: 360px)">
    {small => (
      <UpdatePageConsumer>
        {({ canStream, handleRefreshData }) => (
          <div className={styles.UpdatePage}>
            {!canStream && (
              <Button
                icon="sync-alt"
                onClick={handleRefreshData}
                clean
                small={small ? 'true' : 'false'}
                reverse
                data-type="refresh"
              >
                Oppdater
              </Button>
            )}
          </div>
        )}
      </UpdatePageConsumer>
    )}
  </Media>
);

export { UpdatePageConsumer, UpdatePageProvider };
export default UpdatePage;
