import { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getUserId, getCenterId } from 'state/user/selectors';
import * as UserActions from 'state/user/actions';

import {
  postCompetencyAssessmentStatus,
  deleteCompetencyAssessmentStatus,
} from 'utils/competencyAssessment';

import TestForm from './TestForm/Index';
import styles from './CompetencyAssessmentTest.module.scss';
import Results from './Results';
import ErrorMessage from 'components/other/ErrorMessage';
import Loading from 'components/other/Loading';

const CompetencyAssessmentTest = ({
  userId,
  centerId,
  handleToggle,
  fetchUserInfo,
}) => {
  const [submitState, setSubmitState] = useState('idle');
  const [submitError, setSubmitError] = useState(null);
  const [testResult, setTestResult] = useState(false);
  const [warningAcknowledged, setWarningAcknowledged] = useState(false);

  const assessTest = ({
    financeWorkExperience,
    unlistedTradesKnowledge,
    listedTradesKnowledge,
    subscriptionRightsKnowledge,
    etnTradesKnowledge,
  }) => {
    const workExperience = financeWorkExperience === 'true';
    const unlistedKnowledge =
      unlistedTradesKnowledge === 'medium' ||
      unlistedTradesKnowledge === 'high';
    const listedKnowledge =
      listedTradesKnowledge === 'medium' || listedTradesKnowledge === 'high';
    const subscriptionKnowledge =
      subscriptionRightsKnowledge === 'medium' ||
      subscriptionRightsKnowledge === 'high';
    const etnKnowledge =
      etnTradesKnowledge === 'medium' || etnTradesKnowledge === 'high';

    const workCriteria =
      workExperience &&
      unlistedKnowledge &&
      subscriptionKnowledge &&
      etnKnowledge;
    const experiencedCriteria =
      unlistedKnowledge &&
      listedKnowledge &&
      subscriptionKnowledge &&
      etnKnowledge;

    return workCriteria || experiencedCriteria;
  };

  const handleSubmit = result => {
    const assessmentResult = assessTest(result);
    setTestResult(assessmentResult ? 'COMPLETED_OK' : 'COMPLETED_DISCOURAGED');
  };

  const handleProceed = async () => {
    if (!warningAcknowledged && testResult === 'COMPLETED_DISCOURAGED') return;

    try {
      setSubmitState('pending');
      await deleteCompetencyAssessmentStatus(userId, centerId);
      await postCompetencyAssessmentStatus(userId, centerId, testResult);
      await handleToggle();
      fetchUserInfo(null, false);
      setSubmitState('success');
    } catch (error) {
      setSubmitState('error');
      setSubmitError(error);
      throw new Error(`Request failure: ${error}`);
    }
  };

  return (
    <section className={styles.CompetencyAssessmentTest}>
      <h2>Kunnskapstest</h2>
      <p>
        Siden det er første gang du bestiller et komplekst spareprodukt må vi
        stille deg noen spørsmål.
      </p>
      {testResult ? (
        <Results
          testResult={testResult}
          handleProceed={handleProceed}
          warningAcknowledged={warningAcknowledged}
          setWarningAcknowledged={setWarningAcknowledged}
          submitting={submitState === 'pending'}
        />
      ) : (
        <TestForm onSubmit={handleSubmit} />
      )}
      {submitState === 'pending' && <Loading />}
      {submitState === 'error' && (
        <ErrorMessage
          message="Kunne ikke lagre resultat"
          error={submitError}
          sanitizeError
        />
      )}
    </section>
  );
};

CompetencyAssessmentTest.propTypes = {
  userId: PropTypes.string.isRequired,
  centerId: PropTypes.string.isRequired,
  handleToggle: PropTypes.func.isRequired,
  fetchUserInfo: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  userId: getUserId(state),
  centerId: getCenterId(state),
  assessmentTests: state.assessmentTests,
});

export { CompetencyAssessmentTest };
export default connect(mapStateToProps, UserActions)(CompetencyAssessmentTest);
