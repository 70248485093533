import { Component } from 'react';
import PropTypes from 'prop-types';
import { dropdownOptions } from 'utils/accountSelect';
import { ModalConsumer } from 'components/Modal';
import TradeForm from './TradeForm';

export class Trade extends Component {
  static propTypes = {
    accounts: PropTypes.object.isRequired,
    selectedUserId: PropTypes.string.isRequired,
    instrument: PropTypes.object.isRequired,
    handleSelectType: PropTypes.func.isRequired,
    userMaySell: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  };

  render() {
    const {
      instrument,
      accounts,
      selectedUserId,
      handleSelectType,
      userMaySell,
    } = this.props;

    let filteredAccounts = accounts;

    if (userMaySell.length && instrument.tradeType === 'SELL') {
      filteredAccounts = filteredAccounts.filter(account =>
        userMaySell.find(
          maySellAccount => maySellAccount.account.id === account.get('id'),
        ),
      );
    }
    const anonymizedOwnerId = accounts
      .find(item => item.get('ownerId') === selectedUserId)
      ?.get('anonymizedOwnerId');

    const options = dropdownOptions(filteredAccounts);

    const instrumentWithHolding = {
      ...instrument,
      holdingVolume: userMaySell?.values?.POSITION_VOLUME,
    };

    return (
      <ModalConsumer>
        {({ handleToggle }) => (
          <TradeForm
            key={instrument.ITEM}
            selectedUserId={selectedUserId}
            anonymizedOwnerId={anonymizedOwnerId}
            accountPickerOptions={options}
            instrument={instrumentWithHolding}
            handleToggle={handleToggle}
            handleSelectType={handleSelectType}
          />
        )}
      </ModalConsumer>
    );
  }
}

export default Trade;
