import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import Sidebar from './Sidebar';
import { SidebarProvider, SidebarConsumer, useSidebar } from './Context';

const mapStateToProps = state => ({
  instrument: state.trader.get('instrument'),
});

export { SidebarProvider, SidebarConsumer, useSidebar };

export default compose(withRouter, connect(mapStateToProps))(Sidebar);
