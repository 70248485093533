import { WATCHLIST_URL } from 'constants/Jaws';
import { post, del, get } from 'utils/fetch';
import doubleEncode from 'utils/doubleEncode';

export const addToWatchlist = ({ userId, watchlist, itemSector }) => {
  const watchlistName = doubleEncode(watchlist);
  const ticker = doubleEncode(itemSector);
  return post(`${WATCHLIST_URL}/${userId}/${watchlistName}/${ticker}`);
};

export const removeFromWatchlist = ({ userId, watchlist, itemSector }) => {
  const watchlistName = doubleEncode(watchlist);
  const ticker = doubleEncode(itemSector);

  return del(`${WATCHLIST_URL}/${userId}/${watchlistName}/${ticker}`);
};

export const getWatchlist = ({ userId, watchlist }) => {
  const watchlistName = doubleEncode(watchlist);
  return get(`${WATCHLIST_URL}/${userId}/${watchlistName}`);
};
