import { lazy } from 'react';
import moment from 'moment';
import { Switch, Link, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import locale from 'moment/locale/nb';
import { I18nProvider } from '@lingui/react';
import noCatalog from '@oms/components-i18n/locale/no/messages';
import {
  Provider as ComponentsContext,
  defaultContextValues,
} from '@oms/components-context';
import { WatchlistProvider } from 'state/watchlist';
import { ActiveOrdersProvider } from 'state/activeOrders';
import Redirect from 'components/other/Redirect';
import LazyRoute, { SavingsRobotRoute } from 'components/other/LazyRoute';
import ScrollToTop from 'utils/scrollToTop.js';

import 'react-day-picker/lib/style.css';
import 'react-quill/dist/quill.snow.css';
import 'styles/application.scss';
import 'font-awesome/css/font-awesome.min.css';

import configureStore from './store/configureStore';
import { logOutSession } from './utils/user';

const UploadFile = lazy(() => import('components/admin/Conversion/Upload'));
const TwoFactorLoginPage = lazy(() =>
  import('containers/admin/TwoFactorLoginPage'),
);
const AdminLoginPage = lazy(() => import('containers/admin/LoginPage'));

const AdminChangePassword = lazy(() =>
  import('containers/admin/ChangePasswordPage'),
);
const CustomerListPage = lazy(() =>
  import('containers/admin/CustomerListPage'),
);
const CustomerNewPage = lazy(() => import('containers/admin/CustomerNewPage'));
const UserListPage = lazy(() => import('containers/admin/UserListPage'));
const UserExportPage = lazy(() => import('containers/admin/UserExportPage'));
const UserNewPage = lazy(() => import('containers/admin/UserNewPage'));
const UserEditPage = lazy(() => import('containers/admin/UserEditPage'));
const InstrumentPage = lazy(() => import('containers/admin/InstrumentPage'));
const AnalysisItemPage = lazy(() =>
  import('containers/admin/AnalysisItemPage'),
);
const AnalysisEditPage = lazy(() =>
  import('containers/admin/AnalysisEditPage'),
);
const AnalysisListPage = lazy(() =>
  import('containers/admin/AnalysisListPage'),
);
const AuditReportPage = lazy(() => import('containers/admin/AuditReportPage'));
const AuditSearchPage = lazy(() => import('containers/admin/AuditSearchPage'));
const AuditStatisticsPage = lazy(() =>
  import('containers/admin/AuditStatisticsPage'),
);
const AuditLivePage = lazy(() => import('containers/admin/AuditLivePage'));
const BankFunctionalityPage = lazy(() =>
  import('containers/admin/BankFunctionalityPage'),
);

const FAQPage = lazy(() => import('containers/admin/FAQPage'));
const SnipletsPage = lazy(() => import('containers/admin/SnipletsPage'));
const SnipletEditPage = lazy(() => import('containers/admin/SnipletEditPage'));
const TraderOverviewEditPage = lazy(() =>
  import('containers/admin/TraderOverviewEditPage'),
);
const AdminRoute = lazy(() => import('components/other/AdminRoute'));
const PendingSignature = lazy(() =>
  import('components/admin/Conversion/PendingSignature'),
);
const ConversionList = lazy(() =>
  import('components/admin/Conversion/ConversionList'),
);
const Reminder = lazy(() => import('components/admin/Conversion/Reminder'));

const store = configureStore();

const catalogs = {
  no: noCatalog,
};

const contextValues = {
  ...defaultContextValues,
  Link,
};

// Load norwegian locale
moment.updateLocale('nb', locale);
moment.locale('nb');

export default function Routes() {
  return (
    <I18nProvider language="no" catalogs={catalogs}>
      <ComponentsContext value={contextValues}>
        <Provider store={store}>
          <WatchlistProvider>
            <ActiveOrdersProvider>
              <ScrollToTop>
                <Switch>
                  <Route
                    path="/logout"
                    component={() => {
                      logOutSession();
                      return null;
                    }}
                  />
                  <Redirect exact from="/" to="/dashboard" />
                  <LazyRoute
                    privateRoute
                    exact
                    path="/ui"
                    component="TemporaryUI"
                  />
                  <LazyRoute
                    privateRoute
                    exact
                    path="/accountInformation"
                    component="AccountInformationPage"
                  />
                  <LazyRoute
                    privateRoute
                    exact
                    path="/mailbox"
                    component="MailboxPage"
                  />
                  <LazyRoute
                    privateRoute
                    path="/pension"
                    component="PensionDetailsPage"
                  />
                  <LazyRoute
                    privateRoute
                    exact
                    path="/moneyTransfer"
                    component="MoneyTransferPage"
                  />
                  <LazyRoute
                    privateRoute
                    exact
                    path="/dashboard"
                    component="DashboardPage"
                  />
                  <LazyRoute
                    privateRoute
                    exact
                    path="/dashboard/account/equities"
                    component="EquitiesPage"
                  />
                  <LazyRoute
                    privateRoute
                    exact
                    path="/dashboard/account/historic"
                    component="HistoricEquitiesPage"
                  />
                  <LazyRoute
                    privateRoute
                    path="/savings-agreement"
                    component="CreateSavingsAgreementPage"
                  />
                  <LazyRoute
                    privateRoute
                    path="/dashboard/account/:account"
                    component="AccountDetailsPage"
                  />
                  <LazyRoute
                    privateRoute
                    path="/trader"
                    component="TraderPage"
                  />
                  <LazyRoute
                    privateRoute
                    path="/fundlist"
                    component="FundListPage"
                  />
                  <Redirect exact from="/trade" to="/trade/buy" />
                  <LazyRoute
                    privateRoute
                    path="/transactions"
                    component="TransactionsPage"
                  />
                  <LazyRoute
                    path="/account-setup"
                    component="AccountSetupPage"
                  />
                  <LazyRoute
                    path="/terms-agreements"
                    component="TermsAndAgreementsStockPage"
                  />
                  <LazyRoute path="/no-accounts" component="NoAccountsPage" />
                  <LazyRoute
                    privateRoute
                    path="/ips-setup"
                    component="IPSSetupPage"
                  />
                  <LazyRoute
                    privateRoute
                    path="/vps-setup"
                    component="VPSSetupPage"
                  />
                  <LazyRoute
                    privateRoute
                    path="/trade/:type/complete/:orders/:kId?"
                    component="TradeCompletePage"
                  />
                  <LazyRoute
                    privateRoute
                    path="/trade/:type/:action/:orderId?"
                    component="TradeConfirmPage"
                  />
                  <LazyRoute
                    privateRoute
                    path="/trade/:type"
                    component="TradeFundsPage"
                  />
                  <LazyRoute
                    privateRoute
                    path="/transactions"
                    component="TransactionsPage"
                  />
                  <LazyRoute
                    privateRoute
                    noWrapper
                    path="/yearly-tax-report/:year?"
                    component="YearlyTaxReportPage"
                  />
                  <LazyRoute
                    path="/realize-ask/:accountId"
                    component="RealizeAskPage"
                  />
                  <LazyRoute
                    privateRoute
                    path="/deposit-ask/:accountId"
                    component="DepositAskPage"
                  />
                  <LazyRoute
                    privateRoute
                    path="/funds-cost"
                    component="PositionCostsPage"
                  />
                  <LazyRoute
                    noWrapper
                    path="/conversion/login"
                    component="ConversionLoginPage"
                  />
                  <LazyRoute
                    privateRoute
                    path="/conversion/form"
                    component="ConversionFormPage"
                  />
                  <LazyRoute
                    noWrapper
                    path="/conversion/pdf"
                    component="ConversionPdfPage"
                  />

                  <LazyRoute
                    privateRoute
                    path="/conversion/landingpage"
                    component="SigningLandingPage"
                  />

                  <Redirect exact from="/move" to="/move/step/0" />
                  <LazyRoute
                    privateRoute
                    path="/move"
                    component="MoveStockPage"
                  />

                  <LazyRoute
                    privateRoute
                    path="/externalMove"
                    component="MoveExternalStockPage"
                  />
                  <LazyRoute
                    exact
                    privateRoute
                    path="/switchfunds/:accountType?/:step?"
                    component="SwitchFundsPage"
                  />
                  <LazyRoute
                    privateRoute
                    path="/switchfunds/details/:accountId/:orderId"
                    component="SwitchFundsDetailsPage"
                  />
                  <LazyRoute
                    noWrapper
                    path="/licenses"
                    component="LicensesPage"
                  />
                  <LazyRoute
                    noWrapper
                    path="/system-error/:type"
                    component="SystemErrorPage"
                  />
                  <SavingsRobotRoute
                    noWrapper
                    path="/savings-robot/:step"
                    component="SavingsRobot"
                  />
                  <LazyRoute
                    privateRoute
                    noWrapper
                    path="/electronic-identification"
                    component="ElectronicIdentificationPage"
                  />
                  <LazyRoute
                    privateRoute
                    noWrapper
                    path="/aml/welcome"
                    component="AML/IntroductionPage"
                  />
                  <LazyRoute
                    privateRoute
                    noWrapper
                    path="/aml/verify/:step"
                    component="AML/VerifyPage"
                  />
                  <LazyRoute
                    privateRoute
                    noWrapper
                    path="/aml/pending"
                    component="AML/PendingPage"
                  />
                  <LazyRoute
                    privateRoute
                    noWrapper
                    path="/aml/rejected"
                    component="AML/RejectedPage"
                  />
                  <LazyRoute
                    privateRoute
                    noWrapper
                    path="/aml/error"
                    component="AML/ErrorPage"
                  />
                  <LazyRoute
                    noWrapper
                    path="/logged-out"
                    component="LoggedOutMessagePage"
                  />
                  <LazyRoute noWrapper path="/login" component="LoginPage" />

                  <AdminRoute
                    path="/admin/analysis/new"
                    component={AnalysisItemPage}
                  />
                  <AdminRoute
                    exact
                    path="/admin/editor/sniplets/bank-functionality"
                    component={BankFunctionalityPage}
                  />

                  <AdminRoute
                    exact
                    path="/admin/editor/sniplets/faq"
                    component={FAQPage}
                  />
                  <AdminRoute
                    path="/admin/analysis/edit/:reportId"
                    component={AnalysisEditPage}
                  />
                  <AdminRoute
                    path="/admin/analysis"
                    component={AnalysisListPage}
                  />
                  <AdminRoute
                    path="/admin/users/list"
                    component={UserListPage}
                  />
                  <AdminRoute
                    path="/admin/users/export"
                    component={UserExportPage}
                  />
                  <AdminRoute path="/admin/users/new" component={UserNewPage} />
                  <AdminRoute
                    path="/admin/users/:userId/edit"
                    component={UserEditPage}
                  />
                  <AdminRoute
                    exact
                    path="/admin/customers"
                    component={CustomerListPage}
                  />
                  <AdminRoute
                    exact
                    path="/admin/customers/new/:ssn?"
                    component={CustomerNewPage}
                  />
                  <AdminRoute
                    path="/admin/instrument/recommended"
                    component={InstrumentPage}
                  />
                  <AdminRoute
                    path="/admin/instrument/metadata/:itemSector?"
                    component={InstrumentPage}
                  />
                  <AdminRoute
                    path="/admin/instrument/available"
                    component={InstrumentPage}
                  />
                  <AdminRoute
                    exact
                    path="/admin/editor/sniplets"
                    component={SnipletsPage}
                  />
                  <AdminRoute
                    path="/admin/editor/bank/:centerId/sniplets/:id/edit"
                    component={SnipletEditPage}
                  />
                  <AdminRoute
                    path="/admin/editor/sniplets/trader-overview"
                    component={TraderOverviewEditPage}
                  />
                  <AdminRoute
                    path="/admin/audit/search"
                    component={AuditSearchPage}
                  />
                  <AdminRoute
                    path="/admin/audit/report"
                    component={AuditReportPage}
                  />
                  <AdminRoute
                    path="/admin/audit/statistics"
                    component={AuditStatisticsPage}
                  />
                  <AdminRoute
                    path="/admin/audit/live"
                    component={AuditLivePage}
                  />
                  <Route
                    path="/admin/change-password"
                    component={AdminChangePassword}
                  />
                  <AdminRoute
                    path="/admin/conversion/reminder"
                    component={Reminder}
                  />
                  <Route
                    exact
                    path="/admin/conversion"
                    render={() => <Redirect to="/admin/conversion/upload" />}
                  />
                  <AdminRoute
                    exact
                    path="/admin/conversion/upload"
                    component={UploadFile}
                  />
                  <AdminRoute
                    path="/admin/conversion/PendingSignature"
                    component={PendingSignature}
                  />
                  <AdminRoute
                    path="/admin/conversion/ConversionList"
                    component={ConversionList}
                  />

                  <Route
                    exact
                    path="/admin"
                    render={() => <Redirect to="/admin/customers" />}
                  />
                  <Route
                    exact
                    path="/admin/editor"
                    render={() => <Redirect to="/admin/editor/sniplets" />}
                  />
                  <Route
                    exact
                    path="/admin/instrument"
                    render={() => (
                      <Redirect to="/admin/instrument/recommended" />
                    )}
                  />
                  <Route
                    exact
                    path="/admin/users"
                    render={() => <Redirect to="/admin/users/list" />}
                  />
                  <Route
                    exact
                    path="/admin/audit"
                    render={() => <Redirect to="/admin/audit/search" />}
                  />
                  <Route
                    path="/admin/login/two-factor"
                    component={TwoFactorLoginPage}
                  />
                  <Route path="/admin/login" component={AdminLoginPage} />

                  <LazyRoute noWrapper path="*" component="NotFoundPage" />
                </Switch>
              </ScrollToTop>
            </ActiveOrdersProvider>
          </WatchlistProvider>
        </Provider>
      </ComponentsContext>
    </I18nProvider>
  );
}
