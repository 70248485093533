const mapType = (type: string) => {
  const holdingsTypes: { [key: string]: string } = {
    SHARES: 'Aksjer',
    PRIMARY_CAPITAL_CERTIFICATES: 'Aksjer',
    FUNDS: 'Fond',
    EXCHANGE_TRADED_FUNDS: 'ETF-er',
    EXCHANGE_TRADED_NOTES: 'ETN-er',
  };

  return holdingsTypes[type] || 'Andre';
};

export const calculateCashinDepot = (balances: []) => {
  return balances?.reduce((accumulator: number, currentValue: any) => {
    const validAccount = currentValue?.get('account')?.get('deposit');
    const AVAIL_BALANCE_FUND =
      (validAccount &&
        currentValue?.get('balance')?.get('AVAIL_BALANCE_FUND')) ||
      0;
    return accumulator + AVAIL_BALANCE_FUND;
  }, 0);
};

type Values = {
  INSTRUMENT_TYPE: string;
  POSITION_VOLUME: number;
  LASTNZ_DIV: number;
  PRICE: number;
  COST_BUY_PRICE: number;
};

export const calculatePortfolioValues = ({
  INSTRUMENT_TYPE,
  POSITION_VOLUME,
  LASTNZ_DIV,
  PRICE,
  COST_BUY_PRICE,
}: Values) => {
  const MARKET_VALUE = POSITION_VOLUME * (LASTNZ_DIV || PRICE || 0);
  const unrealizedProfit = MARKET_VALUE - COST_BUY_PRICE;
  const unrealizedProfitPct =
    COST_BUY_PRICE && unrealizedProfit
      ? (unrealizedProfit / COST_BUY_PRICE) * 100
      : 0;
  return {
    type: INSTRUMENT_TYPE,
    name: mapType(INSTRUMENT_TYPE),
    marketValue: MARKET_VALUE,
    unrealizedProfit,
    unrealizedProfitPct,
    costPrice: COST_BUY_PRICE,
  };
};

type Items = {
  marketValue: number;
  unrealizedProfit: number;
  costPrice: number;
};

export const calculateMarketValues = (groupedItems: Items[], name: string) => {
  const unrealizedProfit = groupedItems.reduce(
    (a, b) => a + b.unrealizedProfit,
    0,
  );
  const costPrice = groupedItems.reduce((a, b) => a + b.costPrice, 0);
  const unrealizedProfitPct =
    unrealizedProfit && costPrice ? (unrealizedProfit / costPrice) * 100 : null;

  return {
    name,
    marketValue: groupedItems.reduce((a, b) => a + b.marketValue, 0),
    unrealizedProfit,
    unrealizedProfitPct,
  };
};

export const portfolioColumns = [
  'CHANGE',
  'CHANGE_PCT',
  'CLOSE',
  'COST_BUY_PRICE',
  'DATE',
  'GICS_CODE',
  'INFORMATION_RATIO_1YEAR,MANAGEMENTFEE',
  'INSTRUMENT_TYPE',
  'ISIN',
  'ITEM',
  'ITEM_SECTOR',
  'LAST',
  'LASTNZ_DIV',
  'LONG_NAME',
  'MARKET_VALUE',
  'MARKET_VALUE_TODAY',
  'PORTFOLIO_RETURN',
  'POSITION_VOLUME',
  'PRICE',
  'PRICECHANGEPCT',
  'REALIZED_PROFIT',
  'RET1M',
  'RETGAVG10YR',
  'RETGAVG1YR',
  'RETGAVG3YR',
  'RETGAVG5YR',
  'RETGAVGYTD',
  'RETY2D',
  'SECTOR',
  'SECURITYNAME',
  'SHARPE_RATIO_1YEAR',
  'TIME',
  'TRADE_TIME',
  'UNREALIZED_PROFIT',
  'VOLUME',
  'contributed.PRODUCT_URL',
].join();
