import Immutable from 'immutable';
import { createSelector } from 'reselect';

import calculateSums from 'utils/calculateSums';
import {
  calculateCashinDepot,
  calculatePortfolioValues,
  calculateMarketValues,
} from './utils';

const getHoldingsFilter = state => state.holdings;
const getBalanceFilter = state => state.balance;

const getPortfolios = createSelector([getHoldingsFilter], holdings =>
  holdings.get('portfolios'),
);

const getBalances = createSelector([getBalanceFilter], balance =>
  balance.get('portfolios'),
);

const calculateHoldings = (portfolios, balances) => {
  const result = portfolios.reduce(
    (prev, portfolio) => {
      const cashInDepot =
        (portfolio.account.deposit &&
          balances?.size &&
          balances
            ?.get(portfolio.account.id)
            ?.get('balance')
            ?.get('WITHDRAWAL_BALANCE')) ||
        0;
      const balanceFetchError = balances
        ?.get(portfolio.account.id)
        ?.get('error');

      const portfolioSum = calculateSums(portfolio.positions, cashInDepot);

      return {
        // Calculate total market value and cost price
        marketValue: prev.marketValue + portfolioSum.marketValue,
        costPrice: prev.costPrice + portfolioSum.costPrice,
        realizedProfit: prev.realizedProfit + portfolioSum.realizedProfit,
        cashInDepot: prev.cashInDepot + portfolioSum.cashInDepot,
        balanceFetchError,
        unrealizedProfit: prev.unrealizedProfit + portfolioSum.unrealizedProfit,
        marketValueWithCash:
          prev.marketValueWithCash + portfolioSum.marketValueWithCash,
        accounts: [...prev.accounts, { ...portfolio.account, ...portfolioSum }],
      }; // Calculate a summary of each account
    },
    {
      cashInDepot: 0,
      marketValue: 0,
      marketValueWithCash: 0,
      costPrice: 0,
      realizedProfit: 0,
      unrealizedProfit: 0,
      accounts: [],
    },
  );

  result.unrealizedProfitPct =
    result.unrealizedProfit && result.costPrice
      ? (result.unrealizedProfit / result.costPrice) * 100
      : null;
  return result;
};

export const getHoldingsFetchState = createSelector(
  [getHoldingsFilter],
  holdings => holdings.get('isFetching'),
);

export const getHoldingsSummary = createSelector(
  [getPortfolios, getBalances],
  (portfolios, balances) => {
    if (!portfolios) return null;

    return calculateHoldings(portfolios, balances);
  },
);

export const getAskHoldings = createSelector(
  [getPortfolios, getBalances],
  (portfolios, balances) => {
    if (!portfolios) return null;

    return calculateHoldings(
      portfolios.filter(portfolio => portfolio.account.ask),
      balances,
    );
  },
);

export const getIpsHoldings = createSelector(
  [getPortfolios, getBalances],
  (portfolios, balances) => {
    if (!portfolios) return null;

    return calculateHoldings(
      portfolios.filter(portfolio => portfolio.account.ips),
      balances,
    );
  },
);

export const getEquityHoldings = createSelector([getPortfolios], portfolios => {
  if (!portfolios) return null;

  return calculateHoldings(
    portfolios.filter(
      portfolio => !portfolio.account.ask && !portfolio.account.ips,
    ),
  );
});

export const getFunds = createSelector([getPortfolios], portfolios =>
  portfolios.reduce((prev, portfolio) => {
    if (!portfolio || !portfolio.positions) return prev;

    return [
      ...prev,
      ...portfolio.positions
        .filter(position => position.values.INSTRUMENT_TYPE === 'FUNDS')
        .map(position => ({
          ...position,
          account: portfolio.account,
        })),
    ];
  }, []),
);

export const getFundsSummary = createSelector([getFunds], funds =>
  calculateSums(funds),
);

export const getStocks = createSelector([getPortfolios], portfolios =>
  portfolios.reduce((prev, portfolio) => {
    if (!portfolio || !portfolio.positions) return prev;

    return [
      ...prev,
      ...portfolio.positions
        .filter(
          position =>
            position.values.INSTRUMENT_TYPE !== 'FUNDS' &&
            position.values.POSITION_VOLUME !== 0,
        )
        .map(position => ({
          ...position,
          account: portfolio.account,
        })),
    ];
  }, []),
);

export const getStocksSummary = createSelector([getStocks], stocks =>
  calculateSums(stocks),
);

export const getDistribution = createSelector(
  [getPortfolios, getBalances],
  (portfolios, balances) => {
    if (!portfolios.size && !balances.size) return [];

    const cashDepot = calculateCashinDepot(balances);
    const data = portfolios
      ?.reduce((result, portfolio) => {
        if (!portfolio || !portfolio.positions) return result;
        return result.push(
          ...portfolio.positions
            .filter(item => item.values.POSITION_VOLUME)
            .map(item => calculatePortfolioValues(item?.values)),
        );
      }, Immutable.List())
      .filter(item => item.type)
      .groupBy(item => item.name)
      .map((groupedItems, name) => calculateMarketValues(groupedItems, name))
      .toList()
      .toJS();

    if (cashDepot) {
      data.push({
        name: 'Kontanter',
        marketValue: cashDepot,
        unrealizedProfitPct: 0,
      });
    }

    return data;
  },
);
