import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import { NumberInput } from 'utils/useBlockWheel';
import styles from '../Trade.module.scss';

export const Volume = ({ required }) => (
  <Field name="volume" validate={required}>
    {({ input, meta }) => (
      <div>
        <label htmlFor="volume">Antall aksjer</label>
        <NumberInput
          {...input}
          placeholder="Antall"
          name="volume"
          type="number"
        />
        {meta.error && meta.submitFailed && (
          <span className={styles.formError}>{meta.error}</span>
        )}
      </div>
    )}
  </Field>
);

Volume.propTypes = {
  required: PropTypes.func.isRequired,
};

export default Volume;
