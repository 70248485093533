import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Search from 'components/Trader/Search';
import { SidebarConsumer } from 'components/Sidebar';
import { needTradeTest } from 'utils/trade';
import { getStocks, getHoldingsSummary } from 'state/holdings/selectors';

import {
  hasLevel2,
  getUserId,
  getSelectedUserId,
  getcompetencyTestResult,
  getcompetencyTestTimestamp,
  getStockTradingAccounts,
} from 'state/user/selectors';
import * as HoldingsActions from 'state/holdings';

import Trade from './Trade';
import OwnerStatus from './OwnerStatus';
import InstrumentTitle from './InstrumentTitle';
import BuySellButtons from './BuySellButtons';
import OrderDepth from './OrderDepth';
import InstrumentOverview from './InstrumentOverview';
import styles from './TradeForm.module.scss';
import CompetencyButton from './CompetencyButton';
import ActiveOrder from './ActiveOrder';

class TradeInstrument extends Component {
  static propTypes = {
    accounts: PropTypes.object.isRequired,
    userId: PropTypes.string.isRequired,
    instrument: PropTypes.object,
    stocks: PropTypes.array,
    selectedUserId: PropTypes.string.isRequired,
    testResult: PropTypes.bool.isRequired,
    testTimestamp: PropTypes.bool.isRequired,
    handleToggle: PropTypes.func.isRequired,
    fetchHoldings: PropTypes.func.isRequired,
    level2: PropTypes.bool.isRequired,
  };

  state = {
    selected: {},
    mayTradeInstrument: true,
  };

  componentDidMount() {
    this.props.fetchHoldings();
    this.verifyCompetancy();
  }

  componentDidUpdate(prevProps) {
    if (this.props.instrument.ITEM !== prevProps.instrument.ITEM) {
      this.verifyCompetancy();
    }

    if (this.props.testResult !== prevProps.testResult) {
      this.verifyCompetancy();
    }
  }

  verifyCompetancy = () => {
    const { instrument, testResult, testTimestamp, handleToggle } = this.props;
    const showTest = needTradeTest(
      testResult,
      testTimestamp,
      instrument.INSTRUMENT_TYPE,
      instrument.SECTOR,
    );
    this.setState({ mayTradeInstrument: !showTest });

    if (showTest) return handleToggle();

    return false;
  };

  render() {
    const {
      instrument,
      userId,
      accounts,
      selectedUserId,
      stocks,
      level2,
      handleToggle,
    } = this.props;
    const { tradeType } = instrument;

    const itemSector =
      instrument?.ITEM &&
      instrument?.SECTOR &&
      `${instrument.ITEM}.${instrument.SECTOR}`;

    const userMaySell = stocks?.filter(
      item => item.values.ITEM === instrument?.ITEM,
    );

    const instrumentWithAccount = {
      ...instrument,
      accountSelected: instrument.accountSelected || userMaySell?.account?.id,
    };

    return (
      <SidebarConsumer>
        {({ handleInstrument, handleSuggestSelected, handleSelectType }) => (
          <div className={styles.TradeForm}>
            <section className={styles.FormHeader}>
              {instrument ? (
                <InstrumentTitle
                  instrument={instrument}
                  handleInstrument={handleInstrument}
                />
              ) : (
                <Search
                  className={styles.TradeFormSearch}
                  inputProps={{
                    placeholder: 'Søk ticker/selskap...',
                    autoFocus: true,
                  }}
                  onSuggestionSelected={handleSuggestSelected}
                />
              )}
            </section>

            {instrument && (
              <>
                <InstrumentOverview selected={instrument} />
                <OrderDepth itemSector={itemSector} hasLevel2={level2} />
                {!instrument.orderId && (
                  <BuySellButtons
                    orderId={instrument.orderId}
                    handleInstrument={handleInstrument}
                    instrument={instrument}
                  />
                )}

                <OwnerStatus
                  userMaySell={userMaySell}
                  tradeType={tradeType}
                  instrument={instrument}
                />

                {instrument.orderId && <ActiveOrder instrument={instrument} />}
                {((userMaySell && tradeType === 'SELL') ||
                  tradeType === 'BUY') &&
                  this.state.mayTradeInstrument && (
                    <Trade
                      key={`${instrument.ITEM}${tradeType}`}
                      instrument={instrumentWithAccount}
                      accounts={accounts}
                      userId={userId}
                      selectedUserId={selectedUserId}
                      handleSelectType={handleSelectType}
                      userMaySell={userMaySell}
                    />
                  )}
                <CompetencyButton
                  competency={this.state.mayTradeInstrument}
                  handleToggle={handleToggle}
                />
              </>
            )}
          </div>
        )}
      </SidebarConsumer>
    );
  }
}

const mapStateToProps = state => ({
  userId: getUserId(state),
  testResult: getcompetencyTestResult(state),
  testTimestamp: getcompetencyTestTimestamp(state),
  accounts: getStockTradingAccounts(state),
  selectedUserId: getSelectedUserId(state),
  stocks: getStocks(state),
  holdingsSummary: getHoldingsSummary(state),
  level2: hasLevel2(state),
});

export default connect(mapStateToProps, HoldingsActions)(TradeInstrument);
