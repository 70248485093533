import PropTypes from 'prop-types';
import classNames from 'classnames';
import Icon from '../index';
import styles from './AnimatedDropDown.module.scss';

const AnimatedDropDown = ({ isOpen, size = 'lg' }) => (
  <Icon
    size={size}
    icon="caret-down"
    className={classNames(styles.AnimatedDropDown, { [styles.isOpen]: isOpen })}
  />
);

AnimatedDropDown.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  size: PropTypes.string,
};

export default AnimatedDropDown;
