import PropTypes from 'prop-types';

import styles from '../Alarms.module.scss';

export const AlarmTypeChooser = ({ newsAlarm, handleAlarmTypeChange }) => (
  <div className={styles.textWithToggle}>
    <button
      disabled={!newsAlarm}
      aria-pressed={!newsAlarm}
      onClick={handleAlarmTypeChange}
    >
      Markedsalarm
    </button>
    <button
      disabled={newsAlarm}
      aria-pressed={newsAlarm}
      onClick={handleAlarmTypeChange}
    >
      Nyhetsalarm
    </button>
    <div />
  </div>
);

AlarmTypeChooser.propTypes = {
  newsAlarm: PropTypes.bool,
  handleAlarmTypeChange: PropTypes.func,
};

export default AlarmTypeChooser;
