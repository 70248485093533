import { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Collapse } from 'react-collapse';

import Icon from 'components/other/Icon';
import styles from './HelpText.module.scss';

export default class HelpText extends Component {
  static propTypes = {
    title: PropTypes.node.isRequired,
    children: PropTypes.node.isRequired,
    startExpanded: PropTypes.bool,
    className: PropTypes.string,
  };

  static defaultProps = {
    startExpanded: false,
  };

  state = {
    expanded: this.props.startExpanded,
  };

  handleExpand = () => {
    this.setState({ expanded: !this.state.expanded });
  };

  render() {
    const { title, children, className } = this.props;
    const { expanded } = this.state;

    return (
      <div className={classNames('HelpText', className, styles.HelpText)}>
        <button type="button" onClick={this.handleExpand} aria-hidden="true">
          <Icon
            type="fa-caret-right"
            className={classNames(styles.caret, {
              'fa-rotate-90': expanded,
            })}
          />
          {title}
        </button>
        <Collapse isOpened={expanded} aria-expanded={expanded}>
          <button
            type="button"
            onClick={this.handleExpand}
            tabIndex={expanded ? 0 : -1}
          >
            lukk
          </button>
          {children}
        </Collapse>
      </div>
    );
  }
}
