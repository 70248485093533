import {
  formatNumber as number,
  decimalPlaces,
  formatInteger,
} from '@oms/utils';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Trend } from '@oms/components';

const Row = ({ instrument, item }) => {
  const { ITEM, SECTOR, POSITION_VOLUME, COST_BUY_PRICE } = instrument;
  const { LASTNZ_DIV, LAST, CLOSE, CHANGE_PCT } = item?.toJS() || {};

  const last = LASTNZ_DIV || CLOSE || LAST;
  const marketValue = POSITION_VOLUME * last;
  const unrealizedProfit = marketValue - COST_BUY_PRICE;

  return (
    <tr>
      <td>
        <Link to={`/trader/instrument/${ITEM}.${SECTOR}`}>{ITEM}</Link>
      </td>
      <td>{formatInteger(POSITION_VOLUME)}</td>
      <td>{number(last, decimalPlaces(last))}</td>
      <td>
        <Trend value={CHANGE_PCT}>{number(CHANGE_PCT)}%</Trend>
      </td>
      <td>
        <Trend value={unrealizedProfit}>{number(unrealizedProfit)}</Trend>
      </td>
    </tr>
  );
};

Row.propTypes = {
  instrument: PropTypes.shape({
    ITEM: PropTypes.string,
    SECTOR: PropTypes.string,
    POSITION_VOLUME: PropTypes.number,
    COST_BUY_PRICE: PropTypes.number,
  }),
  item: PropTypes.shape({
    toJS: PropTypes.func.isRequired,
  }),
};

export default Row;
