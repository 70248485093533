import PropTypes from 'prop-types';
import { Button } from 'components/other/Button';
import styles from './TradeForm.module.scss';

const CompetencyButton = ({ competency, handleToggle }) => {
  if (competency) return null;
  return (
    <div className={styles.infoMessage}>
      <p>
        Dette er et komplekst spareprodukt og krever kompetansetest for å
        handles.
      </p>
      <Button
        className={styles.competencyTestButton}
        onClick={() => handleToggle()}
      >
        Ta kompetansetest
      </Button>
    </div>
  );
};

CompetencyButton.propTypes = {
  competency: PropTypes.bool,
  handleToggle: PropTypes.func,
};

export default CompetencyButton;
