import PropTypes from 'prop-types';
import { getIncidentId } from 'utils/errorHandling';
import Box from 'components/other/Box';
import Accordion from '../Accordion';

/* eslint-disable react/no-array-index-key */
const errorText = {
  NO_COVERAGE: 'Du har ikke dekning på kontoen din',
  DUPLICATE_TRANSACTION: 'Stoppet på grunn av duplisert transaksjon',
};

const probrokerErrors = {
  4024: 'Du må være myndig.',
  4025: 'Du kan ikke selge flere andeler enn du eier.',
  4053: 'Det er registrert reskontropostering på kunden.',
  // 4057: 'Vi greier dessverre ikke å hente saldoen din akkurat nå, men jobber med å rette dette så raskt vi kan.',
};

export const ErrorMessage = ({
  error,
  message,
  hideDetails = false,
  sanitizeError = false,
  ...props
}) => {
  if (!error) return null;

  let parsedError = error;
  try {
    parsedError = JSON.parse(error);
  } catch (_) {
    // Not JSON, try as Error
    if (error instanceof Error) {
      parsedError = error.message;
    }
  }

  const getErrorDetails = () => {
    if (Array.isArray(parsedError.errors)) {
      return (
        <ul>
          {parsedError.errors.map((innerError, index) => {
            const { detail, code, backendErrorCode } = innerError;
            let text = errorText[detail] || detail;

            // NOR-1119 Trying to figure out who that manages to send agreement without bankaccount and how
            if (backendErrorCode === 6005) {
              text = detail;
            }
            if (code === 'PROBROKER_ERROR') {
              text = probrokerErrors[backendErrorCode] || text;
            }

            return <li key={index}>{text}</li>;
          })}
        </ul>
      );
    }

    if (sanitizeError) return `${getIncidentId(parsedError)}`;

    if (parsedError?.data)
      return `${errorText[parsedError.data] || parsedError.data}`;

    return `${parsedError}`;
  };

  if (hideDetails) {
    return (
      <Box {...props} type="error" role="alert">
        <Accordion header={message && <p>{message}</p>}>
          {getErrorDetails()}
        </Accordion>
      </Box>
    );
  }

  return (
    <Box {...props} type="error" role="alert">
      {message && <p>{message}</p>}
      {getErrorDetails()}
    </Box>
  );
};

ErrorMessage.propTypes = {
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  message: PropTypes.string,
  hideDetails: PropTypes.bool,
};

export default ErrorMessage;
