import { Suspense, useRef, useContext } from 'react';
import classNames from 'classnames';
import Skeleton from 'react-skeleton-loader';

import useKeyPress from 'utils/useKeypress';
import useOutsideClick from './useOutsideClick';
import useLockBodyScroll from 'utils/useLockBodyScroll';

import Delay from 'components/other/Delay';
import { ModalConsumer } from 'components/Modal';

import Button from '../other/Button';
import Holdings from '../Trader/Sidebar/Holdings';
import ActiveOrders from '../Trader/Sidebar/ActiveOrders';
import Favorites from '../Trader/Sidebar/Favorites';
import TradeInstrument from '../Trader/Sidebar/TradeInstrument';
import FictivePortfolio from '../Trader/Sidebar/FictivePortfolio';
import Alarms from '../Trader/Sidebar/Alarms';

import { SidebarContext } from './Context';
import {
  TRADER,
  ORDERS,
  HOLDINGS,
  FAVORITES,
  ALARMS,
  FICTIVE_PORTFOLIO,
} from './Context/types';
import styles from './Sidebar.module.scss';

const Loader = props => (
  <div className={styles.loader}>
    <Delay ms={300}>
      <Skeleton count={6} width="100%" borderRadius="0" {...props} />
    </Delay>
  </div>
);

const Sidebar = () => {
  const ref = useRef(null);
  const sidebarContext = useContext(SidebarContext);
  const {
    activeType,
    sidebarOpen,
    toggleSidebar,
    instrument,
    mq,
  } = sidebarContext;

  // 27: Key Code for 'ESC'
  useKeyPress(27, () => {
    const activeInput = document.activeElement.tagName === 'INPUT';
    if (sidebarOpen && !activeInput && !mq.popSidebar) {
      toggleSidebar(false);
    }
  });
  useOutsideClick(ref, () => {
    if (sidebarOpen && !mq.popSidebar) {
      toggleSidebar(false);
    }
  });

  // We only want to lock body scrolling when the sidebar is layered on top of the main content
  const isSmall = mq.tablet || mq.small;
  useLockBodyScroll(sidebarOpen && !mq.popSidebar && !isSmall);

  if (mq.small) return null;
  let sidebarContent = null;

  if (activeType === TRADER) {
    sidebarContent = (
      <ModalConsumer>
        {({ handleToggle }) => (
          <TradeInstrument
            instrument={instrument}
            handleToggle={handleToggle}
          />
        )}
      </ModalConsumer>
    );
  } else if (activeType === ORDERS) {
    sidebarContent = <ActiveOrders />;
  } else if (activeType === HOLDINGS) {
    sidebarContent = <Holdings />;
  } else if (activeType === FAVORITES) {
    sidebarContent = <Favorites />;
  } else if (activeType === ALARMS) {
    sidebarContent = <Alarms />;
  } else if (activeType === FICTIVE_PORTFOLIO) {
    sidebarContent = <FictivePortfolio />;
  }

  return (
    <div
      id="sidebar"
      className={classNames({
        [styles.Sidebar]: true,
        [styles.sidebarOpen]: sidebarOpen,
      })}
    >
      {sidebarOpen && !mq.small && (
        <Button
          onClick={() => toggleSidebar(false)}
          aria-label="lukk"
          icon="times"
        />
      )}
      <Suspense fallback={<Loader />}>
        <div ref={ref}>{sidebarContent}</div>
      </Suspense>
    </div>
  );
};

export default Sidebar;
