import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import { NumberInput } from 'utils/useBlockWheel';

import Switch from 'components/other/Switch';
import HelpText from 'components/other/HelpText';
import Icon from 'components/other/Icon';
import { browserIsEdge } from 'utils/browser';

import styles from '../../../TradeForm.module.scss';

export const SellForm = ({ form, values, disableField }) => (
  <>
    <div className={styles.textWithToggle}>
      <span>
        <HelpText className={styles.helpText} title={<h3>Stop loss</h3>}>
          Ved å sette stop loss vil salgsorderen først bli lagt ut i markedet
          når prisen faller under valgt nivå.
        </HelpText>
        <Icon icon="info-circle" />
      </span>
      <Field
        name="useTriggerCriterion"
        render={({ disabled, input: { onChange, value } }) => (
          <Switch
            onSwitch={onChange}
            checked={value}
            withComponent="input"
            disabled={disabled}
            labeled={'true'}
            type="checkbox"
            name="switch"
            form={form}
          />
        )}
        disabled={disableField}
      />
    </div>
    {values.useTriggerCriterion && (
      <Field name="stopLossLimit">
        {({ meta, input }) => (
          <div>
            <label htmlFor="stopLossLimit">Trigger</label>
            <NumberInput
              {...input}
              name="stopLossLimit"
              component="input"
              type={browserIsEdge() ? 'text' : 'number'}
              placeholder="Stop loss limit/pris"
            />
            {meta.error && meta.touched && (
              <span className={styles.formError}>{meta.error}</span>
            )}
          </div>
        )}
      </Field>
    )}
  </>
);

SellForm.propTypes = {
  form: PropTypes.object,
  values: PropTypes.object,
  disableField: PropTypes.object,
};

export default SellForm;
