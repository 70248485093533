import Immutable from 'immutable';
import { logger } from '@oms/utils';
import { createSelector } from 'reselect';

const log = logger('selectors:orders');
const getOrdersFilter = state => state.orders;

const getPortfolios = createSelector([getOrdersFilter], orders =>
  orders.get('portfolios'),
);

/** Both "ordinary" orders and switches */
export const getActiveOrders = createSelector([getPortfolios], portfolios =>
  portfolios.reduce((result, portfolio, portfolioId) => {
    const ordersError = portfolio.getIn(['orders', 'error']);
    const switchesError = portfolio.getIn(['switches', 'error']);

    if (ordersError) {
      log(
        `Not selecting active orders from portfolio ${portfolioId} due to error: ${ordersError}`,
      );
    }

    if (switchesError) {
      log(
        `Not selecting active switches from  ${portfolioId} due error: ${switchesError}`,
      );
    }

    const orders = !ordersError
      ? portfolio
          .get('orders')
          .map(order => order.set('account', portfolio.get('account')))
      : [];

    const switches = !switchesError
      ? portfolio
          .get('switches')
          .map(s => s.set('account', portfolio.get('account')))
      : [];

    return result.concat(orders).concat(switches);
  }, Immutable.List()),
);

export const getNumberOfActiveFundOrders = createSelector(
  [getActiveOrders],
  orders => orders.filter(order => !order.get('INSTRUMENT_NAME')).size,
);
