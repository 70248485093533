// This is a duck experiment.. Quack! https://github.com/erikras/ducks-modular-redux
import Immutable from 'immutable';
import cookie from 'js-cookie';
import INITIAL_FUND_PRICE from 'constants/initialFundPrice';

import { getCartItems } from './selectors';

const ADD_TO_CART = 'norne/cart/ADD_TO_CART';
const REINITIALIZE_CART = 'norne/cart/REINITIALIZE_CART';
const REMOVE_FROM_CART = 'norne/cart/REMOVE_FROM_CART';
const EMPTY_CART = 'norne/cart/EMPTY_CART';

const getInitialState = () => cookie.getJSON('cart');

const initialState = Immutable.fromJS({
  items: Immutable.Map(getInitialState()),
});

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case ADD_TO_CART:
      return state.update('items', items => {
        const { ITEM_SECTOR, SECURITYNAME, LONG_NAME } = action.item;

        // First, insert the new item
        const result = items.set(ITEM_SECTOR, {
          itemSector: ITEM_SECTOR,
          name: SECURITYNAME || LONG_NAME || ITEM_SECTOR,
          amount: INITIAL_FUND_PRICE,
        });

        return result;
      });
    case REINITIALIZE_CART:
      return state.set('items', Immutable.Map(action.items));
    case REMOVE_FROM_CART:
      return state.update('items', items => {
        const result = items.remove(action.item);
        return result;
      });
    case EMPTY_CART:
      return state.set('items', Immutable.Map());
    default:
      return state;
  }
}

const updateCookie = state => {
  const cart = getCartItems(state);
  cookie.set('cart', cart, { expires: 365000 });
};

export const addToCart = item => (dispatch, getState) => {
  dispatch({ type: ADD_TO_CART, item });
  updateCookie(getState());
};

export const reinitializeCart = () => dispatch => {
  const items = cookie.getJSON('cart');
  dispatch({ type: REINITIALIZE_CART, items });
};

export const removeFromCart = item => (dispatch, getState) => {
  dispatch({ type: REMOVE_FROM_CART, item });
  updateCookie(getState());
};

export const emptyCart = () => (dispatch, getState) => {
  dispatch({ type: EMPTY_CART });
  updateCookie(getState());
};
