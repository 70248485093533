import { Field } from 'react-final-form';
import Radio from 'components/other/Radio';
import Icon from 'components/other/Icons';

import styles from './TestForm.module.scss';

const EtnTradesKnowledgeField = field => (
  <div className={styles.FieldContainer} tabIndex="0">
    <p>Kunnskap om ETN-er:</p>
    <Field name="etnTradesKnowledge" type="radio" value="low">
      {({ input }) => (
        <label htmlFor="etnTradesKnowledge_low">
          <Radio
            id="etnTradesKnowledge_low"
            type="radio"
            value={input.value}
            checked={input.checked}
            onChange={input.onChange}
          />
          Ingen
        </label>
      )}
    </Field>
    <Field name="etnTradesKnowledge" type="radio" value="medium">
      {({ input }) => (
        <label htmlFor="etnTradesKnowledge_medium">
          <Radio
            id="etnTradesKnowledge_medium"
            type="radio"
            value={input.value}
            checked={input.checked}
            onChange={input.onChange}
          />
          Middels
        </label>
      )}
    </Field>
    <Field name="etnTradesKnowledge" type="radio" value="high">
      {({ input }) => (
        <label htmlFor="etnTradesKnowledge_high">
          <Radio
            id="etnTradesKnowledge_high"
            type="radio"
            value={input.value}
            checked={input.checked}
            onChange={input.onChange}
          />
          Høy
        </label>
      )}
    </Field>
    {field.meta.error && field.meta.touched && (
      <div className={styles.errorContainer}>
        <Icon icon="exclamation-triangle" />
        <span>{field.meta.error}</span>
      </div>
    )}
  </div>
);

export default EtnTradesKnowledgeField;
