import { Component } from 'react';
import PropTypes from 'prop-types';
import { JawsComponent } from '@oms/jaws-react';
import { connect } from 'react-redux';
import * as HoldingsActions from 'state/holdings';
import * as UserActions from 'state/user/actions';
import { getStocks } from 'state/holdings/selectors';
import { fetchAllBalances } from 'utils/user';
import { getUserId, getStockTradingAccounts } from 'state/user/selectors';
import { getHoldingsSpec, mapStocksToAccount } from './util';

import Summary from './Summary';
import AccountHeading from './AccountHeading';
import HoldingsTable from './HoldingsTable';

import styles from './Holdings.module.scss';

export class Holdings extends Component {
  static propTypes = {
    fetchHoldings: PropTypes.func.isRequired,
    setSelectedAccount: PropTypes.func.isRequired,
    stocks: PropTypes.array,
    accounts: PropTypes.object,
    userId: PropTypes.string,
  };

  state = {
    totalBalance: 0,
  };

  componentDidMount() {
    const { accounts, userId, fetchHoldings, setSelectedAccount } = this.props;
    setSelectedAccount('ALL');
    this.fetchBalances(accounts, userId);
    fetchHoldings();
    this.updateInterval = setInterval(() => {
      fetchHoldings();
      this.fetchBalances(accounts, userId);
    }, 30000);
  }

  componentWillUnmount() {
    clearInterval(this.updateInterval);
  }

  fetchBalances = async (accounts, userId) => {
    try {
      const totalBalance = await fetchAllBalances(userId, accounts);
      this.setState({ totalBalance, balanceFailed: false });
    } catch (error) {
      this.setState({ balanceFailed: true });
    }
  };

  render() {
    const { stocks } = this.props;
    const { totalBalance, balanceFailed } = this.state;
    const accounts = mapStocksToAccount(stocks);

    return (
      <JawsComponent spec={getHoldingsSpec(stocks)}>
        {({ items: jawsItems }) => (
          <div className={styles.Holdings}>
            <div className={styles.Heading}>
              <h2>Min beholdning</h2>
            </div>
            <Summary
              className={styles.Summary}
              balance={totalBalance}
              balanceFailed={balanceFailed}
            />
            {stocks?.length === 0 ? (
              <p className={styles.HoldingsNotFound}>Fant ingen beholdning</p>
            ) : (
              accounts.map(account => (
                <div key={account.account.id}>
                  <AccountHeading account={account.account} />
                  <HoldingsTable
                    stocks={account.stocks}
                    jawsItems={jawsItems}
                  />
                  <div className={styles.Divider} />
                </div>
              ))
            )}
          </div>
        )}
      </JawsComponent>
    );
  }
}

const mapStateToProps = state => ({
  stocks: getStocks(state),
  accounts: getStockTradingAccounts(state),
  userId: getUserId(state),
});

export default connect(mapStateToProps, {
  ...HoldingsActions,
  ...UserActions,
})(Holdings);
