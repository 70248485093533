import moment from 'moment';
import { normalizeNumber, formatNumber, formatInteger } from '@oms/utils';
import {
  validateLimit,
  validateOpenVolume,
} from 'utils/traderValidation/trade';
import { getAmountOfValidDaysForOrder } from 'utils/trade';

const MAX_ORDER_VALUE = 5_000_000;

export function validateDate(date, days) {
  const maxDate = moment()
    .add(days, 'days')
    .endOf('day');
  if (moment(date).isAfter(maxDate)) {
    return `Du kan ikke velge mer en ${days} dager fremover`;
  }
  return undefined;
}

export async function validateBuy(values) {
  try {
    const {
      limit,
      oldTotal,
      volume,
      calendar,
      accountSelected,
      hiddenVolume,
      openVolume,
      limitRules,
      commission,
    } = values;
    const errors = {};
    if (limit === undefined) {
      errors.limit = 'Mangler pris ';
    } else if (limit && limitRules) {
      errors.limit = validateLimit(limit, limitRules.data);
    } else if (limit === null && volume) {
      errors.limit = 'Feil i inntasting limit/pris ';
    }

    if (!volume || volume <= 0 || normalizeNumber(volume) % 1 !== 0) {
      errors.volume = 'Antall må være et heltall, større enn 0 ';
    }

    if (!accountSelected) {
      errors.accountSelected = 'Velg en konto du vil handle på ';
    }

    if (accountSelected?.balance !== undefined && volume && limit) {
      const normalizedLimit = normalizeNumber(limit);
      const normalizedVolume = normalizeNumber(volume);
      const orderValue = normalizedLimit * normalizedVolume;
      const totalPrice = orderValue + commission;
      const balance = !accountSelected?.balance
        ? 0
        : formatNumber(accountSelected?.balance);

      if (orderValue > MAX_ORDER_VALUE) {
        errors.volume =
          'Maks ordrestørrelse i nettløsningen er 5 millioner. Større ordre må splittes i mindre ordre';
      }

      const positiveBalance =
        oldTotal !== 0
          ? accountSelected.balance + oldTotal - totalPrice > 0
          : true;

      if (totalPrice > accountSelected.balance && !oldTotal) {
        errors.accountSelected = `Totalt estimert beløp: ${formatInteger(
          totalPrice,
        )} kr er større enn saldo: ${balance} kr `;
      }
      if (!positiveBalance) {
        errors.accountSelected = `Nytt totalt beløp: ${formatInteger(
          totalPrice,
        )} kr er større enn saldo: ${formatNumber(
          accountSelected.balance + oldTotal,
        )} kr `;
      }
    }

    if (hiddenVolume && openVolume) {
      errors.openVolume = await validateOpenVolume(values);
    }

    const validDays = getAmountOfValidDaysForOrder(
      'BUY',
      accountSelected?.paymentAgent,
    );
    errors.calendar = validateDate(calendar, validDays);

    return errors;
  } catch (error) {
    return { accountSelected: 'Teknisk feil i valideringen av ordre' };
  }
}

export async function validateSell(values) {
  try {
    const {
      accountSelected,
      calendar,
      hiddenVolume,
      limit,
      limitRules,
      openVolume,
      useTriggerCriterion,
      stopLossLimit,
      volume,
      holdingVolume,
    } = values;

    const errors = {};

    if (limit === undefined) {
      errors.limit = 'Mangler pris ';
    } else if (limit && limitRules) {
      errors.limit = validateLimit(limit, limitRules.data);
    } else if (limit === null && volume) {
      errors.limit = 'Feil i inntasting limit/pris ';
    }

    if (!volume || volume <= 0 || normalizeNumber(volume) % 1 !== 0) {
      errors.volume = 'Antall må være et heltall, større enn 0 ';
    }

    const normalizedLimit = normalizeNumber(limit);
    const normalizedVolume = normalizeNumber(volume);
    const orderValue = normalizedLimit * normalizedVolume;

    if (orderValue > MAX_ORDER_VALUE) {
      errors.volume =
        'Maks ordrestørrelse i nettløsningen er 5 millioner. Større ordre må splittes i mindre ordre';
    }

    if (volume && holdingVolume < volume) {
      errors.volume = `Du kan ikke selge mer enn du eier. ( ${formatNumber(
        holdingVolume,
        0,
      )} )`;
    }

    if (!accountSelected) {
      errors.accountSelected = 'Velg en konto du vil handle på';
    }

    if (useTriggerCriterion) {
      if (!stopLossLimit) {
        errors.stopLossLimit =
          'Du må velge en triggerpris, hvis du skal ha stop loss';
      }

      if (normalizeNumber(limit) > normalizeNumber(stopLossLimit)) {
        errors.stopLossLimit = `Trigger kan ikke være lavere enn limit (Pris per aksje)`;
      }
    }

    if (hiddenVolume && openVolume) {
      errors.openVolume = await validateOpenVolume(values);
    }

    const validDays = getAmountOfValidDaysForOrder(
      'SELL',
      accountSelected?.paymentAgent,
    );
    errors.calendar = validateDate(calendar, validDays);

    return errors;
  } catch (error) {
    return { accountSelected: 'Feil i valideringen av ordre' };
  }
}

const validate = values => {
  switch (values.tradeType) {
    case 'BUY':
      return validateBuy(values);
    case 'SELL':
      return validateSell(values);
    default:
      return {
        type: 'Unknown type',
      };
  }
};

export default validate;
