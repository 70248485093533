import { Fragment } from 'react';
import PropTypes from 'prop-types';

const HightlightQueryMatch = ({ text = '', query }) => {
  const regex = new RegExp(query, 'gi');
  const match = text.match(regex);
  if (!match) return <span>{text}</span>;
  const split = text.split(match);
  return (
    <span>
      {split.map((part, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <Fragment key={`${part}-${i}`}>
          {part}
          {i !== split.length - 1 && (
            <em className="suggestion-query-match-highlight">{match}</em>
          )}
        </Fragment>
      ))}
    </span>
  );
};

HightlightQueryMatch.propTypes = {
  text: PropTypes.string,
  query: PropTypes.string.isRequired,
};

export default HightlightQueryMatch;
