import { Field } from 'react-final-form';
import Radio from 'components/other/Radio';
import Icon from 'components/other/Icons';

import styles from './TestForm.module.scss';

const ListedTradesAmountField = field => (
  <div className={styles.FieldContainer} tabIndex="0">
    <p>
      Antall handler siste 12 mnd i <b>noterte</b> finansielle instrumenter
    </p>
    <Field name="listedTradesAmount" type="radio" value="none">
      {({ input }) => (
        <label htmlFor="listedTradesAmount_none">
          <Radio
            id="listedTradesAmount_none"
            type="radio"
            value={input.value}
            checked={input.checked}
            onChange={input.onChange}
          />
          Ingen
        </label>
      )}
    </Field>
    <Field name="listedTradesAmount" type="radio" value="lessThan10">
      {({ input }) => (
        <label htmlFor="listedTradesAmount_lessThan10">
          <Radio
            id="listedTradesAmount_lessThan10"
            type="radio"
            value={input.value}
            checked={input.checked}
            onChange={input.onChange}
          />
          Færre enn 10
        </label>
      )}
    </Field>
    <Field name="listedTradesAmount" type="radio" value="moreThan10">
      {({ input }) => (
        <label htmlFor="listedTradesAmount_moreThan10">
          <Radio
            id="listedTradesAmount_moreThan10"
            type="radio"
            value={input.value}
            checked={input.checked}
            onChange={input.onChange}
          />
          Flere enn 10
        </label>
      )}
    </Field>
    {field.meta.error && field.meta.touched && (
      <div className={styles.errorContainer}>
        <Icon icon="exclamation-triangle" />
        <span>{field.meta.error}</span>
      </div>
    )}
  </div>
);

export default ListedTradesAmountField;
