import { useContext } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { WatchlistContext } from 'state/watchlist';
import Icon from 'components/other/Icons';
import FavoritesTable from './FavoritesTable';

import styles from './Favorites.module.scss';

export const watchlistName = 'watchlist';

export const Favorites = () => {
  const { watchlist, handleWatchlist } = useContext(WatchlistContext);

  if (watchlist.length < 1) {
    return (
      <section className={classNames('Favorites', styles.Favorites)}>
        <h3>Ingen favoritter</h3>
        <p>
          Det ser ikke ut som om du har noen favoritter enda. I{' '}
          <Link to="/trader/quotelist">kurslisten</Link> kan du trykke på{' '}
          <Icon icon="star" /> for å velge deg ut noen favoritter du ønsker å
          følge ekstra nøye med på.
        </p>
      </section>
    );
  }

  return (
    <section className={classNames('Favorites', styles.Favorites)}>
      <FavoritesTable watchlist={watchlist} handleWatchlist={handleWatchlist} />
    </section>
  );
};

export default Favorites;
