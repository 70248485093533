import React, { ReactNode, useState } from 'react';
import { Collapse } from 'react-collapse';

import AnimatedDropDown from '../Icons/AnimatedDropDown';

import styles from './Accordion.module.scss';

type AccordionProps = {
  header: ReactNode;
  children: ReactNode;
  defaultExpanded?: boolean;
};

const Accordion = ({ header, children, defaultExpanded }: AccordionProps) => {
  const [isOpen, setIsOpen] = useState(defaultExpanded || false);

  const toggleExpanded = () => setIsOpen(!isOpen);

  return (
    <section className={styles.accordion}>
      <header onClick={toggleExpanded}>
        <button
          className={styles.closeButton}
          onClick={toggleExpanded}
          type="button"
        >
          <AnimatedDropDown isOpen={isOpen} size={'lg'} />
        </button>
        {header}
      </header>
      <Collapse isOpened={isOpen}>
        <div className={styles.collapsable}>{children}</div>
      </Collapse>
    </section>
  );
};

export default Accordion;
