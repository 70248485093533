import { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './JumpLink.module.scss';

class JumpLink extends Component {
  static propTypes = {
    targetDomId: PropTypes.string,
    text: PropTypes.string,
    className: PropTypes.string,
    props: PropTypes.shape({}),
  };

  static defaultProps = {
    text: 'Hopp til hovedinnhold',
    targetDomId: 'main',
  };

  onClick = () => {
    const { targetDomId } = this.props;
    const target = document.getElementById(targetDomId);
    /* move focus to first focusable element in tree */
    const focusable = target.querySelectorAll(
      'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])',
    );
    if (focusable.length !== 0) {
      focusable[0].focus();
    } else {
      target.focus();
    }
  };

  render() {
    const { targetDomId, text, className, ...props } = this.props;
    return (
      <a
        onClick={this.onClick}
        href={`#${targetDomId}`}
        className={classNames('jump-link', className, styles.JumpLink)}
        {...props}
      >
        {text}
      </a>
    );
  }
}

JumpLink.displayName = 'JumpLink';

export default JumpLink;
