// This is a duck experiment.. Quack! https://github.com/erikras/ducks-modular-redux
import Immutable from 'immutable';

const SET_TAX_DETAIL = 'norne/tax/SET_TAX_DETAIL';

const initialState = Immutable.fromJS({
  selection: {},
});

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_TAX_DETAIL:
      return state.set('selection', Immutable.Map(action.selection));
    default:
      return state;
  }
}

export const setTaxDetail = selection => dispatch => {
  dispatch({ type: SET_TAX_DETAIL, selection });
};
