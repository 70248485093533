import { Component } from 'react';
import classNames from 'classnames';
import Icon from 'components/other/Icon';
import styles from './ScrollToTopButton.module.scss';

export class ScrollToTopButton extends Component {
  state = {
    showScrollButton: '',
  };

  componentDidMount() {
    this.scrollEventListener();
  }

  componentWillUnmount() {
    this.setState({ showScrollButton: null });
    window.removeEventListener('scroll', this.scroll);
  }

  scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  scrollEventListener = () => {
    this.setState({ showScrollButton: 'showScrollButtonHidden' });
    this.scroll = window.addEventListener('scroll', () => {
      const scrolled = window.scrollY;

      /* eslint-disable no-unused-expressions */
      Math.ceil(scrolled) > 300
        ? this.setState({ showScrollButton: 'showScrollButton' })
        : this.setState({ showScrollButton: 'showScrollButtonHidden' });
    });
  };

  render() {
    const { showScrollButton } = this.state;
    return (
      <button
        title="Back to top"
        className={classNames({
          [styles.showScrollButtonHidden]:
            showScrollButton === 'showScrollButtonHidden',
          [styles.ToTheTopButton]: 'goToTop',
          [styles.displacedButton]: window.location.pathname.startsWith(
            '/savings-agreement',
          ),
        })}
        onClick={() => {
          this.scrollToTop();
        }}
      >
        <Icon type="fa-angle-double-up" />
        <span className="sr-only">Til toppen</span>
      </button>
    );
  }
}

export default ScrollToTopButton;
