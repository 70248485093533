import { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { FORM_ERROR } from 'final-form';
import jawsComponent from '@oms/jaws-react-compat';
import { normalizeNumber } from '@oms/utils';
import { connect } from 'react-redux';
import { Form, Field } from 'react-final-form';
import { compose } from 'redux';
import Skeleton from 'react-skeleton-loader';

import {
  getOrder,
  executeStockOrder,
  validateStockOrder,
  updateStockOrder,
} from 'utils/trade';

import {
  getUserId,
  getcompetencyTestResult,
  getcompetencyTestTimestamp,
} from 'state/user/selectors';
import ErrorMessage from 'components/other/ErrorMessage';
import Switch from 'components/other/Switch';
import Icon from 'components/other/Icon';
import HelpText from 'components/other/HelpText';
import validate from './validate';
import calculate from './calculate';

import Limit from './Fields/Limit';
import Volume from './Fields/Volume';
import OpenVolume from './Fields/OpenVolume';
import AccountPicker from './Fields/AccountPicker';
import SellForm from './Fields/SellForm';
import Calendar from './Fields/Calendar';
import OrderSummary from './OrderSummary';
import Receipt from './OrderReceipt';

import styles from '../../TradeForm.module.scss';
import AccountBalance from './AccountBalance';
import TradeFormButtons from './TradeFormButtons';
import { SidebarConsumer } from 'components/Sidebar';

export class TradeForm extends Component {
  static propTypes = {
    accounts: PropTypes.object.isRequired,
    accountPickerOptions: PropTypes.array,
    anonymizedOwnerId: PropTypes.string.isRequired,
    closeRules: PropTypes.object,
    competencyTestResult: PropTypes.shape({}),
    competencyTestTimestamp: PropTypes.shape({}),
    handleSelectType: PropTypes.func.isRequired,
    instrument: PropTypes.object.isRequired,
    limitRules: PropTypes.object,
    selectedUserId: PropTypes.string.isRequired,
    items: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      order: '',
      date: moment(),
      currentAskAccount: false,
      initialValues: {
        userId: props.userId,
        accountSelected:
          props.instrument.accountSelected ||
          (props.accountPickerOptions.length === 1
            ? props.accountPickerOptions[0]
            : ''),
        bankAccount: '',
        closeRules: props.instrument.tradeRules?.closeRules,
        hiddenVolume: props.instrument.hiddenVolume || false,
        item: props.instrument.ITEM,
        limit: props.instrument?.limit || null,
        oldTotal: props.instrument?.oldTotal || 0,
        limitRules: props.instrument.tradeRules?.limitRules,
        lotSizeRules: props.instrument.tradeRules?.lotSizeRules,
        openVolume:
          props.instrument.volume - props.instrument.hiddenVolume || '',
        calendar: props.instrument?.EXPIRATION_DATE || moment(),
        volume: props.instrument?.volume,
        sector: props.instrument.SECTOR,
        selectedUserId: props.selectedUserId,
        tradableASKinstrument: null,
        tradeType: props.instrument?.tradeType,
        orderId: props.instrument?.orderId,
        useOpenVolume: false,
        useTriggerCriterion: props.instrument?.useTriggerCriterion || false,
        stopLossLimit: props.instrument?.stopLossLimit || null,
      },
    };
  }

  onSubmit = async values => {
    const { anonymizedOwnerId } = this.props;

    const {
      item,
      sector,
      limit,
      volume,
      accountSelected,
      openVolume,
      hiddenVolume,
      bankAccount,
      useTriggerCriterion,
      stopLossLimit,
      orderId,
      tradeType,
    } = values;
    try {
      const payload = {
        accountId: accountSelected?.value || accountSelected,
        bankAccount,
        expirationDate: moment(values.calendar).format('YYYY-MM-DD'),
        instrumentAccount: 'NO_ACCOUNT',
        exchange: sector,
        limit: normalizeNumber(limit),
        totalVolume: volume,
        openVolume,
        orderType: 'normal',
        ticker: item,
        tradeType,
        useOpenVolume: hiddenVolume,
        useTriggerCriterion,
        stopLossLimit: useTriggerCriterion
          ? normalizeNumber(stopLossLimit)
          : undefined,
        triggerCriterion: useTriggerCriterion ? 'lt' : undefined,
      };
      this.setState({ payload });

      let executeResult;

      if (orderId) {
        executeResult = await updateStockOrder(
          anonymizedOwnerId,
          orderId,
          payload,
        );
      } else {
        const validateOrderResult = await validateStockOrder(
          anonymizedOwnerId,
          payload,
        );

        if (validateOrderResult.ok) {
          const validationStatus = await validateOrderResult.json();

          if (validationStatus.data.OK) {
            executeResult = await executeStockOrder(anonymizedOwnerId, payload);
          } else {
            // I couldn't get a good answer for which code/message i can expect, so just checking for the specific one I encountered
            const investmentPolicyError =
              validationStatus?.data?.ERROR_CODE === 93 ||
              validationStatus?.data?.ERROR_MESSAGE ===
                'Instrumentet finnes ikke i kundens investmentpolicy';

            if (investmentPolicyError)
              return {
                [FORM_ERROR]:
                  'Instrumentet du forsøker å handle er ikke støttet på denne kontoen. Handler du på en bedriftskonto så trenger du LEI. Handler du på en aksjesparekonto må instrumentet være godkjent for dette',
              };

            return {
              [FORM_ERROR]:
                validationStatus?.data?.ERROR_MESSAGE || 'Ukjent feil',
            };
          }
        } else {
          return this.handleError(validateOrderResult);
        }
      }

      if (executeResult.ok) {
        const orderData = await executeResult.json();
        const { ORDER_ID, CUSTOMER_ID } = orderData.data;
        // Do a "best attempt" to ensure we get the up-to-date status
        // after submitting an order based on hueristics
        setTimeout(() => this.getOrder(ORDER_ID, CUSTOMER_ID), 1000);
        setTimeout(() => this.getOrder(ORDER_ID, CUSTOMER_ID), 4000);
        setTimeout(() => this.getOrder(ORDER_ID, CUSTOMER_ID), 10000);
      } else {
        /* eslint-disable consistent-return */
        return this.handleError(executeResult);
      }
    } catch (error) {
      return { [FORM_ERROR]: error };
    }

    // Wait/sleep 1 second before returning to form to prevent showing empty receipt
    await new Promise(resolve => setTimeout(resolve, 1000));
    return true;
  };

  getOrder = async (orderId, account) => {
    const { anonymizedOwnerId } = this.props;
    const result = await getOrder(orderId, account, anonymizedOwnerId);

    if (!result.ok) {
      return { [FORM_ERROR]: `${result.status} - ${result.statusText}` };
    }

    const order = await result.json();
    this.setState({ order });
  };

  handleError = async (error = {}) => {
    const text = await error.text();
    try {
      const details = JSON.parse(text);
      const errorDetails = details?.errors?.map(err => err.detail);
      const errorStatus =
        error.status ||
        error.data?.ERROR_STATUS ||
        error.data?.ERROR_CODE ||
        '';
      const errorMessage = error.statusText || error.data?.ERROR_MESSAGE || '';
      return {
        [FORM_ERROR]: `${errorStatus} - ${errorMessage} - ${errorDetails?.join() ||
          ''}`,
      };
    } catch (e) {
      return { [FORM_ERROR]: text || error.statusText || 'Ukjent feil' };
    }
  };

  render() {
    const {
      accountPickerOptions,
      instrument,
      accounts,
      handleSelectType,
      items,
    } = this.props;

    if (!items?.size) return null;
    const { initialValues, order } = this.state;

    const isEditOrder = instrument?.orderId !== undefined;
    const destructJawsData = items.toJS();
    const jawsData = destructJawsData[Object.keys(destructJawsData)[0]];
    const last =
      jawsData?.LAST ||
      jawsData?.LASTNZ_DIV ||
      jawsData?.LASTNZ ||
      jawsData?.CLOSE ||
      0;

    return (
      <Form
        onSubmit={this.onSubmit}
        validate={validate}
        decorators={[calculate]}
        initialValues={initialValues}
        render={({
          handleSubmit,
          submitError,
          values,
          submitting,
          submitSucceeded,
          form,
          pristine,
        }) => {
          // NOR-3592 Only allow edit of date if limit and/or volume is also edited
          const allowEditDate =
            !isEditOrder ||
            normalizeNumber(values.limit) !== initialValues.limit ||
            normalizeNumber(values.volume) !== initialValues.volume;
          const askAccount = values.accountSelected?.ask;
          const SELL = instrument.tradeType === 'SELL';
          if (
            instrument.tradeType === 'BUY' &&
            typeof values.accountSelected === 'string'
          ) {
            // eslint-disable-next-line no-param-reassign
            delete values.accountSelected;
          }

          return (
            <form onSubmit={handleSubmit} noValidate>
              {(instrument.tradeType === 'BUY' ||
                instrument.tradeType === 'SELL') && (
                <AccountPicker
                  disableField={isEditOrder}
                  accountPickerOptions={accountPickerOptions}
                  accountSelected={values?.accountSelected}
                />
              )}
              {instrument.tradeType === 'BUY' &&
                values?.accountSelected &&
                !values.accountSelected?.isMarginAccount && (
                  <AccountBalance
                    oldTotal={values?.oldTotal}
                    selectedAccount={values.accountSelected}
                  />
                )}

              <div className={styles.divider} />

              <Limit last={last} sell={SELL} />
              {SELL && (
                <SellForm
                  form={form}
                  values={values}
                  disableField={isEditOrder}
                />
              )}
              <Volume />
              <div className={styles.textWithToggle}>
                <span>
                  <HelpText
                    className={styles.helpText}
                    title={<h3>Jeg vil skjule deler av min ordre</h3>}
                  >
                    Du kan velge å skjule deler av din ordre ved å her taste inn
                    det volumet som skal være synlig. Ordrestørrelse må være
                    større enn 100 00 NOK
                  </HelpText>
                  <Icon icon="info-circle" />
                </span>

                <label htmlFor="hiddenVolume" className="sr-only">
                  Skjult Volum
                </label>

                <Field
                  name="hiddenVolume"
                  type="checkbox"
                  render={({ input: { onChange, value } }) => (
                    <Switch
                      onSwitch={onChange}
                      checked={value}
                      withComponent="input"
                      disabled={isEditOrder}
                      labeled={'true'}
                      type="checkbox"
                      name="switch"
                      form={form}
                    />
                  )}
                  disabled={isEditOrder}
                />
                <label htmlFor="openVolume" />
              </div>

              {values?.hiddenVolume && <OpenVolume />}
              <Calendar
                closeRules={values.closeRules}
                tradeType={instrument.tradeType}
                orderId={instrument?.orderId}
                selectedAccount={values.accountSelected}
                disabled={!allowEditDate}
              />

              <SidebarConsumer>
                {({ askTradableInstruments }) => {
                  if (askAccount && !askTradableInstruments?.length) {
                    return (
                      <ErrorMessage
                        error={
                          'Vi fikk ikke kontakt med et baksystem og vet ikke om dette instrumentet kan handles på ASK eller ikke'
                        }
                      />
                    );
                  }

                  if (
                    askAccount &&
                    !instrument.askTradable &&
                    instrument.tradeType === 'BUY' &&
                    !instrument?.orderId
                  ) {
                    return (
                      <ErrorMessage
                        error={`${instrument.ITEM} kan ikke handles på en aksjesparekonto (ASK)`}
                      />
                    );
                  }
                }}
              </SidebarConsumer>

              {!submitting && !submitSucceeded && (
                <OrderSummary accounts={accounts} values={values} />
              )}

              {submitting && (
                <>
                  <p className={styles.submitLoadingText}>
                    Ordre sendes inn. Vennligst vent...
                  </p>
                  <Skeleton
                    width="100%"
                    height={'20px'}
                    color={'#f7f7f7'}
                    count={5}
                  />
                </>
              )}
              {submitSucceeded && !submitting && (
                <Receipt resetForm={form.reset} order={order} values={values} />
              )}

              {submitError && !submitting && (
                <ErrorMessage error={submitError} message="Noe gikk galt" />
              )}

              <SidebarConsumer>
                {({ askTradableInstruments }) => {
                  const notTradableOnASK =
                    !instrument.askTradable && askTradableInstruments?.length;
                  const isFreshBuyOrder =
                    instrument.tradeType === 'BUY' && !instrument?.orderId;

                  const tradeDisabled =
                    askAccount && notTradableOnASK && isFreshBuyOrder;

                  if (!submitting && !submitSucceeded) {
                    return (
                      <TradeFormButtons
                        instrument={instrument}
                        tradeType={values.tradeType}
                        disabled={
                          submitting ||
                          (tradeDisabled && askTradableInstruments.length)
                        }
                        handleSelectType={handleSelectType}
                        pristine={pristine}
                      />
                    );
                  }
                }}
              </SidebarConsumer>
            </form>
          );
        }}
      />
    );
  }
}
const mapStateToSpec = (state, props) => {
  const columns = 'LAST, LASTNZ, LASTNZ_DIV, CLOSE, SECTOR, INSTRUMENT_TYPE';
  return {
    columns,
    itemSector: `${props.instrument.ITEM}.${props.instrument.SECTOR}`,
  };
};

const mapStateToProps = state => ({
  userId: getUserId(state),
  competencyTestResult: getcompetencyTestResult(state),
  competencyTestTimestamp: getcompetencyTestTimestamp(state),
});

export default compose(
  jawsComponent(mapStateToSpec),
  connect(mapStateToProps),
)(TradeForm);
