export const conditions = [
  { label: 'Faller minst', value: 'LTE' },
  { label: 'Stiger minst', value: 'GTE' },
];

export const conditionsLast = [
  { label: 'Faller til minst', value: 'LTE' },
  { label: 'Stiger til minst', value: 'GTE' },
];

export const conditionsChange = [
  { label: 'Faller med minst', value: 'LTE' },
  { label: 'Stiger med minst', value: 'GTE' },
];

export const fields = [
  { value: 'LAST', label: 'Siste' },
  { value: 'CHANGE', label: 'Intradag' },
  { value: 'CHANGE_PCT', label: 'Intradag i %' },
  { value: 'HIGH_5YEARS', label: 'Høyest over 5 år' },
];

export const newsTypes = [
  { value: 'ALL', label: 'Alle' },
  { value: 'INSIDER_TRADES', label: 'Innsidehandler' },
  { value: 'FINANCIAL_REPORTING', label: 'Resultat' },
];

export const typeOptions = [
  { value: 'stocks', label: 'Aksjer' },
  { value: 'indices', label: 'Indekser' },
  { value: 'interests', label: 'Renter' },
  { value: 'commodities', label: 'Råvarer' },
  { value: 'currencies', label: 'Valuta' },
];

export const tickerSelections = {
  indices: [
    {
      label: 'OSEBX',
      value: 'OSEBX.OSE',
    },
    {
      label: 'AEX',
      value: 'I:AEX.EUR',
    },
    {
      label: 'CAC 40',
      value: 'I:PX1.EUR',
    },
    {
      label: 'DAX',
      value: 'I:DAX.GER',
    },
    {
      label: 'Euronext 100',
      value: 'I:N100.EUR',
    },
    {
      label: 'OMXSPI',
      value: 'OMXSPI.SSE',
    },
    {
      label: 'Dow Jones (IA)',
      value: 'I:DJI.DJ',
    },
    {
      label: 'Nasdaq Composite',
      value: 'I:COMP.NAS',
    },
    {
      label: 'Nikkei 225',
      value: 'NI225.OSA',
    },
  ],
  commodities: [
    {
      label: 'Brent Spot',
      value: 'C:PBROUSDBR\\SP.IDCENE',
    },
    {
      label: 'West Texas Intermediate',
      value: 'C:PWTIUSDBR\\SP.IDCENE',
    },
    {
      label: 'Sølv',
      value: 'C:PXAGUSDOZ\\SP.IDCIND',
    },
  ],
  currencies: [
    {
      label: 'USD',
      value: 'X:SUSDNOK.IDCFX',
    },
    {
      label: 'Euro',
      value: 'X:SEURNOK.IDCFX',
    },
    {
      label: 'GBP',
      value: 'X:SGBPNOK.IDCFX',
    },
    {
      label: 'EUR vs. USD',
      value: 'X:SEURUSD.IDCFX',
    },
    {
      label: 'USD vs. JPY',
      value: 'X:SUSDJPY.IDCFX',
    },
    {
      label: 'SEK',
      value: 'X:SSEKNOK.IDCFX',
    },
  ],
  interests: [
    {
      label: 'US Bond 10Y',
      value: 'B:BMUS\\10Y.IDCFI',
    },
    {
      label: 'US Bond 30Y',
      value: 'B:BMUS\\30Y.IDCFI',
    },
    {
      label: 'Norsk st. oblig 5 år',
      value: 'OMS:NST5Y.OSE-SYNTHETIC',
    },
    {
      label: 'Norsk st. oblig 10 år',
      value: 'OMS:NST10Y.OSE-SYNTHETIC',
    },
  ],
};
