import Select from 'react-select';
import { Field } from 'react-final-form';
import { typeOptions } from 'utils/alarms/options';
import styles from '../../Alarms.module.scss';

const required = value => (value ? undefined : 'Hva vil du varsles om ?');

export const commoditiesType = () => (
  <Field name="commoditiesType" validate={required}>
    {({ input, meta }) => (
      <div className={styles.field}>
        <label htmlFor="commoditiesType">Varsle om</label>
        <Select
          {...input}
          isSearchable={false}
          isClearable={false}
          placeholder={'Aksjer, indekser, renter, råvarer, valuta...'}
          options={typeOptions}
          value={typeOptions.find(opt => opt.value === input.value?.value)}
        />
        {meta.error && meta.touched && (
          <span className={styles.error}>{meta.error}</span>
        )}
      </div>
    )}
  </Field>
);

export default commoditiesType;
