import cookies from 'js-cookie';
import { ADMIN_LOGOUT_URL, ACCOUNTS_URL } from 'constants/Jaws';
import { get } from 'utils/fetch';

/*
 * IMPORTANT! Don't use these methods in components, use a reselect selector
 *
 * See state/user/selectors.js
 */

export function isLoggedIn(user) {
  return user && user.get('authenticated') === true;
}

export function getCustomerData(user) {
  const principals = user.get('principals') || [];

  return principals.find(p => p.get('userType') === 'customer');
}

export function getGuardianData(user) {
  const principals = user.get('principals') || [];

  return principals.find(p => p.get('userType') === 'guardian');
}

export function isSupportTakeover(user) {
  const principals = (user && user.get('principals')) || [];

  return principals.findIndex(p => p.get('supportTakeover') === true) !== -1;
}

export function isCustomerOrTakeover(user) {
  if (!isLoggedIn(user)) return false;
  return !!getCustomerData(user);
}

export function isGuardian(user) {
  if (!isLoggedIn(user)) return false;
  return !!getGuardianData(user);
}

export function getAdminData(user) {
  return user
    ?.get('principals')
    ?.find(p => p.get('userType') === 'administrator');
}

export const fetchAllBalances = async (userId, accounts) => {
  const uniqueBankAccounts = [];
  const accountsToFetch = accounts.filter(account => {
    const bankAccount = account.get('bankAccountNo');
    if (bankAccount && !uniqueBankAccounts.includes(bankAccount)) {
      uniqueBankAccounts.push(bankAccount);
      return true;
    }

    if (account.get('ask')) return true;

    return false;
  });
  const promises = accountsToFetch.map(account =>
    get(`${ACCOUNTS_URL}/${userId}/balance/${account.get('id')}`)
      .then(response => response.json())
      .then(response => ({ balance: response.data?.BALANCE || 0 }))
      .catch(error => {
        throw error;
      }),
  );
  const responses = await Promise.all(promises);
  const totalBalance = responses.reduce(
    (result, item) => result + Number(item.balance),
    0,
  );
  return totalBalance;
};

/**
 * Get the userId of the main account. This is necessary when f.ex.
 * fetching fictive portfolios or products, which are always on the main
 * account, not the selected account when you have a power of attourney
 * situation.
 */
export function getUserId(user) {
  if (!isLoggedIn(user)) {
    return null;
  }

  if (isCustomerOrTakeover(user)) {
    return getCustomerData(user).get('sub');
  }

  if (isGuardian(user)) {
    return getGuardianData(user).get('sub');
  }

  const data = getAdminData(user);
  return data ? data.get('sub') : null;
}

export function getUserCustomerNumber(user) {
  if (!isLoggedIn(user)) {
    return null;
  }
  return getCustomerData(user) && getCustomerData(user).get('customerNumber');
}

export function canStream(user) {
  const customerData = getCustomerData(user);

  if (!customerData) {
    return false;
  }

  return (
    isCustomerOrTakeover(user) &&
    customerData.get('shiro') &&
    customerData.get('shiro').check('collect:streaming')
  );
}

export function hasLevel2(user) {
  const customerData = getCustomerData(user);

  if (!customerData) {
    return false;
  }

  return (
    isCustomerOrTakeover(user) &&
    customerData.get('shiro') &&
    customerData.get('shiro').check('collect:level2')
  );
}

export function isAdmin(user) {
  return !!user
    .get('principals')
    .find(p => p.get('userType') === 'administrator');
}

export function isTakeover(user) {
  return !!user.get('principals').find(p => p.get('supportTakeover') === true);
}

export function isOwnTakeover(user) {
  return isAdmin(user) && isTakeover(user);
}

export function isExternalTakeover(user) {
  return !isAdmin(user) && isTakeover(user);
}

/**
 * Checks if any valid acocunts exist on the user
 */
export function accountsExist(user) {
  if (!isLoggedIn(user)) return false;

  // Login is valid if an account has an account to trade on
  const customerData = getCustomerData(user);

  if (!customerData) {
    return false;
  }

  return customerData.get('accounts').size > 0;
}

export async function logOutSession() {
  window.location = ADMIN_LOGOUT_URL;
}

export const getSelectedUserIdFromCookie = () => cookies.get('selectedUserId');

export const getUserFlags = user => {
  if (!user || !user.principals || !user.principals.length) return {};
  const {
    isNewCustomer,
    userAgreementTimestamp,
    userAgreementTimestampEquities,
    accounts,
    bank,
    centerId,
    hasElectronicIdentification,
  } = user.principals[0];

  return {
    accounts,
    hasElectronicIdentification,
    hasVpsAccount: accounts?.some?.(
      acc => acc.vpsAccountNo?.length && !acc.ips,
    ),
    isNewCustomer,
    hasAccounts: !!accounts && !!accounts.length,
    userAgreementTimestamp: userAgreementTimestamp
      ? new Date(userAgreementTimestamp)
      : null,
    userAgreementTimestampEquities: userAgreementTimestampEquities
      ? new Date(userAgreementTimestampEquities)
      : null,
    bankPrivileges: bank,
    centerId,
  };
};
