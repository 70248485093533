import PropTypes from 'prop-types';
import { jawsPropTypes } from '@oms/jaws-react-compat';
import { formatNumber, formatInteger, decimalPlaces } from '@oms/utils';

import styles from './OrderDepth.module.scss';

function Row(props) {
  const values = props.item;
  const { BID, ASK, ASKVOL, BIDVOL } = values;

  let ask = props.item.percentOfMax.ask;
  let bid = props.item.percentOfMax.bid;
  if (bid > 100) {
    bid = 100;
  }
  if (ask > 100) {
    ask = 100;
  }
  const barStyle = {
    bid: {
      width: `${bid}%`,
    },
    ask: {
      width: `${ask}%`,
    },
  };
  return (
    <div aria-label="Ordredybde" className={styles.row}>
      <div className={styles.leftBar}>
        <b aria-label="BID volum">{formatInteger(BIDVOL || 0)}</b>
        <b aria-label="BID pris">{formatNumber(BID, decimalPlaces(BID))}</b>
        <div className={styles.barBid} style={barStyle.bid} />
      </div>
      <div className={styles.rightBar}>
        <b aria-label="ASK pris"> {formatNumber(ASK, decimalPlaces(ASK))}</b>
        <b aria-label="ASK volum"> {formatInteger(ASKVOL || 0)}</b>
        <div className={styles.barAsk} style={barStyle.ask} />
      </div>
    </div>
  );
}

Row.propTypes = {
  item: PropTypes.shape({
    ...jawsPropTypes.items,
    percentOfMax: PropTypes.shape({
      ask: PropTypes.number.isRequired,
      bid: PropTypes.number.isRequired,
    }).isRequired,
  }).isRequired,
  // hasLevel2: PropTypes.bool,
};

export default Row;
