// This is a duck experiment.. Quack! https://github.com/erikras/ducks-modular-redux
import Immutable from 'immutable';
import { ACCOUNTS_URL } from 'constants/Jaws';

import { get } from 'utils/fetch';
import { getUserId, getSelectedAccounts } from 'state/user/selectors';

const RECIEVE_ACCOUNT_BALANCE = 'norne/orders/RECIEVE_ACCOUNT_BALANCE';

const initialState = Immutable.fromJS({
  portfolios: {},
});

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case RECIEVE_ACCOUNT_BALANCE:
      return state.set('portfolios', Immutable.fromJS(action.payload));
    default:
      return state;
  }
}

export const fetchBalance = () => async (dispatch, getState) => {
  const state = getState();
  const userId = getUserId(state);
  const accounts = getSelectedAccounts(state);

  const promises = accounts.map(async item => {
    const account = item.toJS();
    // Don't fetch balance on dashboard for non-deposit accounts ref: NOR-3019
    if (!account.deposit) return { account };

    try {
      const result = await get(
        `${ACCOUNTS_URL}/${userId}/balance/${account.id}`,
      );

      let content = await result.text();
      try {
        content = JSON.parse(content);
      } catch (error) {
        // Not JSON
      }

      if (!result.ok) {
        throw content;
      }

      return {
        account,
        balance: content.data,
      };
    } catch (error) {
      return {
        account,
        error,
      };
    }
  });

  // Send in parallell, block with await afterwards
  const responses = await Promise.all(promises);
  const balance = responses.reduce(
    (result, data) => result.set(data.account.id, Immutable.fromJS(data)),
    Immutable.Map(),
  );

  dispatch({ type: RECIEVE_ACCOUNT_BALANCE, payload: balance });
};
